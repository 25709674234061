import React from 'react';
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ExpandMore } from '@material-ui/icons';
import { Box, MenuItem, Select, Typography } from '@material-ui/core';
import { City, Country, State } from "country-state-city";
import StorageProvider from "../../../framework/src/StorageProvider";
import { validationerror } from './assets';
import { toast } from 'react-toastify';
export const configJSON = require("./config");


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export interface Props {
    navigation: any;
    id: string;
    
    
    // Customizable Area Start
    
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    customerType:string;
    addressType:string;
    value: number;
    open: boolean;
    selectedCountry:any;
    selectedRegion:any;
    selectedCity:any;
    customer_name:string;
    job_position:string;
    title:string;
    phone_number:string;
    alternate_phone_number:string;
    email:string;
    tags:any[];
    language:string;
    address_line1:string;
    address_line2:string;
    postal_code:string;
    website:string;
    company_name:string;
    note:string;
    addresses_attributes:any[];
    accountings_attributes:any[];
    account_sale_purchases_attributes:any[];
    contactData:any;
    salesPurchaseData:any;
    accountingData:any;
    noteAlert:boolean;
    image:any;
    tagImages:File[];
    bankDetails:any[];
    reminder:number;
    reminderCheckbox:boolean;
    token:string|null;
    saveClick:boolean;
    createClick:boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export interface Option {
    value: string;
    label: string;
    
}

export default class CfcustomersalescreatecustomerController extends BlockComponent<
    Props,
    S,
    SS
> {
    addCustomerApiCallId: any = "";
    postalCodeApiCallId: any = "";
    stateObj = {
                customerType:'a',
                addressType:'a',
                value: 0,
                open: false,
                selectedCountry:null,
                selectedRegion:null,
                selectedCity:null,
                customer_name:'',
                job_position:'',
                title:'',
                phone_number:'',
                alternate_phone_number:'',
                email:'',
                tags:[],
                language:'',
                address_line1:'',
                address_line2:'',
                postal_code:'',
                website:'',
                company_name:'',
                note:'',
                addresses_attributes:[],
                accountings_attributes:[],
                account_sale_purchases_attributes:[],
                contactData:{
                  contact_name:"",title:"",job_position:"",email:'',phone_number:'',alternative_phone_number:'',address1:"",address2:"",country:"",state:"",postal_code:''
                },
                salesPurchaseData:{},
                accountingData:{},
                noteAlert:false,
                image:null,
                tagImages:[],
                bankDetails:[{bank_name:"",account_number:""}],
                reminder:1,
                reminderCheckbox:true,
                token:'',
                saveClick:false,
                createClick:false,
              };
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
          ];
        // Customizable Area Start  
        this.state = this.stateObj;
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        
        
        // Customizable Area End
    }

    async componentDidMount(){
      this.setApiToken()
    }

    componentDidUpdate(prevProps: {}, prevState: S) {
      
      if (JSON.stringify(prevState.addresses_attributes) !== JSON.stringify(this.state.addresses_attributes)) {
        
        this.handleClose();
      }
    
    }


    setApiToken = async() => {
      const getapiToken =  await StorageProvider.get("TOKEN");
       this.setState({token: getapiToken})
    }

    
    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
    
          let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          let errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          console.log({errorReponse});
          runEngine.debugLog("API Message Recived", message);
          console.log('before',responseJson);
          
          
            if (responseJson?.errors) {
               this.handleErrorResponse(responseJson.errors);
            }else if ((apiRequestCallId === this.postalCodeApiCallId) && responseJson?.postal_codes) {
              
                this.setState({...this.state,postal_code:responseJson.postal_codes})
              
            }else if ((apiRequestCallId === this.addCustomerApiCallId) && responseJson?.data?.id) {
              this.handleDiscard();
            }
           
          
        }
        // Customizable Area End
      }

    handleErrorResponse = (error:any) =>{
      let msg = configJSON.something_went_wrong;
      if(error[0] && error[0]['account']){
          msg = error[0]['account'];
      }else if(error[0]){
          msg = error[0];
      }
      this.showAlert(configJSON.errorTitle, msg);
    }

    handleContactErrorMessage= (type:string,show:boolean=false) => {
        let flabel:any = '';
        switch(type){
          
          case 'contact_name' :  flabel = !this.state.contactData.contact_name?.trim() && 'Please enter contact name.'; break;
          case 'contact_title' :  flabel = !this.state.contactData.title && 'Please select title.'; break;
          case 'contact_job_position' :  flabel = !this.state.contactData.job_position && 'Please select job position.'; break;
          case 'contact_email' :  flabel = !this.state.contactData.email?.trim() && 'Please enter email.'; break;
          case 'contact_phone_number' :  flabel = !this.state.contactData.phone_number?.trim() && 'Please enter mobile number.'; break;
          case 'contact_alternate_phone_number' :  flabel = !this.state.contactData.alternate_phone_number?.trim() && 'Please enter other number.'; break;
          case 'contact_address' :  flabel = !this.state.contactData.address1?.trim() && 'Please enter address.'; break;
          case 'contact_country' :  flabel = !this.state.contactData.country && 'Please select country.'; break;
          case 'contact_state' :  flabel = !this.state.contactData.state?.trim() && 'Please enter state.'; break;
          case 'contact_city' :  flabel = !this.state.contactData.city?.trim() && 'Please enter city.'; break;
          case 'contact_postal_code' :  flabel = !this.state.contactData.postal_code?.trim() && 'Please enter postal code.'; break;
          
          default :
          break;
          
        }
        return this.handleLabel(flabel)
      
    }


    handleTabErrorMessage= (type:string,show:boolean=false) => {
      if(this.state.saveClick || show){
        let flabel:any = '';
        switch(type){
          
          case 'saler_name' :  flabel = !this.state.salesPurchaseData?.saler_name && 'Please select sales person.'; break;
          case 'payment_terms' :  flabel = !this.state.salesPurchaseData?.sale_payment_terms && 'Please select payment terms.'; break;
          case 'price_list' :  flabel = !this.state.salesPurchaseData?.pricelist && 'Please select pricelist.'; break;
          case 'delivery_method' :  flabel = !this.state.salesPurchaseData?.delivery_method && 'Please select delivery method.'; break;
          case 'product_code' :  flabel = !this.state.salesPurchaseData?.product_code && 'Please select product code.'; break;
          case 'purchase_payment_terms' :  flabel = !this.state.salesPurchaseData?.puchase_payment_terms && 'Please select payment terms.'; break;
          case 'payment_method' :  flabel = !this.state.salesPurchaseData?.payment_method && 'Please select payment method.'; break;
          case 'supplier_currency' :  flabel = !this.state.salesPurchaseData?.supplier_currency && 'Please select supplier currency.'; break;
          case 'reminder' :  flabel = !this.state.reminder && 'Please enter receipt reminder.'; break;
          case 'account_reievable' :  flabel = !this.state.accountingData?.receivable && 'Please select account recievable.'; break;
          case 'account_payable' :  flabel = !this.state.accountingData?.payable && 'Please select account payable.'; break;
          case 'tag_images' :  flabel = !this.state.tagImages.length && 'Please select tags.'; break;
          default :
          break;
          
        }
        return this.handleLabel(flabel)
      }else{
        return ''
      }
      
    }

    handleLabel = (flabel:any) => {
      return <>
      {
        flabel ? this.handleErrorLabel(flabel) : flabel
      }
      </>
    }

    handleErrorMessage = (type:string,show:boolean=false) => {
      if(this.state.saveClick || show){
        let flabel:any = '';
        switch(type){
          case 'customer_name' :  flabel = !this.state.customer_name.trim() && 'Please enter valid customer name.'; break;
          case 'job_position' :  flabel = !this.state.job_position && 'Please select valid job position.'; break;
          case 'title' :  flabel = !this.state.title && 'Please select valid title.'; break;
          case 'phone_number' :  flabel = !this.state.phone_number.trim() && 'Please enter valid mobile number.'; break;
          case 'alternate_phone_number' :  flabel = !this.state.alternate_phone_number.trim() && 'Please enter valid alternate mobile number.'; break;
          case 'email' :  flabel = !this.state.email.trim() && 'Please enter valid email.'; break;
          case 'tags' :  flabel = !this.state.tags.length && 'Please select valid tags.'; break;
          case 'language' :  flabel = !this.state.language && 'Please select valid language.'; break;
          case 'website' :  flabel = !this.state.website.trim() && 'Please enter valid website.'; break;
          case 'address1' :  flabel = !this.state.address_line1.trim() && 'Please enter valid address.'; break;
          case 'selectedCountry' :  flabel = !this.state.selectedCountry && 'Please select valid country.'; break;
          case 'selectedRegion' :  flabel = !this.state.selectedRegion && 'Please select valid state.'; break;
          case 'selectedCity' :  flabel = !this.state.selectedCity && 'Please select valid city.'; break;
          case 'company_name' :  flabel = !this.state.company_name.trim() && 'Please enter valid company name.'; break;
          
          case '1' :  
          case '2' :  
          case '3' :  
          case '4' :  
          flabel = configJSON.errorMobileLengthNotValid; 
          break;
          case 'email1' :  
          case 'email2' :
          flabel = configJSON.errorEmailNotValid; 
          break;
          default :  
          break;
          
        }
        return this.handleLabel(flabel)
      }else{
        return ''
      }
      
    }


    errorTabTextClass = (field:any,value:any) => {
      if(this.handleTabErrorMessage(field) && !value){
        return 'error-text1';
      }else{
        return 'main-color';
      }
    }

    errorTextClass = (field:any,value:any) => {
      if(this.handleErrorMessage(field) && !value){
        return 'error-text1';
      }else{
        return 'main-color';
      }
    }

    errorContactTextClass = (field:any,value:any) => {
      if(this.state.createClick && this.handleContactErrorMessage(field) && !value){
        return 'error-text1';
      }else{
        return 'main-color';
      }
    }

    errorSelectFocusClass = (field:any,value:any) => {
      if(this.state.createClick && this.handleContactErrorMessage(field) && !value){
        return 'error-focus';
      }else{
        return '';
      }
    }

    errorContactFocusClass = (field:any,value:any) => {
      if(this.state.createClick && this.handleContactErrorMessage(field) && !value){
        return 'error-focus1';
      }else{
        return '';
      }
    }

    errorFocusClass = (field:any,value:any) => {
      if(this.handleErrorMessage(field) && !value){
        return 'error-focus';
      }else{
        return '';
      }
    }

    errorTabFocusClass = (field:any,value:any) => {
      if(this.handleTabErrorMessage(field) && !value){
        return 'error-focus';
      }else{
        return '';
      }
    }

    errorTagsClass = (field:any) => {
      if(this.handleErrorMessage(field) && !this.state.tags.length){
        return 'tags-error-focus';
      }else{
        return '';
      }
    }

    mainErrorBox = () => {
      return <Box className={`flex error-main-box ${!(this.state.saveClick && ((!this.checkAddCustomerValidation()) || (!this.state.addresses_attributes.length))) && 'display-none'}`}>
            <img src={validationerror}/>
                <Typography className='error-main-text'>Some of the fields are incorrect or invalid. Please enter the valid details to continue.</Typography>
            </Box>
    }

    handleErrorLabel = (flabel:any) => {
      return <Box className='flex relative' >
                  <img className='error-text-img' src={validationerror} />
              <Typography className='error-text'>{flabel}</Typography>
              </Box>
    }

    handleWebsiteError = (msg:any='') => {
      return <Box className='flex relative' >
                <img className='error-text-img' src={validationerror} />
            <Typography className='error-text'>{msg=='add_address'?configJSON.errorAddOneContact:'Please enter valid website.'}</Typography>
            </Box>
    }

    handleRemoveFile= (index:any) => {
      console.log('remove file ',this.state.tagImages,index)
      const filteredPeople:any = this.state.tagImages.length > 0 ? Array.from(this.state.tagImages).filter((item:any,indx:number) => indx !== index) : [];
      console.log('filteredPeople ',filteredPeople)
      this.setState({...this.state,tagImages:filteredPeople})
    }

    removeColumn = (index:any) => {
        this.setState({...this.state,bankDetails:this.state.bankDetails.filter((i:any,indx:number)=>indx != index)})
    }

    addAnotherColumn = () => {
      this.setState({
        ...this.state,
        bankDetails:this.state.bankDetails.concat({
          bank_name:"",
          account_number:""
        })
      })
    }

    handleBankDetails= (e:any,index:number,field:string) => {
        
      let newArr = JSON.parse(JSON.stringify(this.state.bankDetails)); 
      const editArr:any = newArr.filter((i:any,indx:number)=>indx == index)
      //console.log(editArr)
      if(editArr?.length>0){
          editArr[0][field]=e.target?.value;
      }
      this.setState({...this.state,bankDetails:newArr})
    }

    isStringNullOrBlank(str: string) {
    return str?.trim() === null || str?.trim().length === 0;
    }

    handleDiscard = () => {
      this.props.navigation.goBack();
      this.setState(this.stateObj);
    }

    handleTagImages = (event:any) => {
      const inputFiles:any = event.target?.files;

    if (inputFiles) {
      const newFiles = Array.from(inputFiles);
      this.setState((prevState:any) => ({
        tagImages: [...prevState.tagImages, ...newFiles],
      }));
    }
      
      
    }
    
    noteValidation = (note: any) => {
      if (note.length>500) {
        this.setState({...this.state,noteAlert:true});
      } else {
        this.setState({...this.state,note:note,noteAlert:false});
        
      }
    }

    handleMobile = (field:any,mob:any) => {
      if(field == 'mobile1'){
        this.setState({...this.state,phone_number:mob});
       return true;
     }else if(field == 'mobile3'){
       this.setState({...this.state,contactData:{...this.state.contactData,phone_number:mob}})
       return true;
     }else if(field == 'mobile4'){
       this.setState({...this.state,contactData:{...this.state.contactData,alternate_phone_number:mob}})
       return true;
     }else{
       this.setState({...this.state,alternate_phone_number:mob})
       return true;
     }
    }

    checkMobileLength = (mob: any,type: string) => {
      if (mob && mob.length<10) {
        
        return false;
      }
      
      return true;
    }

    isMobileNumberValid = (mob: any,field: string) => {
      
      let mobreg = /^\d{0,15}$/;
      if(!mob){
        field == 'mobile1' && this.setState({...this.state,phone_number:''});
        field == 'mobile2' && this.setState({...this.state,alternate_phone_number:''});
        field == 'mobile3' && this.setState({...this.state,contactData:{...this.state.contactData,phone_number:''}});
        field == 'mobile4' && this.setState({...this.state,contactData:{...this.state.contactData,alternate_phone_number:''}});
        return false;
      }
        if (mobreg.test(mob) === false && (field == 'mobile1' || field == 'mobile2')) {
          return false;
        }
        if (mobreg.test(mob) === false && (field == 'mobile3' || field == 'mobile4')) {
          
          return false;
        }
        this.handleMobile(field,mob);
    } 

    expandMore = () => {
      return <ExpandMore/>;
    }

    isValidEmail = (Email: string,field:string) => {
        let reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (reg.test(Email) === false && Email?.trim()) {
          
          return false;
        } else {
          
          return true;
        }
    };
    
    onTagsChange = (event:any, values:any) => {
    this.setState({
        ...this.state,
        tags: values
    }, () => {
        // This will output an array of objects
        // given by Autocompelte options property.
        console.log(this.state.tags);
    });
    }
    
    handleContact=()=>{
      
        if(this.handleContactValidation()){
        let data:any = {
            contact_name: this.state.contactData.contact_name,
            postal_code: this.state.contactData.postal_code,
            phone_number: this.state.contactData.phone_number,
            email: this.state.contactData.email,
            title: this.state.contactData.title,
            job_position: this.state.contactData.job_position,
            address: this.state.contactData.address1+"@"+this.state.contactData.address2,
            state: this.state.contactData.state,
            city: this.state.contactData.city,
            country: this.state.contactData.country,
            note: this.state.contactData.note,
            alternate_phone_number: this.state.contactData.alternate_phone_number
        }
        this.setState({
            ...this.state,
            addresses_attributes:this.state.addresses_attributes.concat(data)
          });
        }
        
    }
    handleCountryChange = (event:any) => {
        const selectedCountry = event.target?.value as string;
        this.setState({
          ...this.state,
          selectedCountry,
          selectedRegion: '',
          selectedCity: '',
        });
      };
    
      handleRegionChange = (event:any) => {
        const selectedRegion = event.target?.value as string;
        this.setState({
          ...this.state,
          selectedRegion,
          selectedCity: '',
        });
      };
    
      handleCityChange = (event:any) => {
        const selectedCity = event.target?.value as string;
        this.setState({
          ...this.state,
          selectedCity,
        });
        this.fetchPostalCode(selectedCity.split('@')[1],selectedCity.split('@')[2])
        
      };

      SelectCity = (selectedRegion:any,selectedCity:any,selectedCountry:any) => {
        return <>
              <Select
                  IconComponent={this.expandMore}
                  inputProps={{
                      'aria-label': 'custom select',
                  }}
                  data-test-id="cityInput"
                  value={selectedCity}
                  onChange={this.handleCityChange}
                  label="Select"
                  disableUnderline
                  className={`width-100 ${this.errorFocusClass('selectedCity',this.state.selectedCity)}`}
                  
              >
                  {(selectedRegion ? City.getCitiesOfState(selectedCountry,selectedRegion) : [])?.length>0 && (selectedRegion ? City.getCitiesOfState(selectedCountry,selectedRegion) : []).map((city:any,index:number) => (
                      <MenuItem className='select-menu-item' key={index} value={city.name+'@'+city.latitude+'@'+city.longitude}>
                      {city.name}
                      </MenuItem>
                  ))}
                  
              </Select>
              </>
      }

      SelectState = (selectedRegion:any,regions:any) => {
        return <>
                <Select
                    IconComponent={this.expandMore}
                    inputProps={{
                        'aria-label': 'custom select',
                    }}
                    data-test-id="stateInput"
                    value={selectedRegion}
                    onChange={this.handleRegionChange}
                    label="Select"
                    disableUnderline
                    className={`width-100 ${this.errorFocusClass('selectedRegion',this.state.selectedRegion)}`}
                    
                >
                    {regions?.length>0 && regions.map((region:any) => (
                    <MenuItem className='select-menu-item' key={region.isoCode} value={region.isoCode}>
                    {region.name}
                    </MenuItem>
                    ))}
                    
                </Select>
            
              </>
    }

      fetchPostalCode(lat:any,long:any){
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
          
        };
      
        this.postalCodeApiCallId = requestMessage.messageId;
      
      
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `account_block/accounts/postal_code?latitude=${lat}&longitude=${long}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

    
    
    TabPanel: React.FC<TabPanelProps> = ({ children, index, value }) => {
        return <div hidden={value !== index}>{children}</div>;
    };
    
    handleCustomerType = (event: any) => {
        this.setState({ ...this.state,customerType: event.target.value });
    };

    handleImageChange = (e : any) => {
      const file = e?.target?.files && e.target.files[0];
      if (file) {
        if (!file.type.startsWith("image/")) {
          toast.error("Please select a valid image file");
          return;
        }   
        this.setState({ ...this.state, image: file });
      }
    }

    handle1Validation=()=>{
      if(this.isValidEmail(this.state.contactData.email,'email2') && 
      this.state.contactData.phone_number && 
      (!this.isStringNullOrBlank(this.state.contactData.phone_number)) && 
      this.checkMobileLength(this.state.contactData.phone_number,'2') && 
      this.state.contactData.alternate_phone_number && 
      (!this.isStringNullOrBlank(this.state.contactData.alternate_phone_number)) && 
      this.checkMobileLength(this.state.contactData.alternate_phone_number,'2')){
        return true;
        }
        return false;
    }
    handleContactValidation = ():boolean => { 
        if (this.state.addressType == 'a' && 
            this.state.contactData.contact_name && (!this.isStringNullOrBlank(this.state.contactData.contact_name)) && 
            this.state.contactData.title && (!this.isStringNullOrBlank(this.state.contactData.title)) &&
            this.state.contactData.job_position && (!this.isStringNullOrBlank(this.state.contactData.job_position)) &&
            this.state.contactData.email && (!this.isStringNullOrBlank(this.state.contactData.email)) && 
            this.state.contactData.phone_number && (!this.isStringNullOrBlank(this.state.contactData.phone_number)) && 
            this.state.contactData.alternate_phone_number && (!this.isStringNullOrBlank(this.state.contactData.alternate_phone_number)) 
        ) {
            return this.handle1Validation()

        }else if (this.state.addressType == 'b' && 
        this.state.contactData.address1 && (!this.isStringNullOrBlank(this.state.contactData.address1)) && 
        this.state.contactData.country && (!this.isStringNullOrBlank(this.state.contactData.country)) &&
        this.state.contactData.state && (!this.isStringNullOrBlank(this.state.contactData.state)) && 
        this.state.contactData.city && (!this.isStringNullOrBlank(this.state.contactData.city)) && 
        this.state.contactData.postal_code && (!this.isStringNullOrBlank(this.state.contactData.postal_code))
        ) {
            
            return true;

        }else{
          this.setState({...this.state,createClick:true});
            return false;
        }
        
    }
    
    handleAddressType = (event: React.ChangeEvent<HTMLInputElement>) => {
      
        
          this.setState({ ...this.state,addressType: event.target.value });
        
        
    };

    
    imageRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
    tagImagesRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();

    options: Option[] = [
        { value: '215.00%', label: '215.00%' },
        { value: '220.00%', label: '220.00%' }
        // Add more options as needed
    ];

    handleMultiSelect = (value: any, name: any) => {
      this.setState({ ...this.state,tags: value })
    }

    handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ ...this.state,value: newValue });
    };
    handleOpen = () => {
        this.setState({ ...this.state,open: true });
    };

    handleReminder = (e:any) => {
      const numericValue = e.target.value.replace(/\D/g, '');
      this.setState({...this.state,reminder:numericValue,salesPurchaseData:{...this.state.salesPurchaseData,receipt_reminder:numericValue}});
    }

    websiteValidation = (website:any) => {
      const urlPattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;
      if(urlPattern.test(website) || website == ""){
        return true;
        
      }else{
        return false;
      }
      
    }
    
    handleClose = () => {
      this.setState({
        ...this.state,
        createClick:false,
        addressType: 'a',
        open: false,
        contactData:{}
      })
      
    };

    addCustomerData = () => {
      const countries = Country.getAllCountries();
      const stat = State.getStatesOfCountry(this.state.selectedCountry)
      this.setState({...this.state,saveClick:false})
      let formdata:any = new FormData();
      formdata.append("data[image]", this.state.image);
      this.state.tagImages.length>0 && this.state.tagImages.forEach((item:any,index:number)=>{
        
          formdata.append(`data[accounting_attributes][accounting_tags][]`, item);
        
      })
      
      formdata.append("data[first_name]", this.state.customer_name.trim());
      formdata.append("data[user_type]", 'customer');
      formdata.append("data[email]", this.state.email);
      
      formdata.append("data[phone_number]", this.state.phone_number);
      formdata.append("data[full_phone_number]", this.state.phone_number);
      formdata.append("data[alternate_phone_number]", this.state.alternate_phone_number);
      
      formdata.append("data[address]", this.state.address_line1 +"@"+ this.state.address_line2);
      
      
      formdata.append("data[city]", this.state.selectedCity);
      formdata.append("data[state]", stat.filter((c:any)=>c.isoCode==this.state.selectedRegion)[0]?.name);
      formdata.append("data[country]", countries.filter((c:any)=>c.isoCode==this.state.selectedCountry)[0]?.name);
      formdata.append("data[postal_code]", this.state.postal_code);
      formdata.append("data[title]", this.state.title);
      formdata.append("data[job_position]", this.state.job_position);
      
      formdata.append("data[website]", this.state.website);
      formdata.append("data[language]", this.state.language);
      formdata.append("data[company_name]", this.state.company_name);
      formdata.append("data[note]", this.state.note);
      formdata.append("data[internal_note_attributes][internal_note]", this.state.note);
      formdata.append(`data[tags][]`, this.state.tags);
      this.state.addresses_attributes.length>0 && this.state.addresses_attributes.forEach((item:any,index:number)=>{
        Object.keys(item).forEach((key) => {
          formdata.append(`data[addresses_attributes][${index}][${key}]`, item[key]);
        });
      })
      Object.keys(this.state.accountingData).forEach((key) => {
        if(!(key == 'bank_name' || key == 'account_number')){
          formdata.append(`data[accounting_attributes][${key}]`, this.state.accountingData[key]);
        }
        
      });
      this.state.bankDetails.length>0 && this.state.bankDetails.forEach((item:any,index:number)=>{
        Object.keys(item).forEach((key:any) => {
        
          formdata.append(`data[account_details_attributes][${index}][${key}]`, (key=='bank_name' && (!item[key])) ? 'Bank Muscat' : item[key]);
        
        
        });
      })
      
      Object.keys(this.state.salesPurchaseData).forEach((key) => {
        formdata.append(`data[account_sale_purchases_attributes][${key}]`, this.state.salesPurchaseData[key]);
      });
      formdata.append("data[customer_type]", this.state.customerType == 'a' ? 'individual' : 'company');
      
      
        const header = {
          token: this.state.token,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
  
        this.addCustomerApiCallId = requestMessage.messageId;
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          'account_block/accounts/create_customer'
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formdata
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          'POST'
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    checkEmailWebsite = () => {
        if((!this.isValidEmail(this.state.email,'email1')) || 
        (!this.checkMobileLength(this.state.phone_number,'1')) || 
        (!this.checkMobileLength(this.state.alternate_phone_number,'1'))){
          return false;
        }
        if(!(this.websiteValidation(this.state.website))){
          return false;
        }
      
    }

    checkAddCustomerValidation = () => {
      if (
        this.isStringNullOrBlank(this.state.customer_name) ||
        this.isStringNullOrBlank(this.state.email) ||
        this.isStringNullOrBlank(this.state.phone_number) ||
        this.isStringNullOrBlank(this.state.alternate_phone_number) ||
        this.isStringNullOrBlank(this.state.language) ||
        this.isStringNullOrBlank(this.state.address_line1) ||
        this.isStringNullOrBlank(this.state.selectedCountry) ||
        this.isStringNullOrBlank(this.state.selectedRegion) ||
        this.isStringNullOrBlank(this.state.selectedCity) ||
        this.isStringNullOrBlank(this.state.website) ||
        this.isStringNullOrBlank(this.state.company_name) ||
        (!this.state.tags.length) ||
        (Object.keys(this.state.salesPurchaseData).length < 9) ||
        (Object.keys(this.state.accountingData).length < 2) ||
        this.state.tagImages.length == 0 || (!this.state.reminder) || (!(this.websiteValidation(this.state.website))) || (!(this.isValidEmail(this.state.email,'email1')))
        ) {
          this.checkEmailWebsite()
          return false;
        
        }else if (this.state.customerType == 'a' && (this.isStringNullOrBlank(this.state.job_position) || this.isStringNullOrBlank(this.state.title))) {
            
            return false;
        }else if(this.state.addresses_attributes.length==0){
        
        return false;
        }
        return true;
    }
    
    addCustomer = () => {
      this.setState({...this.state,saveClick:true})
        if (!this.checkAddCustomerValidation()){
        return false;
        }else {
        
          this.addCustomerData();
            
        }
    }

    navigateToCustomerListPage = () =>{
      const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "Cfcustomersaleslistview"
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }
    

}