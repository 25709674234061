// Customizable Area Start
import React from 'react';
import { Box, Button, Checkbox, FormControl, Grid, MenuItem, Select, Tab, Table, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from '@material-ui/core';
import AddCompanyController, { Props } from './AddCompanyController.web'
import '../assets/style.css'
export const configJSON = require("./config");
import Sidebar from '../../../components/src/Sidebar.web';
import { AppHeader } from '../../../components/src/AppHeader.web';
import { external } from './assets';
import { Country, State } from "country-state-city";
import { Link }  from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

// Customizable Area End


export default class AddCompany extends AddCompanyController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const {value} = this.state;
        const countries = Country.getAllCountries();
        const regions = this.state.country_name ? State.getStatesOfCountry(this.state.country_name) : [];
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
            
            <Box className="gridMainLayout">
            <Box className="gridsideBar">
            <Sidebar />
            </Box>
            
            <Box className="gridcontent" >
            <AppHeader pageTitle={configJSON.settings} />
            <ToastContainer />
            <Box >
                <Box className='child-div-1'>
                <Grid container spacing={0} >
                <Grid item sm={12}  className='grid-item-1'>
                <Grid item sm={12}  >
                <Box>
                <Typography>
                    <Link to = "/CompaniesListView" 
                    className="companiesHeading bold-text" 
                    style={{ textDecoration: 'none' }} >
                    Companies
                    </Link> 
                    <span className='new-normal-text'>
                        / {configJSON.new}
                    </span>
                </Typography>
                </Box>
                </Grid>
                </Grid>
                
                </Grid>
                </Box>
                
                <Box className="form-div form-div-border bottomBorder">
                {
                    this.mainCompanyErrorBox()
                }
                <Grid container  >
                
                <Grid item sm={6} >
                    <Box>
                        <Typography className={`${this.errorTextClass('company_name',this.state.company_name)}`}>Company Name<span className='required'>*</span></Typography>
                        <TextField data-test-id="companyNameInput" 
                        className={`${this.errorFocusClass('company_name',this.state.company_name)} form-input create-user-input`}
                        placeholder='E.g. Launch Academy'
                        value={this.state.company_name}
                        onChange={(e:any) => this.setState({...this.state,company_name:e.target.value})} 
                        name="company_name" type="text" />
                        {
                            this.handleErrorMessage2('company_name')
                        }
                    </Box>
                </Grid>
                <Grid item sm={6} >
                    <Box className='end-center-align'>
                    {
                        this.imageUI()
                    }
                    </Box>
                    
                </Grid>
                
            
                </Grid>
                </Box>
                <Box className='company-tabs'>
                <Tabs value={value} onChange={this.handleTabChange}>
                <Tab label="General Information" />
                <Tab label="Vat Units" />
                </Tabs>
                </Box>
                <Box className='tab-parent-div tab-parent-div-company leftRightBorder' >
                <div >
                
                <this.TabPanel value={value} index={0}>
                <Box className="form-div">
                <Grid container spacing={2} >
                <Grid item sm={6} >
                    
                <Grid item sm={12} >
                    <Box>
                    <Typography className={`${this.errorTextClass('address',this.state.address)}`}>Address<span className='required'>*</span></Typography>
                    <TextField 
                        className={`${this.errorFocusClass('address',this.state.address)} form-input create-user-input`}
                        placeholder='Enter Address'
                        data-test-id="addressInput"
                        value={this.state.address}
                        onChange={(e:any) => this.setState({...this.state,address:e.target.value})} 
                        name="address" type="text" />
                        {
                            this.handleErrorMessage1('address')
                        }
                    </Box>
                </Grid>
                <Grid container spacing={2} className='flex'>
                <Grid item sm={6} >
                    <Box>
                        <Typography className={`${this.errorTextClass('house_no',this.state.house_no)}`}>House Number<span className='required'>*</span></Typography>
                        <TextField className={`${this.errorFocusClass('house_no',this.state.house_no)} form-input create-user-input`} 
                        placeholder='Enter House Number'
                        data-test-id="houseNoInput"
                        value={this.state.house_no}
                        onChange={(e:any) => this.setState({...this.state,house_no:e.target.value})} 
                        name="house_no" type="text" />
                        {
                            this.handleErrorMessage1('house_no')
                        }
                    </Box>
                </Grid>
                <Grid item sm={6} >
                    <Box>
                        <Typography className={`${this.errorTextClass('door_no',this.state.door_no)}`}>Door Number<span className='required'>*</span></Typography>
                        <TextField className={`${this.errorFocusClass('door_no',this.state.door_no)} form-input create-user-input`}
                        placeholder='Enter Door Number'
                        data-test-id="doorNoInput"
                        value={this.state.door_no}
                        onChange={(e:any) => this.setState({...this.state,door_no:e.target.value})} 
                        name="door_no" type="text" />
                        {
                            this.handleErrorMessage1('door_no')
                        }
                    </Box>
                </Grid>
                </Grid>
                <Grid container spacing={0} className='flex'>
                <Grid item sm={6} >
                    <Box sx={{marginRight:"8px"}}>
                        <Typography className={`${this.errorTextClass('city_name',this.state.city_name)}`}>City<span className='required'>*</span></Typography>
                        <FormControl id="select-form-control" className="width-100">
                        {
                            this.SelectCity(this.state.state_name,this.state.city_name,this.state.country_name)
                        }
                        
                        </FormControl>
                        {
                            this.handleErrorMessage1('city_name')
                        }
                    </Box>
                </Grid>
                <Grid item sm={6} >
                    <Box sx={{marginLeft:"8px"}}>
                        <Typography className={`${this.errorTextClass('country_name',this.state.country_name)}`}>Country<span className='required'>*</span></Typography>
                        <FormControl id="select-form-control" className="width-100">
                
                        <Select
                            IconComponent={this.expandMore}
                            data-test-id="countryNameInput"
                            inputProps={{
                                'aria-label': 'custom select',
                            }}
                            
                            value={this.state.country_name}
                            displayEmpty
                            onChange={this.handleCountryChange}
                            label="Select"
                            disableUnderline
                            className={`width-100 ${this.errorFocusClass('country_name',this.state.country_name)}`}
                            MenuProps={{
                                PaperProps: {
                                  style: {
                                    marginTop:52,
                                  },
                                },
                            }}
                            
                        >
                            <MenuItem value="">Select Country</MenuItem>
                            {countries.map((country:any) => (
                            <MenuItem key={country.isoCode} value={country.isoCode}>
                                {country.name}
                            </MenuItem>
                            ))}
                            
                            
                        </Select>
                        </FormControl>
                        {
                            this.handleErrorMessage1('country_name')
                        }
                    </Box>
                </Grid>
                </Grid>
                <Grid container spacing={2} className='flex'>
                <Grid item sm={6} >
                    <Box>
                    <Typography className={`${this.errorTextClass('state_name',this.state.state_name)}`}>State<span className='required'>*</span></Typography>
                        <FormControl id="select-form-control" className="width-100">
                        {
                            this.SelectState(this.state.state_name,regions)
                        }
                        
                        </FormControl>
                        {
                            this.handleErrorMessage1('state_name')
                        }
                        
                    </Box>
                </Grid>
                <Grid item sm={6} >
                    <Box>
                        <Typography className={`${this.errorTextClass('zip',this.state.zip)}`}>Zip<span className='required'>*</span></Typography>
                        <TextField className={`${this.errorFocusClass('zip',this.state.zip)} form-input create-user-input`}
                        placeholder='Enter Zip'
                        data-test-id="zipInput"
                        value={this.state.zip}
                        onChange={(e:any) => this.setState({...this.state,zip:e.target.value})} 
                        name="zip" type="text" />
                        {
                            this.handleErrorMessage1('zip')
                        }
                    </Box>
                </Grid>
                </Grid>
                
                <Grid item sm={12} >
                    <Box>
                        <Typography className={`${this.errorTextClass('vat',this.state.vat)}`}>VAT<span className='required'>*</span></Typography>
                        <TextField className={`${this.errorFocusClass('vat',this.state.vat)} form-input create-user-input`}
                        placeholder='Enter VAT'
                        data-test-id="vatInput"
                        value={this.state.vat}
                        onChange={(e:any) => this.setState({...this.state,vat:e.target.value})} 
                        name="vat" type="text" />
                        {
                            this.handleErrorMessage1('vat')
                        }
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box>
                        <Typography className={`${this.errorTextClass('company_registry',this.state.company_registry)}`}>Company Registry<span className='required'>*</span></Typography>
                        <TextField className={`${this.errorFocusClass('company_registry',this.state.company_registry)} form-input create-user-input`} 
                        placeholder='Enter Company Registry'
                        data-test-id="companyRegistryInput"
                        value={this.state.company_registry}
                        onChange={(e:any) => this.setState({...this.state,company_registry:e.target.value})} 
                        name="company_registry" type="text" />
                        {
                            this.handleErrorMessage1('company_registry')
                        }
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box>
                        <Typography className={`${this.errorTextClass('currency',this.state.currency)}`}>Currency<span className='required'>*</span></Typography>
                        <FormControl id="select-form-control" className="width-100">
                
                        <Select
                            IconComponent={this.expandMore}
                            data-test-id="currencyInput"
                            inputProps={{
                                'aria-label': 'custom select',
                            }}
                            
                            value={this.state.currency}
                            displayEmpty
                            onChange={(e:any) => this.setState({...this.state,currency:e.target.value})}
                            label="Select"
                            disableUnderline
                            className={`width-100 ${this.errorFocusClass('currency',this.state.currency)}`}
                            MenuProps={{
                                PaperProps: {
                                  style: {
                                    marginTop:52,
                                  },
                                },
                            }}
                            
                        >
                            <MenuItem value="">Select Currency</MenuItem>
                            <MenuItem value="USD">USD</MenuItem>
                            
                            
                        </Select>
                        </FormControl>
                        {
                            this.handleErrorMessage1('currency')
                        }
                    </Box>
                </Grid>
                
                </Grid>
                <Grid item sm={6} >
                
                <Grid item sm={12} >
                    <Box>
                    <Typography className={`${this.errorTextClass('phone',this.state.phone)}`}>Phone<span className='required'>*</span></Typography>
                    <TextField className={`${this.errorFocusClass('phone',this.state.phone)} form-input create-user-input`}
                        placeholder='Enter Phone'
                        data-test-id="phoneInput"
                        value={this.state.phone}
                        onBlur={(e:any) => this.checkMobileLength(e.target.value)}
                        onChange={(e:any) => this.setState({...this.state,phone:e.target.value.replace(/[^0-9]/g, '')})} 
                        name="phone" type="text" />
                        {
                            this.handleErrorMessage2('phone')
                        }
                        {
                            (!this.checkMobileLength(this.state.phone)) && this.handleErrorMessage2('1',true)
                        }
                        {
                            (this.state.phone.length>15) && this.handleErrorMessage2('exceed_length',true)
                        }
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box>
                    <Typography className={`${this.errorTextClass('mobile',this.state.mobile)}`}>Mobile<span className='required'>*</span></Typography>
                    <TextField className={`${this.errorFocusClass('mobile',this.state.mobile)} form-input create-user-input`}
                        placeholder='Enter Mobile'
                        data-test-id="mobileInput"
                        value={this.state.mobile}
                        onBlur={(e:any) => this.checkMobileLength(e.target.value)}
                        onChange={(e:any) => this.setState({...this.state,mobile:e.target.value.replace(/[^0-9]/g, '')})} 
                        name="mobile" type="text" />
                        {
                            this.handleErrorMessage2('mobile')
                        }
                        {
                            (!this.checkMobileLength(this.state.mobile)) && this.handleErrorMessage2('2',true)
                        }
                        {
                            (this.state.mobile.length>15) && this.handleErrorMessage2('exceed_length',true)
                        }
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box>
                    <Typography className={`${this.errorTextClass('email',this.state.email)}`}>Email<span className='required'>*</span></Typography>
                    <TextField className={`${this.errorFocusClass('email',this.state.email)} form-input create-user-input`} 
                        placeholder='Enter Email'
                        data-test-id="emailInput"
                        value={this.state.email}
                        onBlur={(e:any) => this.isValidEmail(e.target.value)}
                        onChange={(e:any) => this.setState({...this.state,email:e.target.value})} 
                        name="email" type="text" />
                        {
                            this.handleErrorMessage2('email')
                        }
                        {
                            (!this.isValidEmail(this.state.email)) && this.handleErrorMessage2('email1',true)
                        }
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box>
                    <Typography className={`${this.errorTextClass('website',this.state.website)}`}>Website<span className='required'>*</span></Typography>
                    <TextField className={`${this.errorFocusClass('website',this.state.website)} form-input create-user-input`} 
                        placeholder='Enter Website'
                        data-test-id="websiteInput"
                        value={this.state.website}
                        onBlur={(e:any)=>this.websiteValidation(e.target.value)}
                        onChange={(e:any) => this.setState({...this.state,website:e.target.value})} 
                        name="website" type="text" />
                        {
                            this.handleErrorMessage2('website')
                        }
                        {
                            (!this.websiteValidation(this.state.website)) && this.handleErrorMessage2('3',true)
                        }
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Box sx={{position:"relative"}}>
                        <Typography className={`${this.errorTextClass('parent_company',this.state.parent_company)}`}>Parent Company<span className='required'>*</span></Typography>
                        <FormControl id="select-form-control" className="width-100">
                
                        <Select
                            IconComponent={this.expandMore}
                            data-test-id="parentCompanyInput"
                            inputProps={{
                                'aria-label': 'custom select',
                            }}
                            
                            value={this.state.parent_company}
                            displayEmpty
                            onChange={(e:any) => this.setState({...this.state,parent_company:e.target.value})}
                            label="Select"
                            disableUnderline
                            className={`width-100 ${this.errorFocusClass('parent_company',this.state.parent_company)}`}
                            MenuProps={{
                                PaperProps: {
                                  style: {
                                    marginTop:52,
                                  },
                                },
                            }}
                            
                        >
                            <MenuItem value="">Select Parent Company</MenuItem>
                            <MenuItem value="xyz">SE Company</MenuItem>
                            
                        </Select>
                        </FormControl>
                        <img className='pl-5 external-links-company' src={external} />
                        {
                            this.handleErrorMessage2('parent_company')
                        }
                    </Box>
                </Grid>
                </Grid>
                </Grid>
                
                
                </Box>
                </this.TabPanel>
                <this.TabPanel value={value} index={1}>
                <Box sx={{margin:"0 -5px"}}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{width:"42px",paddingRight:"0",paddingLeft:"0"}}>
                                <Checkbox /></TableCell>
                                <TableCell style={{paddingLeft: "5px", width: "225px"}}>COMPANY NAME</TableCell>
                                <TableCell >PARTNER</TableCell>
                                
                            </TableRow>
                        </TableHead>
                        
                        
                        
                    </Table>
                </TableContainer>
                <Box sx={{padding:"20px 15px"}}>
                <Button variant="contained"  className='save-btn' 
                >Add a Line</Button>
                </Box>
                
                <Grid container spacing={2} >
                <Grid item sm={12}></Grid>
                
                </Grid>
                </Box>
                </this.TabPanel>
                
                
                </div>

                </Box>
                
                <Box className='end-center-align form-btn-box'>
                <Button variant="outlined" data-test-id="discard-button" className='discard-btn' onClick={this.discardCompanyData}>{configJSON.discard}</Button>
                <Button variant="contained" data-test-id="save-button" className='save-btn' onClick={this.addCompany}
                   >{configJSON.save}</Button>
                
                </Box>
                <div>
        
        
      </div>
                
            </Box>
            
            </Box>
            </Box>
            </>
            
            // Customizable Area End
        )

    }
}