import React from 'react';
import { ExpandMore } from "@material-ui/icons";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { backArrowEnable, backarrow, forwardArrowDisable, forwardarrow, griddownarrow, gridleftarrow, moveBackwardArrowEnable, moveForwardArrowDisable, movebackward, moveforward, paginationarrow } from './assets';
import StorageProvider from "../../../framework/src/StorageProvider";
import { Checkbox, CircularProgress, IconButton, TableCell, TableRow, Typography } from '@material-ui/core';
import moment from 'moment';
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  defaultView:boolean;
  loadMoreRecords:boolean;
  page: number;
  per_page: number;
  name:string;
  model:string;
  group:string;
  open: boolean;
  active:any[];
  showFilter: boolean;
  showGroup: boolean;
  showFav: boolean;
  showColumn: boolean
  showCurrentSearch: boolean;
  showAddToDashboard: boolean;
  showSettings: boolean;
  token:string|null;
  showCustomerdata:any[];
  searchValue: string;
  groupValue: string;
  groupByvalue: string;
  activeGroup: string; 
  activeFilter: number;
  activeFilterArr: any[];
  filterLabel: string;
  loading: boolean;
  searchKeyword: string;
  totalCounts:number;
  totalPage:number;
  globalCheck:boolean;
  checkedArr:any[];
  
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class SettingsUserKanbanViewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apigetCustomerlistCallId!: string;
  apigetGroupbylistCallId!: string;
  apigetFilterlistCallId!: string;


  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),

      // Customizable Area Start
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      defaultView:true,
      loadMoreRecords:false,
      page:1,
      per_page:9,
      name:'',
      model:'',
      group:'',
      open: false,
      active:[],
      activeGroup: '',
      activeFilter: -1,
      activeFilterArr: [],
      showFilter: false,
      showColumn: false,
      showGroup: false,
      showFav: false,
      showCurrentSearch: false,
      showAddToDashboard: false,
      showSettings: false,
      showCustomerdata: [],
      searchValue: "",
      groupValue: "",
      groupByvalue: "",
      token:"",
      filterLabel:"",
      loading:false,
      searchKeyword: '',
      totalCounts: 0,
      totalPage:0,
      globalCheck:false,
      checkedArr:[],
      
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start


    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.setState({...this.state,loading:false});
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson.errors){
        window.alert("Invalid Token")
      }else if (apiRequestCallId && responseJson) {

        if (apiRequestCallId === this.apigetCustomerlistCallId) {
          this.apiResponse(responseJson)
        }
        if (apiRequestCallId === this.apigetFilterlistCallId) {
          this.apiResponse(responseJson)
        }
        if (apiRequestCallId === this.apigetGroupbylistCallId) {
          this.apiResponse(responseJson)
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  

  handleGlobalCheck = () => {
    let arr:any = [];
    this.state.showCustomerdata?.forEach((user:any) => {
      arr.push(user.id)
    })
    console.log('xp',arr)
    this.setState({...this.state,checkedArr:!this.state.globalCheck?arr:[],globalCheck:!this.state.globalCheck})
  }
  
  handleUserCheckbox = (id:any) => {
    console.log('xpp',id,this.state.checkedArr);
    if(this.state.checkedArr.includes(id)){
      this.setState({...this.state,globalCheck:false,checkedArr:this.state.checkedArr.filter((i)=> i !== id)})
    }else{
      this.setState({...this.state,globalCheck:(this.state.showCustomerdata.length==(this.state.checkedArr.length+1))?true:false,checkedArr:[...this.state.checkedArr,id]})
    }
  }

  handleUserActive = (id:any) => {
    if(this.state.active.includes(id)){
      this.setState({...this.state,active:this.state.active.filter((i)=> i !== id)})
    }else{
      this.setState({...this.state,active:[...this.state.active,id]})
    }
  }

  isPageLast(): boolean {
    if (!this.state.showCustomerdata.length) {
      return true;
    }
    if (!this.state.activeGroup) {
      return this.state.page === this.state.totalPage;
    }
    return true;
  }
  

  removeActiveFilter = (value:any) => {
    const filteredNumbers = this.state.activeFilterArr.filter(number => number !== value);
    this.setState({...this.state,activeFilterArr:filteredNumbers});
  }

  async componentDidMount() {
    const getToken= await StorageProvider.get("TOKEN")
    this.setState({
      token:getToken
    })
   await this.getCustomerlistApi(this.state.per_page,this.state.page)
  }

  async componentDidUpdate(prevProps: {}, prevState: S) {
      
    if ((prevState.page !== this.state.page) || (prevState.per_page !== this.state.per_page)) {
      this.state.token && await this.getCustomerlistApi(this.state.per_page,this.state.page)
    }
    
    if (prevState.activeGroup !== this.state.activeGroup || (prevState.activeFilterArr.length !== this.state.activeFilterArr.length)) {
      this.state.activeGroup == 'User Type' ? await this.getGroupbylistApi('grp_user_types') : await this.getFilterlistApi('','');
    }
    if ((prevState.searchKeyword !== this.state.searchKeyword)) {
         await this.getCustomerlistApi(this.state.per_page,1);

      if(this.state.searchKeyword.length==0){
        await this.getCustomerlistApi(this.state.per_page,1);
      }
    }
    
    
  }

  searchPlaceholderFunc = () => {
    return !(this.state.activeFilterArr.length && this.state.activeGroup) ? 'Search here....' : '';
  }

  searchIconDisplayFunc = () => {
    return this.state.activeFilterArr.length && this.state.activeGroup
  }

  filterUIfunc = () => {
    if(this.state.activeFilterArr.length >0){
      return this.state.activeGroup?'filter-box-4 flex':'filter-box-2 flex';
    }else{
      return ''
    }
    
  }

  selectedGroupListItem = (value:any) => {
    if(this.state.activeGroup == value){
      return 'selected-listItem'
    }else{
      return ''
    }
  }

  selectedListItem = (value:any) => {
    if(this.state.activeFilterArr.includes(value)){
      return 'selected-listItem'
    }else{
      return ''
    }
  }

  loaderBoxFunc = () => {
    if(this.state.loading || this.state.showCustomerdata.length==0){
      return 'loaderBox'
    }else{
      return 'display-none'
    }
  }


  loaderBoxContentFunc = () => {
    if(this.state.loading){
      return <CircularProgress />
    }else if(this.state.showCustomerdata.length==0){
      return <Typography className='bold-text'>No Records Found!</Typography>
    }else{
      return <></>
    }
  }


  listViewFunc = () => {
    if(!(this.state.defaultView && this.state.showCustomerdata.length>0 && (!this.state.loading))){ 
      return 'display-none'
    }else{
      return ''
    }
  }


  handleFilterLabel = (value:any) => {
    let flabel:string = '';
      switch(value){
        case 0 :  flabel = 'Internal Users'; break;
        case 1 :  flabel = 'E-Commerce Users'; break;
        case 2 :  flabel = 'Inactive Users'; break;
        case 3 :  flabel = 'Two-Factor Authentication Enabled'; break;
        case 4 :  flabel = 'Two-Factor Authentication Disabled'; break;
        default :  
        break;
        
      }
     return flabel;
  }


  handleActiveFilter = (value:any) => {
    !this.state.activeFilterArr.includes(value) && this.setState({...this.state,activeFilter:value,activeFilterArr:[...this.state.activeFilterArr,value],showFilter:false});
  }

  handleActiveGroup = (value:any) => {
    this.setState({...this.state,activeGroup:value,showGroup:false});
  }

  loadMoreRecords = async(action:number,type:string='',lmore:boolean=false) => {
    this.setState({
      page:action==0?1:this.state.page+action,
      loadMoreRecords:lmore
    })
    
  }

  handlePagination = async(action:number) => {
    this.setState({
      per_page:action,page:1
    })
    
  }
 
  displaySettingStyle = ()=>{
    if(this.state.checkedArr.length){
      return {display:'inline'}
    }else{
      return {display:'none'}
    }
  }
  
  navigatetoCreateCustomer = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'SettingsAddUser'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);

  }
  navigatetoGridview = () => {
    this.setState({...this.state,defaultView:false,per_page:20,page:1,checkedArr:[],globalCheck:false})

  }
  navigatetoListview = () => {
    this.setState({...this.state,defaultView:true,per_page:9,page:1})

  }
  apiResponse=(responseJson:any)=>{
    if(responseJson.data[0]?.users?.data?.length>0){
      let res:any = [];
      responseJson.data.forEach((data:any)=>{
        data.users.data.length>0 && res.push(...data.users.data)
      })
      this.setState({loadMoreRecords:false,showCustomerdata:res,totalCounts:responseJson.meta.total_counts,totalPage:responseJson.meta.total_pages});
    }else if(responseJson.data){
      (this.state.loadMoreRecords && this.state.showCustomerdata?.length>0) ? this.setState({loadMoreRecords:false,showCustomerdata:[...this.state.showCustomerdata,...responseJson.data],totalCounts:responseJson.meta.total_counts,totalPage:responseJson.meta.total_pages}) : this.setState({loadMoreRecords:false,showCustomerdata:responseJson.data,totalCounts:responseJson.meta.total_counts,totalPage:responseJson.meta.total_pages});
    }
  }
  
  handleFilter = () => {
    this.setState({ ...this.state, showFilter: !this.state.showFilter });
  };
  handleGroup = () => {
    this.setState({ ...this.state, showGroup: !this.state.showGroup });
  };
  handleFavorites = () => {
    this.setState({ ...this.state, showFav: !this.state.showFav });
  };
  handleCurrentSearch = () => {
    this.setState({ ...this.state, showCurrentSearch: !this.state.showCurrentSearch });
  };
  handleAddToDashboard = () => {
    this.setState({ ...this.state, showAddToDashboard: !this.state.showAddToDashboard });
  };
  handleSettings = () => {
    this.setState({ ...this.state, showSettings: !this.state.showSettings });
  };
  columns: string[] = ["Customer Name", "Phone", "Email", "Salesperson", "Activities", "City", "State", "Country", "VAT/TAX ID", "Next Partner Review", "Partner Level", "Activation", "Tags", "Current Membership Status"];

  getCustomerlistApi = async (per_page:number,page1:number) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":this.state.token
    };

    this.apigetCustomerlistCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/admin_listing?per_page=${per_page}&page=${page1}&search=${this.state.searchKeyword}${this.state.activeGroup ? '&grp_user_types=yes':''}${this.state.activeFilterArr.includes(0)?'&internal_user=internal_user':''}${this.state.activeFilterArr.includes(1)?'&e_commerce_user=e_commerce_user':''}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  
  

  getGroupbylistApi = async (value:string) => {
    this.setState({...this.state,loading:true,globalCheck:false,checkedArr:[],page:1,searchKeyword:''});
    const url_filter = `${this.state.activeFilterArr.includes(0)?'&internal_user=internal_user':''}${this.state.activeFilterArr.includes(1)?'&e_commerce_user=e_commerce_user':''}`; 
    const url_params = `?${value}=yes${url_filter}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":this.state.token
    };

    this.apigetGroupbylistCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/admin_listing${url_params}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getFilterlistApi = async (key:string,value:string) => { 
    this.setState({...this.state,loading:true,globalCheck:false,checkedArr:[],page:1,searchKeyword:''});
    const url_params = `?per_page=${this.state.per_page}&page=1${this.state.activeFilterArr.includes(0)?'&internal_user=internal_user':''}${this.state.activeFilterArr.includes(1)?'&e_commerce_user=e_commerce_user':''}${this.state.activeGroup == 'User Type' ? '&grp_user_types=yes' : ''}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":this.state.token
    };
    this.apigetFilterlistCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/admin_listing${url_params}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  expandMore = () => {
    return <img className='expandMoreImg' height={7} src={paginationarrow}/>;
  }

  handleOpen = () => {
        this.setState({ ...this.state,open: true });
  };
  
  handleClose = () => {
      this.setState({ ...this.state,open: false });
  };

  movebackward = () => {
    return this.state.page==1 ? movebackward : moveBackwardArrowEnable;
  }

  backarrow = () => {
    return this.state.page==1 ? backarrow : backArrowEnable;
  }

  forwardarrow = () => {
    return this.isPageLast()? forwardArrowDisable:forwardarrow;
  }

  moveforward = () => {
    return this.isPageLast()? moveForwardArrowDisable:moveforward;
  }

  kanbanView = () => {
    if(!(!this.state.defaultView && this.state.showCustomerdata.length>0 && (!this.state.loading))){
      return 'display-none'
    }else{
      return ''
    }
  }

  hideLoadMore = () => {
    if((this.state.showCustomerdata.length == this.state.totalCounts)){
      return 'display-none';
    }else{
      return '';
    }
  }

  handleGroupIndexZeroFunc = (utype:any,index1:number,index:number) => {
    if(index1==0){
      return <TableRow className='table-custom-row' data-test-id="table-custom-row" onClick={() => this.handleUserActive(index)} >
                <TableCell >
                <IconButton
                    className='arrow-icon-button'
                    aria-label="expand row"
                    size="small"
                >
                    <img src={this.state.active.includes(index) ? griddownarrow : gridleftarrow} />
                </IconButton>
                </TableCell>
                <TableCell className='table-column-bold' component="th" scope="row" colSpan={5}>
                {
                    utype == 'internal_user' ? 'Internal Users' : 'E-commerce Users'
                }
                {
                    ` (${this.state.showCustomerdata.filter((i:any)=>i?.attributes?.access_right?.user_types == utype).length})`
                }
                </TableCell>
                
            </TableRow>
    }else{
      return ''
    }
  }

  expandTableRow = (user:any,classes:any) => {
    return <TableRow>
                <TableCell>
                    <Checkbox 
                    checked={this.state.checkedArr.includes(user?.id) ? true : false} 
                    className={classes.userlistviewCheck} 
                    onChange={()=>this.handleUserCheckbox(user?.id)}
                    data-test-id="usercheckbox"
                    /></TableCell>
                <TableCell className={classes.userlistviewTabletext}>{user.attributes?.first_name}</TableCell>
                <TableCell className={classes.userlistviewTabletext}>{(user.attributes?.latest_authentication && user.attributes?.access_right?.user_types) && (user.attributes?.access_right?.user_types=='internal_user'?'Portal':'Website')}</TableCell>
                <TableCell className={classes.userlistviewTabletext}>{user.attributes?.preference?.language}</TableCell>
                <TableCell className={classes.userlistviewTabletext}>{user.attributes?.latest_authentication && moment(user.attributes?.latest_authentication).format("MM/DD/YYYY HH:mm:ss")}</TableCell>
                <TableCell className={classes.userlistviewTabletext}>{user.attributes?.access_right?.default_company}</TableCell>

            </TableRow>
  }

  // Customizable Area End
}
