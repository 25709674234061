import React from "react";
import { AppHeader } from "../../../components/src/AppHeader.web";
import Sidebar from "../../../components/src/Sidebar.web";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { CollapseTable } from "../../../components/src/CollapseTable/CollapseTable.web";

import {
  Container,
  Box,
  CardContent,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  TableHead,
  TableRow,
  Card,
  Grid,
  MenuProps,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Select,
  MenuList,
  Popper,
  Dialog,
  DialogContent,
  DialogActions,
  ListItem,
  List,
  Chip,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Divider from "@material-ui/core/Divider";
import { FILTER_FIELDS } from "../../../components/src/Utils/constants";
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import CircularProgress from '@material-ui/core/CircularProgress';
import AdvancedSearch from "../../advancedsearch/src/AdvancedSearch.web";

import {
  createTheme,
  ThemeProvider,
  withStyles,
  styled,
} from "@material-ui/core/styles";

const Images = require("./assets");

const ButtonComponent = styled("button")({
  color: "black",
  backgroundColor: "#F3F3F3",
  padding: "8px 15px",
  borderRadius: "4px",
  fontSize: "12px",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  border: "none",
  height: "100%",
  marginX: "10px",
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#2980b9",
      contrastText: "#fff",
    },
  },
});

const tableHeading = [
  "Name",
  "Responsible",
  "Product Code",
  "Sales Price",
  "Cost",
  "Category",
  "Type",
  "QTY on Hand",
  "Forecasted QTY",
  "UOM",
];
const tableBodyContent = {
  name: "LED TV",
  responsible: "Mark Demo",
  productCost: "456875DFGT",
  salesPrice: "50.00 OMR",
  cost: "30.00 OMR",
  category: "All/Delivery",
  type: "Storable Product",
  qTYOnHand: "16.00",
  forecastedQTY: "132.00",
  UMO: "Units",
};

const tableBody = Array(18).fill(tableBodyContent);

// Customizable Area End

import ItemavailabilityController, {
  Props,
  configJSON,
  groupByOptions,
  filterOptions,
  SettingOptions,
} from "./ItemavailabilityController";
import { ToastContainer } from "react-toastify";
import { noProductImg } from "./assets";
import { gridleftarrow, whiteStar } from "../../settings2/src/assets";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    "& .MuiList-padding": {
      paddingBottom: "0px",
      maxHeight: "240px"
    },
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    paddingBotton: 0,
  },
}))(MenuItem);

export default class Itemavailability extends ItemavailabilityController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  isCurrentPageOne(): boolean {
    if (!this.state.isGroupby) {
      return this.state.currentPage === 1;
    }
    return true;
  }

  isCurrentPageLast(): boolean {
    if (!this.state.responseData.length) {
      return true;
    }
    if (!this.state.isGroupby) {
      return this.state.currentPage === this.state.totalPage;
    }
    return true;
  }

  renderListAndGroupbyTable = (responseData: any) => {
    let content;

    if (this.state.isLoading) {
      content = (
        <TableRow>
          <TableCell colSpan={10}>
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "50vh", width: "100%" }}>
              <CircularProgress />
            </Box>
          </TableCell>
        </TableRow>
      );
    } else {
      content = this.state.isGroupby ? (
        <CollapseTable setEditingFalse={this.setEditingFalse}
          data={responseData} checkboxSelect={this.handleCheckBoxSelect} visibleFields={this.state.visibleFields} />
      ) : this.renderListView(responseData);
    }

    return content;
  }

  renderListView = (responseData: any) => {

    return (
      <TableBody>
        {
          this.state.isLoading ?
            <TableRow>
              <TableCell colSpan={10}>
                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "50vh", width: "100%" }}>
                  <CircularProgress />
                </Box>
              </TableCell>
            </TableRow>
            : Array.isArray(responseData) && responseData?.map((tablerow: any, index: number) => (
              <TableRow data-testId={"setEditingFalse" + index.toString()} onClick={() => this.setEditingFalse(tablerow.id)} key={index} style={webStyle.tableRow}>
                <TableCell>
                  <Box sx={webStyle.tableBox}>
                    <Checkbox
                      edge="start"
                      tabIndex={-1}
                      color="primary"
                      className="individual"
                      checked={tablerow.checked}
                      onChange={() => {
                        this.handleCheckBoxSelect(tablerow, index);
                      }}
                      data-test-id="rowCheckbox"
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Box>
                </TableCell>
                {this.state.visibleFields.includes("name") && <TableCell>{tablerow.name}</TableCell>}
                {this.state.visibleFields.includes("responsible") && <TableCell>{tablerow.responsible}</TableCell>}
                {this.state.visibleFields.includes("productCode") && <TableCell>{tablerow.productCode}</TableCell>}
                {this.state.visibleFields.includes("salesPrice") && <TableCell>{tablerow.salesPrice} {configJSON.currency}</TableCell>}
                {this.state.visibleFields.includes("cost") && <TableCell>{tablerow.cost} {configJSON.currency}</TableCell>}
                {this.state.visibleFields.includes("category") && <TableCell>{tablerow.category}</TableCell>}
                {this.state.visibleFields.includes("type") && <TableCell>{tablerow.type}</TableCell>}
                {this.state.visibleFields.includes("qtyOnHand") && <TableCell>{tablerow.qTYOnHand}</TableCell>}
                {this.state.visibleFields.includes("forecastedQty") && <TableCell>{tablerow.forecastedQTY}</TableCell>}
                {this.state.visibleFields.includes("umo") && <TableCell>{tablerow.UMO}</TableCell>}
                <TableCell></TableCell>
              </TableRow>
            ))
        }
      </TableBody>
    )
  }

  renderKanbanView = (responseData: any) => {
    return (
      responseData.length > 0 ?
        (<Grid container spacing={2} style={{ padding: "0 15px 10px 15px" }}>
          {responseData.map((tablerow: any) => (
            <Grid onClick={() => this.setEditingFalse(tablerow.id)} item xs={4} key={tablerow.id}>
              <Card style={webStyle.productCard}>
                <CardContent>
                  <Grid container style={{ width: "100%" }}>
                    <Grid item style={{ width: "35%" }}>
                      <Box>
                        <img
                          src={`${tablerow.productImage}` || noProductImg}
                          width="100px"
                          height="100px"
                        />
                      </Box>
                    </Grid>
                    <Grid item style={{ width: "45%" }}>
                      <Box px={2}>
                        <Typography
                          component="h6"
                          variant="body1"
                          style={webStyle.productDescriptionHeading}
                        >
                          {tablerow.name || ""}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={webStyle.productDescription}
                        >
                          Sales Price : {tablerow.salesPrice || ""} {configJSON.currency}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={webStyle.productDescription}
                        >
                          Cost Price : {tablerow.cost || ""} {configJSON.currency}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={webStyle.productDescription}
                        >
                          Quantity in Hand : {tablerow.qTYOnHand || ""}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={webStyle.productDescription}
                        >
                          Delivery : {`[${tablerow?.delivery}]`}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={webStyle.productDescription}
                        >
                          Product Code : {tablerow.productCode || ""}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      style={webStyle.favoriteBtn as React.CSSProperties}
                    >
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        )
        : (<Box style={webStyle.EmptyData}><Typography>No Data Found</Typography></Box>)
    )
  }


  renderPagination = () => {
    return (
      <Box sx={webStyle.paginationBox}>
        <Box sx={webStyle.itemPerPage}>
          <Typography>Items per page : &nbsp;</Typography>
          <Select
            value={this.state.rowsPerPage}
            variant="outlined"
            data-test-id="dropdown"
            style={webStyle.paginationDropdown as React.CSSProperties}
            onChange={this.handleChangeRowsPerPage}
            IconComponent={KeyboardArrowDownSharpIcon}
          >
            <MenuItem value={9}>
              09
            </MenuItem>
            <MenuItem
              value={27}
            >
              27
            </MenuItem>
            <MenuItem
              value={54}
            >
              54
            </MenuItem>
            <MenuItem
              value={99}
            >
              99
            </MenuItem>
          </Select>
        </Box>
        <Box style={webStyle.paginationText}>
          <Box style={this.state.isLoading ? webStyle.paginationCountBoxHidden as React.CSSProperties : webStyle.paginationCountBoxShow as React.CSSProperties}>
            <Typography style={webStyle.rowText as React.CSSProperties}>
              {(this.state.currentPage - 1) * this.state.rowsPerPage + 1}
              &nbsp;-&nbsp;
              {(this.state.currentPage - 1) * this.state.rowsPerPage +
                this.state.responseData.length}
            </Typography>
            <Typography>
              &nbsp;of &nbsp;
              {this.state.totalCounts}
            </Typography>
          </Box>
          <Button
            disabled={this.isCurrentPageOne()}
            data-test-id="previous-1"
            style={webStyle.paginationButton}
            onClick={() => this.handleChangePage(0)}
          >
            <img
              src={
                this.isCurrentPageOne()
                  ? Images.moveBackwardArrowDisable
                  : Images.moveBackwardArrowEnable
              }
            />
          </Button>
          <Button
            disabled={this.isCurrentPageOne()}
            data-test-id="previous-2"
            style={webStyle.paginationButton}
            onClick={() => this.handleChangePage(this.state.currentPage - 2)}
          >
            <img
              src={
                this.isCurrentPageOne()
                  ? Images.backArrowDisable
                  : Images.backArrowEnable
              }
            />
          </Button>
          <Button
            disabled={this.isCurrentPageLast()}
            data-test-id="next-1"
            style={webStyle.paginationButton}
            onClick={() => this.handleChangePage(this.state.currentPage)}
          >
            <img
              src={
                this.isCurrentPageLast()
                  ? Images.forwardArrowDisable
                  : Images.forwardArrowEnable
              }
            />
          </Button>
          <Button
            disabled={this.isCurrentPageLast()}
            data-test-id="next-2"
            style={webStyle.paginationButton}
            onClick={() => this.handleChangePage(this.state.totalPage - 1)}
          >
            <img
              src={
                this.isCurrentPageLast()
                  ? Images.moveForwardArrowDisable
                  : Images.moveForwardArrowEnable
              }
            />
          </Button>
        </Box>
      </Box>
    );
  };

  renderLoadMore = () => {
    return (<>
      {this.state.isLoadMore && <Box style={webStyle.loaderContainer} data-test-id="loadmore">
        <TableRow>
          <TableCell colSpan={10}>
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "20vh", width: "100%" }}>
              <CircularProgress />
            </Box>
          </TableCell>
        </TableRow>
      </Box>}
      {this.state.responseData.length > 17 && (<Box style={webStyle.loadMoreContainer} data-test-id="loadmore">
        <Button variant="contained" data-test-id="loadMore-btn" style={webStyle.gridviewLoadmoreButton as React.CSSProperties} onClick={this.handleLoadMore}>
          Load More Records
        </Button>
      </Box>)}
    </>
    )
  }

  renderFavoritesBox = () => {
    return (
      <ClickAwayListener
        onClickAway={this.handleFavorites}
        data-test-id="clickHandleSettingsUOMcat"
      >
        <Box className={`settingsBox`} sx={webStyle.settingsBox1}>
          <ListItem button data-testid="list-currentSearch" className='font-14 listItem favorites-list' onClick={this.handleCategoriesClick}>
            <ListItemText primary="Save Current Search" />
            <img src={gridleftarrow} />
          </ListItem>
          <ListItem button className='font-14 listItem favorites-list' data-testid="list-importRecords">
            <ListItemText primary="Import Records" onClick={() => this.renderExport()} />
          </ListItem>
          {this.state.showSaveCurrentSearch && (
            <ClickAwayListener onClickAway={() => this.setState({ showSaveCurrentSearch: false })} data-test-id="clickHandleSettingsUOMcat2">
              <Box className={`settingsBox`} sx={webStyle.settingsBox2}>
                <ListItem button className='font-14 listItem'>
                  <ListItemText primary={configJSON.uomCategories} />
                </ListItem>
                <List>
                  <ListItem>
                    <Checkbox
                      data-test-id="saveCurrentSearchCheckbox"
                      checked={this.state.useByDefault}
                      onChange={(e) => this.setState({ useByDefault: e.target.checked })}
                    />
                    <ListItemText primary="Use by default" />
                  </ListItem>

                  <Button onClick={this.saveCurrentSearch} variant="contained" style={{
                    color: "#ffffff",
                    backgroundColor: "rgba(9, 65, 115, 0.77)", marginRight: "8px", float: "right"
                  }}>Save</Button>
                </List>
              </Box>
            </ClickAwayListener>
          )}
        </Box>
      </ClickAwayListener>
    )
  }

  isViewActive = (
    isViewTypeChanged: any,
    anchorElement: any,
    responseData: any
  ) => {
    if (this.state.isLoading) {
      return (<Box style={webStyle.EmptyData}><LoaderContainer>
        <CircularProgress style={{ color: "#2A6395" }} />
      </LoaderContainer></Box>)
    }

    return isViewTypeChanged ? (
      <Box sx={{ overflow: "auto" }}>
        <TableContainer >
          {responseData.length ? <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    data-test-id="allCheckBox"
                    edge="start"
                    tabIndex={-1}
                    color="primary"
                    checked={this.state.isAllChecked}
                    onChange={this.handleAllCheck}
                    disabled={this.state.responseData.length <= 0}
                  />
                </TableCell>
                {tableHeading
                  .filter((_, index) => this.state.visibleFields.includes(FILTER_FIELDS[index].name))
                  .map((item, index) => (
                    <TableCell key={index} style={{ textTransform: "uppercase", minWidth: "150px" }}>
                      <b style={{ whiteSpace: "nowrap" }}>{item}</b>
                    </TableCell>
                  ))}
                <TableCell
                  data-test-id="toggleColumn"
                  onClick={(event) => this.toggleColumn(event.currentTarget)}
                >
                  <img src={Images.more} />
                </TableCell>

                <StyledMenu
                  anchorEl={anchorElement}
                  data-test-id="closedMenu-2"
                  open={Boolean(anchorElement)}
                  onClose={() => this.toggleColumn(null)}
                >
                  {FILTER_FIELDS.map((field) => (
                    <React.Fragment key={field.name}>
                      <StyledMenuItem
                        data-test-id="filterMenu"
                        disabled={field.disable}
                        onClick={() => { field.name !== 'name' && this.handleFilterChange(field.name) }}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            disableFocusRipple
                            disableTouchRipple
                            disableRipple
                            checked={this.state.visibleFields.includes(field.name)}
                            checkedIcon={<img src={Images.checkboxChecked} />}
                            icon={<img src={Images.checkboxUnchecked} />}
                          />
                        </ListItemIcon>
                        <ListItemText primary={field.label} />
                      </StyledMenuItem>
                      <Divider />
                    </React.Fragment>
                  ))}
                </StyledMenu>
              </TableRow>
            </TableHead>
            {this.renderListAndGroupbyTable(responseData)}
          </Table>
            : <Box style={webStyle.EmptyData}><Typography>No Data Found</Typography></Box>
          }

        </TableContainer>
        {!this.state.isGroupby && this.renderPagination()}
      </Box>
    ) : (
      <Box>
        {this.renderKanbanView(responseData)}
        {(((this.state.totalCounts - this.state.rowsPerPage) > 0) || !this.state.initialLoadComplete) && this.renderLoadMore()}
      </Box>
    )
  };

  render() {
    const { anchorElement, responseData, isViewTypeChanged } = this.state;
    const openFilter = Boolean(this.state.anchorElFilter);
    const openGroupBy = Boolean(this.state.anchorElGroupBy);

    return (
      <ThemeProvider theme={theme}>
        <Box sx={webStyle.MainLayout}>
          <ToastContainer />
          <Box sx={webStyle.sideBar}>
            <Sidebar />
            <Box sx={webStyle.content}>
              <AppHeader pageTitle="Product" />
              <Card style={webStyle.mainContainer}>
                <CardContent style={webStyle.listCard}>
                  <Box style={webStyle.listHeading}>
                    <Typography
                      variant="h6"
                      style={webStyle.topbarTitle as React.CSSProperties}
                    >
                      List of Products
                    </Typography>
                    <Box sx={webStyle.productlistviewSearchbox}>
                      {this.state.showInfoCurrentInSearchBar ?
                        <Box sx={webStyle.saveCurrentSearchBox}>
                          <Box style={{ position: "relative", marginLeft: "10px" }}>
                            <Chip
                              style={{ backgroundColor: "#006395", color: "#ffffff", padding: "0 25px" }}
                              label={"Save Current Search : List of Products"}
                            />
                            <span
                              className={"handleFilterIcon"}
                            >
                              <img src={whiteStar} style={webStyle.uomlistviewFilterImg} />
                            </span>
                            <span
                              data-testid={`handleRemoves`}
                              className="handleRemoves"
                              onClick={() => this.setState({ useByDefault: false }, this.saveCurrentSearch)}
                            >
                              ×
                            </span>
                          </Box>
                        </Box> :
                        <AdvancedSearch
                          data-test-id="advanceSearch"
                          navigation={undefined}
                          id={""}
                          classes={{}}
                          queryItems={this.state.queryItems}
                          onChangeSearchField={this.onChangeSearchField}
                          handleRemoveTag={this.handleRemoveTag}
                          querySearch={this.state.query}
                        />}
                    </Box>
                  </Box>
                  <Box sx={webStyle.tableMainContainer}>
                    <Box sx={webStyle.tableOption}>
                      <ClickAwayListener
                        onClickAway={this.handleClickAwayFilter}
                        data-test-id="clickAwayFilter"
                      >
                        <div>
                          <Button
                            onClick={this.handleClickFilter}
                            style={webStyle.buttonComponent}
                          >
                            <img src={Images.filter} /> &nbsp; Filters
                          </Button>
                          <Popper
                            open={openFilter}
                            anchorEl={this.state.anchorElFilter}
                            transition
                            style={webStyle.popper}
                            placement="bottom-start"
                          >
                            <MenuList style={{ padding: 0 }}>
                              {filterOptions.map((item) => (
                                <div
                                  style={{
                                    borderBottom: "2px solid #F3F3F3",
                                  }}
                                >
                                  <MenuItem
                                    data-test-id="filterMenuPopper"
                                    key={item.name}
                                    data-value={item.name}
                                    style={webStyle.popperItem}
                                    onClick={() => {
                                      this.handleFilterSelect(item);
                                    }}
                                  >
                                    {item.name}
                                  </MenuItem>
                                </div>
                              ))}
                            </MenuList>
                          </Popper>
                        </div>
                      </ClickAwayListener>
                      <ClickAwayListener
                        onClickAway={this.handleClickAwayGroup}
                        data-test-id="clickAwayGroup"
                      >
                        <div>
                          <Button
                            onClick={this.handleClickGroupBy}
                            style={webStyle.buttonComponent}
                          >
                            <img src={Images.multipleFile} /> &nbsp; Group by
                          </Button>
                          <Popper
                            open={openGroupBy}
                            anchorEl={this.state.anchorElGroupBy}
                            transition
                            style={webStyle.popper}
                            placement="bottom-start"
                          >

                            <MenuList style={{ padding: 0 }}>
                              {groupByOptions.map((item) => (
                                <div
                                  style={{
                                    borderBottom: "1px solid #F3F3F3",
                                  }}
                                >
                                  <MenuItem
                                    data-test-id="groupMenuPopper"
                                    key={item.name}
                                    data-value={item.name}
                                    style={webStyle.popperItem}
                                    onClick={() => {
                                      this.handleGroupBySelect(item);
                                    }}
                                  >
                                    {item.name}
                                  </MenuItem>
                                </div>
                              ))}
                            </MenuList>

                          </Popper>
                        </div>
                      </ClickAwayListener>

                      <Box sx={webStyle.listviewColumnbox}>
                        <Button style={webStyle.buttonComponent} onClick={this.handleFavorites}>
                          <img src={Images.favourite} /> &nbsp; Favorites
                        </Button>
                        {this.state.showFav && this.renderFavoritesBox()}
                      </Box>
                    </Box>

                    <Box sx={webStyle.tableOption}>
                      {(this.state.isAllChecked) && this.state.responseData.length ||
                        Object.keys(this.state.selectedItems).length ? (
                        <>
                          <ClickAwayListener onClickAway={this.handleClickAwaySettings} data-test-id="clickAwaySetting">
                            <div>
                              <Button
                                onClick={this.handleClickSetting}
                                data-test-id="toggleMenu"
                                style={webStyle.buttonComponentSettings}
                              >
                                <img src={Images.settingsGrey} />
                              </Button>
                              <Popper open={Boolean(this.state.anchorEl)} anchorEl={this.state.anchorEl} transition style={webStyle.settingsPopper}
                                placement="bottom-end">
                                <MenuList style={{ padding: 0 }}>
                                  {SettingOptions.filter(item => {
                                    const noFiltersApplied = (this.state.queryItems?.length === 0);
                                    if (noFiltersApplied && item.name === "Unarchive") {
                                      return false;
                                    }
                                    return !this.state.queryItems?.some(queryItem => queryItem.key === item.name.toLowerCase())
                                  }).map((item, index) => (
                                    <div style={{ borderBottom: "1px solid #F3F3F3", }}>
                                      <MenuItem data-test-id={`closedMenu-${index}`} key={item.name} data-value={item.name} style={webStyle.popperItem} onClick={() => { this.handleSettingAction(item.name, this.state.selectedItems) }}>
                                        {item.name}
                                      </MenuItem>
                                    </div>
                                  ))}
                                </MenuList>

                              </Popper>
                            </div>
                          </ClickAwayListener>
                        </>
                      ) : null}
                      <ButtonComponent
                        data-test-id="listView"
                        color="primary"
                        style={
                          this.state.isViewTypeChanged ? webStyle.active : {}
                        }
                        onClick={() => {
                          this.setState({ queryItems: [], isViewTypeChanged: true, rowsPerPage: 9 });
                        }}
                      >
                        <img
                          src={Images.listView}
                          alt="listview"
                          style={
                            this.state.isViewTypeChanged
                              ? {}
                              : { filter: "invert(1)" }
                          }
                        />
                      </ButtonComponent>
                      <ButtonComponent
                        data-test-id="canbanView"
                        style={
                          this.state.isViewTypeChanged ? {} : webStyle.active
                        }
                        onClick={() => {
                          this.setState({ queryItems: [], isViewTypeChanged: false, rowsPerPage: 18, isLoading: true, selectedItems: {}, isGroupby: false, isAllChecked: false, anchorEl: null });
                        }}
                      >
                        <img
                          src={Images.canban}
                          alt="canbanview"
                          style={
                            this.state.isViewTypeChanged
                              ? {}
                              : { filter: "invert(1)" }
                          }
                        />
                      </ButtonComponent>
                      <ButtonComponent color="primary" style={webStyle.active}
                        data-test-id="navigationButton"
                        onClick={
                          this.setEditingTrue
                        }
                      >
                        <Typography style={{ marginRight: "5px" }}>
                          Create Product
                        </Typography>
                        <img src={Images.add} />
                      </ButtonComponent>
                    </Box>
                  </Box>
                  {this.isViewActive(
                    isViewTypeChanged,
                    anchorElement,
                    responseData
                  )}
                </CardContent>
              </Card>
            </Box>
          </Box>
          <Dialog
            maxWidth={"sm"}
            data-test-id="deleteDialog"
            open={this.state.deleteDialog}
            onClose={this.handleDialogCancel}>
            <DialogContent>
              <Typography>
                Are you sure you want to delete ?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="outlined"
                onClick={this.handleDialogCancel}
                data-test-id="dialogCancelBtn">
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={this.OnDelete}
                data-test-id="dialogDeleteBtn">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  MainLayout: {
    display: "flex",
    width: "100%",
  },
  sideBar: {
    display: "flex",
    minHeight: "100%",
    width: "100%",
    transition: "all 0.3s",
  },
  content: {
    padding: "0 20px",
    flexGrow: 1,
    width: "calc( 100% - 300px)",
    backgroundColor: "#f3f3f3",
    overflow: "auto",
  },
  tableBox: {
    display: "flex",
    alignItems: "center",
  },
  mainWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
    margin: "5px 0",
  },
  paginationBox: {
    display: "flex",
    alignItems: "center",
    padding: "15px",
    justifyContent: "space-between",
    height: "50px",
  },
  itemPerPage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  paginationArrows: {
    width: "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  paginationNumber: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cursorPointor: {
    cursor: "pointer" as string,
  },
  active: {
    backgroundColor: "#006395",
    color: "#fff",
  },
  tableOption: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
    margin: "5px 0",
    height: "100%",
  },
  tableMainContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
    margin: "5px 0",
    height: "50px",
    padding: "15px",
  },
  uomlistviewFilterImg: {
    paddingRight: "8px"
  },
  productlistviewSearchbox: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    position: "relative",
  },
  tableMainContainerKanban: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
    margin: "5px 0",
    height: "50px",
    padding: "15px"
  },
  paginationButton: {
    height: "100%",
  },
  productCard: {
    border: "1px solid #d9d9d9",
  },
  mainContainer: {
    border: "1px solid #d9d9d9",
    marginBottom: "20px",
    boxShadow: "none",
    padding: 0
  },
  singleLine: {
    whiteSpace: "nowrap",
  },
  productDescription: {
    width: "150px",
    textWrap: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "12px"
  },
  disabledPaginationButton: {
    color: "lightgrey",
    fill: "lightgrey",
  },
  enabledPaginationButton: {
    color: "grey",
    fill: "grey",
  },
  settingsBox1: {
    top: "55px !important",
    borderRadius: "5px !important",
    right: '-89px',
  },
  saveCurrentSearchBox: {
    border: "1px solid #c4c4c4",
    borderRadius: "4px",
    padding: "8px 12px",
    width: '440px',
    display: "flex",
    alignItems: "center",
    height: "40px",
  },
  settingsBox2: {
    position: 'absolute',
    top: ' 0px !important',
    left: '100%',
    marginLeft: '4px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    backgroundColor: 'white',
    paddingBottom: '8px',
    borderRadius: '4px',
    zIndex: 3,
    width: '221px',
  },
  listHeadingKanban: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: "15px",
    borderBottom: "1px solid #F3F3F3",
  },
  listHeading: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: "15px",
    borderBottom: "1px solid #F3F3F3",
  },
  searchBar: {
    minWidth: "250px",
    border: "none !important",
    "& .MuiOutlinedInput-root": {
      "& input": {
        border: "none !important",
      },
      width: "500px",
      height: "100%",
      "& .MuiOutlinedInput-input": {
        border: "none !important",
      },
    },
  },
  topbarTitle: {
    color: "#006395",
    fontWeight: "bold",
  },
  popper: {
    zIndex: theme.zIndex.tooltip,
    marginTop: "4px",
    border: "1px solid #ccc",
    borderRadius: 4,
    backgroundColor: "#ffffff",
    padding: 0,
    width: 200,
  },
  listviewColumnbox: {
    position: "relative",
  },
  settingsPopper: {
    zIndex: theme.zIndex.tooltip,
    marginTop: "4px",
    border: "1px solid #ccc",
    borderRadius: 4,
    backgroundColor: "#ffffff",
    padding: 0,
    width: 90,
  },
  popperItem: {
    fontSize: "small",
    borderBottom: "1px solid #F3F3F3 !important",
  },
  buttonComponent: {
    color: "black",
    backgroundColor: "#e1e1e1",
    padding: "8px 15px",
    borderRadius: "4px",
    fontSize: "12px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    border: "none",
    height: "100%",
  },
  buttonComponentSettings: {
    color: "black",
    backgroundColor: "#e1e1e1",
    padding: "8px 8px",
    borderRadius: "4px",
    fontSize: "12px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    border: "none",
    height: "100%",
  },
  rowText: {
    fontWeight: "bold",
  },
  favoriteBtn: {
    width: "10%",
    position: "relative",
    right: "-50px",
  },
  paginationDropdown: {
    fontWeight: "bold",
    outline: "1px solid #f3f3f3",
    borderRadius: "4px",
    height: "40px",
    padding: "0px 5px"
  },
  tableRow: {
    borderBottom: "1px solid #E5E5E5"
  },
  listCard: {
    padding: 0
  },
  productDescriptionHeading: {
    width: "150px",
    textWrap: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  paginationText: {
    display: "flex",
    height: "40px",
    alignItems: "center",
  },
  paginationCountBoxHidden: {
    visibility: "hidden",
    display: "flex",
    flexDirection: "row"
  },
  paginationCountBoxShow: {
    display: "flex",
    flexDirection: "row"
  },
  EmptyData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
  },
  loadMoreContainer: {
    marginBlock: "3%",
    display: "flex",
    justifyContent: "center",
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
  },
  gridviewLoadmoreButton: {
    backgroundColor: "#f3f3f3",
    color: "#3d3d3d",
    margin: "15px 10px",
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: "500"
  }
};
const LoaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '40vh',
})

// Customizable Area End