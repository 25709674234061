import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from 'react';
export type token = string | null;
import { getStorageData } from "../../../framework/src/Utilities";
import {
  filterTableData
} from "../../../components/src/Utils/utils";
import { TableData } from "../../../components/src/Utils/types";


type tableBodyContentType = {
  id: string;
  type: string,
  checked: boolean,
  customer_code: string;
  customer_name: string;
  order_date: Date;
  invoice_address: string;
  delivery_address: string;
  payment_terms: string;
  status_of_order: string;
  total_items: 2,
  branch_name: string;
  sales_name: string;
  pricelist: string;
  quotation_no: string;
  terms_and_conditions: string;
  margin: string;
  total: number,
  tax_amount: [],
  invoice_status: string;
  archived: boolean;
  account_id: number;
  order_lines: [],
};

type groupbyBodyContentType = {
  key: string;
  data: tableBodyContentType[];
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isViewTypeChanged: boolean;
  anchorElement: HTMLElement | null;
  selectedFilter: string;
  anchorEl: HTMLElement | null;
  isAllChecked: boolean;
  isChecked: boolean;
  token: token;
  responseData:any;
  filteredData: TableData[];
  currentPage: number;
  rowsPerPage: number;
  totalCounts: number;
  row: {
    from: number;
    end: number;
  };
  totalPage: number;
  dataLength: number;
  query: string;
  anchorElFilter: HTMLElement | null;
  anchorElPrint: HTMLElement | null;
  anchorElGroupBy: HTMLElement | null;
  selectedItems: {[name: string]: boolean;};
  params: { [name: string]: boolean | string };
  isGroupby: boolean;
  isLoading: boolean;
  queryItems: { id: string, tag: string, key: string, type: string }[];
  isExpanded:boolean[];
  tableData:any;
  isLoadMore: boolean;
  initialLoadComplete: boolean;
  remainingItems: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export interface Option {
  value: string;
  label: string;
}


export default class OrderlistingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createShippingMethodAPI: string = "";
  getOrderListing: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),getName(MessageEnum.RestAPIRequestMessage),getName(MessageEnum.NavigationMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isViewTypeChanged: true,
      anchorElement: null,
      selectedFilter: "",
      anchorEl: null,
      isAllChecked: false,
      isChecked: false,
      token: "",
      responseData:[],
      filteredData: [],
      currentPage: 1,
      rowsPerPage: 9,
      totalCounts: 0,
      row: {
        from: 1,
        end: 9,
      },
      totalPage: 0,
      dataLength: 0,
      query: "",
      anchorElFilter: null,
      anchorElPrint: null,
      anchorElGroupBy: null,
      selectedItems: {},
      params: {},
      isGroupby: false,
      isLoading: false,
      queryItems: [],
      isExpanded:[],
      tableData:[],
      isLoadMore: false,
      initialLoadComplete: false,
      remainingItems: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(
      this as IBlock, this.subScribedMessages
    );

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog(
      "Message Recived", 
      message
    );

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert("Change Value","From: " + this.state.txtSavedValue + " To: " + value);

      this.setState({ 
        txtSavedValue: value 
      });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.receiveApi(apiRequestCallId, responseJson);
      }
      runEngine.debugLog(
        "API Message Recived", message
      );
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const token = await getStorageData("TOKEN")
    this.setState({ token: token });
    if (!token) {this.props.navigation.navigate("EmailAccountLoginStyles")}
    else {this.orderListingData();
    }
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    const token = this.state.token;
    if (prevState.query !== this.state.query ||
      (prevState.currentPage !== this.state.currentPage ||
        prevState.rowsPerPage !== this.state.rowsPerPage)
    ) {
      let searchString = ""
      if (this.state.query) {
        searchString = `&search=${this.state.query}`
      }
      const { currentPage, rowsPerPage } = this.state;
      let params = {} as any
    this.state.queryItems.forEach((item: any) => {
      if (item.hasOwnProperty("key")) {params[item.key] = true}
    })

    let str = Object.keys(params).map(function (key) {return key + "=" + params[key];}).join("&");
      str = `${str}&`+`page=${currentPage}&per_page=${rowsPerPage}${searchString}`
      this.getOrderListing = this.handleApiCall(token,
        `/bx_block_order_management/quotations?${str}`, "GET"
      );
    }
    else if (
      prevState.currentPage !== this.state.currentPage ||
      prevState.rowsPerPage !== this.state.rowsPerPage ||
      prevState.queryItems.length !== this.state.queryItems.length) {
      this.orderListingData()
    }
    else if(prevState.queryItems !== this.state.queryItems){
      this.orderListingData()
    }
  }
  groupByOptions = [
    { name: "Sales Person", key: "saler_name" },
    { name: "Customer", key: "customer_name" },
    { name: "Order Date", key: "group_by_order_date" },
  ];

  filterOptions = [
    { name: "My Orders", key: "my_orders" },
    { name: "Invoice Status", key: "invoice_status" },
    { name: "Order Date", key: "order_date" },
    { name: "Archieved", key: "archived" },

  ];

  PrintOptions = [
    { name: "Quotation/Order" },
    { name: "Pickup and Return reciepts" },
  ];

  SettingOptions = [
    { name: "Export" },
    { name: "Delete" },
    { name: "Create Invoices" },
  ];



  receiveApi = (
    apiRequestCallId: string,
    responseJson: {data: object[];
      meta: { total_pages: number; 
        total_counts: number 
      };
    }
  ) => {
    if (apiRequestCallId === this.getOrderListing) {
      this.handleGetItemResponse(responseJson.data,
        responseJson.meta.total_pages,responseJson.meta.total_counts
      );
    }
  };


  handleGetItemResponse = (
    data: object[],
    totalPage: number,
    totalCounts: number
  ) => {
    const tableInfo = this.state.isGroupby ?
      this.getCollapseTableKey(data) : this.getTableKey(data);
    this.setState({
      responseData: tableInfo,
      totalPage, totalCounts,
      isLoading: false,
      isLoadMore: false,
    })
  };


  getTableKey = (data: any) => {
    const requiredData: any = [];
    data.map((item: any) => {
      const attributes = item.attributes;
      const type = item.type;
      const id = item.id;
      const shippingInfo: any = {
        id,
        type,
        checked: false,
        customer_code: attributes.customer_code || "",
        customer_name: attributes.customer_name || "",
        order_date: attributes.order_date || "",
        invoice_address: attributes.invoice_address || "",
        delivery_address: attributes.delivery_address || "",
        payment_terms: attributes.payment_terms || "",
        status_of_order: attributes.status_of_order || "",
        total_items: attributes.total_items || 0,
        branch_name: attributes.branch_name || "",
        sales_name: attributes.sales_name || "",
        pricelist: attributes.pricelist || "",
        quotation_no: attributes.quotation_no || "",
        terms_and_conditions: attributes.terms_and_conditions || "",
        margin: attributes.margin || "",
        total: attributes.total || "",
        tax_amount: attributes.tax_amount || "",
        invoice_status: attributes.invoice_status || "",
        archived: attributes.archived || "",
        account_id: attributes.account_id || "",
        order_lines: attributes.order_lines || "",
      };
      requiredData.push(shippingInfo);
    })
    return requiredData;
  }

  getCollapseTableKey = (data: any) => {
    const requiredData = data?.map((item: any) => {
      let payload = {
        "key": item.key,
        data: []
      }
      payload.data = this.getTableKey(item.quotations.data)
      return payload
    })
    return requiredData;
  }

  handleRowClick = (orderId: string) => {
    this.props.navigation.navigate("Ordercreation1", { id: orderId });
  }


  handleSettingsClickAway = () => {
    this.setState({ 
      anchorEl: null 
    });
  };

  handleFilterClickAway = () => {
    this.setState({ 
      anchorElFilter: null 
    });
  };
  
  handleGroupClickAway = () => {
    this.setState({ 
      anchorElGroupBy: null 
    });
  };

  handlePrintClickAway = () => {
    this.setState({ 
      anchorElPrint: null 
    });
  };

  handleClickGroupBy = (event: any) => {
    this.setState({anchorElGroupBy: this.state.anchorElGroupBy ? null : event.currentTarget,});
  };

  handleClickFilter = (e: any) => {
    this.setState({anchorElFilter: this.state.anchorElFilter ? null : e.currentTarget,});
  };

  handleClickSetting = (e: any) => {
    this.setState({anchorEl: this.state.anchorEl ? null : e.currentTarget,});
  };

  showSettingIcon = () => {
    if(this.state.isAllChecked || Object.keys(this.state.selectedItems).length)
      return true
    else
      return false
  }

  handleClickPrint = (e: any) => {
    this.setState({ anchorElPrint: this.state.anchorElPrint ? null : e.currentTarget, });
  };

  handleGroupBySelect = (item: { name: string; key: string }) => {
    if (!this.state.isViewTypeChanged) {
      this.setState({
        isViewTypeChanged: true,
      })
    }
    this.setState((prevState) => { const updatedQueryItems = [...prevState.queryItems];
      const existingIndex = updatedQueryItems.findIndex((queryItem) => queryItem.type === "groupby");
      if (existingIndex !== -1) {updatedQueryItems[existingIndex] = {id: item.key,tag: item.name,key: item.key,type: "groupby", };} 
      else {updatedQueryItems.push({id: item.key,tag: item.name,key: item.key,type: "groupby",});}
      return { queryItems: updatedQueryItems };
    });
    this.setState({
      isAllChecked: false,
      anchorElGroupBy: null,
      isGroupby: true,
      selectedItems: {},
      isLoading:true
    })
  };

  handleChangePage = (pageNo: number) => {
    this.setState({currentPage: pageNo + 1,});
    if (this.state.currentPage > pageNo) {this.setState((prev) => ({row: {from: prev.row.from - this.state.rowsPerPage,end: prev.row.end - this.state.rowsPerPage,},}));} 
    else {this.setState((prev) => ({row: {from: prev.row.from + this.state.rowsPerPage,end: prev.row.end + this.state.rowsPerPage,},}));}
    this.setState({isAllChecked: false,})
  };

  handleLoadMore = () => {
    const { totalCounts, rowsPerPage, remainingItems, initialLoadComplete } = this.state;
    const leftOver = totalCounts - rowsPerPage;
    const newRowsPerPage = rowsPerPage + 18;
    this.setState({ remainingItems: leftOver, isLoadMore: true, rowsPerPage: newRowsPerPage })

    if (!initialLoadComplete || (leftOver > 0 && remainingItems > 0)) {
      this.setState({
        remainingItems: leftOver,
        isLoadMore: true,
        initialLoadComplete: true,
        rowsPerPage: newRowsPerPage,
      });
    }
  }

  handleFilterAllChecked = (updatedSelectedItem: { [name: string]: boolean }) => {
    const filterData = 
    this.state.responseData as any[]
    const checkBoxData = filterData.map((item: any) => {
      item.checked = !this.state.isAllChecked;
      if (item.checked) {
        updatedSelectedItem[item.id] = item.checked;
      } else {if(updatedSelectedItem.hasOwnProperty(item.id)) {delete updatedSelectedItem[item.id];}}
      return item;
    });
    this.setState((prev) => {
      return {
        selectedItems: updatedSelectedItem,
        isAllChecked: !prev.isAllChecked,
        responseData: checkBoxData,
      };
    });
    this.setState({isLoading: false,})
  }

  handleGroupbyAllChecked = (updatedSelectedItem: { [name: string]: boolean }) => {
    const groupbyData = 
    this.state.responseData as any[]
    const checkBoxData = groupbyData.map((element: any) => {
      let updatedElement = element
      updatedElement.data = element.data?.map((item: any) => {
        item.checked = !this.state.isAllChecked;
        if (item.checked) {
          updatedSelectedItem[item.id] = item.checked;
        } 
        else {if (updatedSelectedItem.hasOwnProperty(item.id)) { delete updatedSelectedItem[item.id];}}
        return item;
      })
      return updatedElement
    }
    );
    this.setState((prev) => {
      return {
        selectedItems: updatedSelectedItem,isLoading: false,isAllChecked: !prev.isAllChecked,responseData: checkBoxData,
      };
    });
  }

  handleAllChecked = () => {
    let updatedSelectedItem = Object.assign({}, this.state.selectedItems);
    if (!this.state.isGroupby) {this.handleFilterAllChecked(updatedSelectedItem)} 
    else {this.handleGroupbyAllChecked(updatedSelectedItem)}
  };

  handleFilterSelect = (item: { name: string; key: string }) => {
    const existingItemIndex = this.state.queryItems.findIndex(
      (queryItem) => queryItem.key === item.key
    );
    if (existingItemIndex !== -1) {
      this.setState({ anchorElFilter: null });
    } else {
      this.setState({
        queryItems: [
          ...this.state.queryItems,
          { id: item.key, tag: item.name, key: item.key, type: "filter" },
        ],
        anchorElFilter: null,
        currentPage:1,
        selectedItems:{},
        isGroupby:false,
        isAllChecked:false,
        anchorEl:null,
        isLoading:true
      });
    }
  };

  handleChangeRowsPerPage = (event:any) => {
    this.setState({currentPage: 1,rowsPerPage: parseInt(event.target.value as string),});
  };

  toggleColumn = (anchorElement: HTMLElement | null) => {
    this.setState({ anchorElement });
  };

  toggleIsExpanded = (rowKey: number) => {
    const expandableRows=this.state.isExpanded
    expandableRows[rowKey] = !expandableRows[rowKey]
    this.setState({isExpanded:expandableRows})
  };

  onChangeSearchField = (e: any) => {
    const { value } = e.target;
    this.setState({query: value});
    return {};
  }

  handleRemoveTag = (item: { id: string; tag: string; key: string; type: string; }) => {
    const updatedQueryItems = this.state.queryItems.filter((queryItem) => queryItem.key !== item.key);
    if (item.type === "groupby") {
      this.setState({
        isGroupby: false ,
        queryItems: updatedQueryItems , 
        query: "",
        currentPage: 1,
        rowsPerPage:9,
        isAllChecked:false,
        selectedItems:{},
        isLoading:true
      });
    }
    else {
      this.setState({
        isAllChecked: false,
        selectedItems: {},
        queryItems: updatedQueryItems,
        query: "",
        currentPage: 1,
        isLoading:true
      });
    }
  }

  orderListingData = async () => {
    const token = this.state.token;
    const { currentPage, rowsPerPage } = this.state;
    let params = {} as any
    this.state.queryItems.forEach((item: any) => {
      if (item.hasOwnProperty("key")) {params[item.key] = true}
    })

    const str = Object.keys(params).map(function (key) {return key + "=" + params[key];}).join("&");
    this.getOrderListing = this.handleApiCall(token,
      this.state.isGroupby ? `/bx_block_order_management/quotations?${str}` : 
      `/bx_block_order_management/quotations?page=${currentPage}&per_page=${rowsPerPage}&${str}`,"GET"
    );
  };


  handleCheckBoxSelectGroupby = (item: any, index: number, updatedSelectedItem: { [x: string]: boolean }, checkBoxData: any) => {
    if (item.checked) {updatedSelectedItem[item.id] = true;} 
    else if (updatedSelectedItem.hasOwnProperty(item.id)) {delete updatedSelectedItem[item.id];}

    checkBoxData.map((element:any,index:number)=>{element.data.map((ele:any,ind:any)=>{if(ele.id==item.id){element=item}})})
    return checkBoxData.every((element: any) => {return element.data.every((item: any) => item.checked) === true})
  }

  handleCheckBoxSelectFilter = (item: any, index: number, updatedSelectedItem: { [x: string]: boolean }, checkBoxData: any) => {
    if (item.checked) {
      updatedSelectedItem[item.id] = true;
    } 
    else if (updatedSelectedItem.hasOwnProperty(item.id)) { 
      delete updatedSelectedItem[item.id];
    }
    checkBoxData[index] = item;
    return checkBoxData.every((item: any) => item.checked);
  }

  handleFilterChange = (selectedFilters: Array<string>) => {
    const { tableData} = this.state;
    const filteredResult = filterTableData(
      tableData, selectedFilters);
    this.setState({
      filteredData: filteredResult,
      isAllChecked: false,
    });
  };
  
  handleDate=(givenDate:string)=>{
    const date = new Date(givenDate);
    return isNaN(date.getTime()) 
        ? "" 
        : date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
  }

  handleDateAndTime=(givenDate:string)=>{
        const originalDate = new Date(givenDate);
        const formattedDate = originalDate.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
        const formattedTime = originalDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
        const result = `${formattedDate} ${formattedTime}`;
        return result;
  }


  handleCheckBoxSelect = (item: any, index: number) => {
    const updatedSelectedItem = { ...this.state.selectedItems };
    const checkBoxData = [...this.state.responseData] as any
    let isAllChecked = false
    item.checked = !item.checked;
    if (this.state.isGroupby) {
      isAllChecked = this.handleCheckBoxSelectGroupby(item, index, updatedSelectedItem, checkBoxData);
    } else {
      isAllChecked = this.handleCheckBoxSelectFilter(item, index, updatedSelectedItem, checkBoxData);
    }

    this.setState({
      responseData: checkBoxData,
      selectedItems: updatedSelectedItem,
      isAllChecked: isAllChecked,
    });
    this.setState({
      isLoading: false, isLoadMore: false,
    })
  };


  handleApiCall = (
    token: token, 
    endPoint: string, 
    method: string, 
    body?: object) => {
    
    const header = {
      "Content-Type": "application/json",
      token: token, 
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),method);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));


    runEngine.sendMessage(
      requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  // Customizable Area End
}
