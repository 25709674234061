// Customizable Area Start
import React from 'react';
import { Box, Button, Checkbox, Chip, ClickAwayListener, Dialog, DialogActions, DialogContent, FormControl, Grid, List, ListItem, ListItemText, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, createTheme, withStyles } from '@material-ui/core';
import UomCategorieslistviewController, { Props } from './UomCategorieslistviewController.web'

import '../../../settings2/assets/style.css'
export const configJSON = require("../../../settings2/src/config");
import { add, backArrowEnable, backarrow, filter, filtericon, forwardArrowDisable, forwardarrow, gridleftarrow, moveBackwardArrowEnable, moveForwardArrowDisable, movebackward, moveforward, search, star, whiteStar } from '../../../settings2/src/assets';
import { AppHeader } from '../../../../components/src/AppHeader.web';
import Sidebar from '../../../../components/src/Sidebar.web';
import { groupWhite } from '../../../../components/src/assets';
import { settingsGrey } from '../../../itemavailability/src/assets';
export const theme = createTheme({
    palette: {
        primary: {
            main: "#ffcc00",
            contrastText: "#fff",
        },
    },
});
import { ToastContainer } from 'react-toastify'
// Customizable Area End

export default class UomCategorieslistview extends UomCategorieslistviewController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const { showFilter, showSettingsUOMcat,showFav,  } = this.state;
        const { classes }: any = this.props;
        const searchPlaceholder = this.searchPlaceholderFnUOMcat() as string;

        const renderSearchChips =()=>{
            return (
                <Box className={`${this.checkGroupStatus(this.state.searchArray.length)}`} sx={{position:"absolute",left:"0px",top:"12px"}}>
                                            <Box sx={{display:"flex"}}>
                                                    {
                                                    this.state.searchArray.map((data:any,indexx:number)=>{
                                                        return <Box style={{position:"relative",marginLeft:"10px"}}>
                                                        <Chip style={{backgroundColor:"#006395",color:"#ffffff",padding:"0 25px"}} 
                                                            label={this.chipLabelUOMcat(data.value)} />
                                                        <span className={this.getIconClassName(data.type)} >
                                                            <img src={this.getIconSrc(data.type)} />
                                                        </span>
                                                        <span data-testid={`handleRemoves${indexx}`} 
                                                            className='handleRemoves' 
                                                            onClick={()=>this.handleRemove(data.type, data.value)} 
                                                            >×</span>
                                                            </Box>
                                                        })
                                                    }
                                                </Box>
                                            </Box>
            )
        }

        const renderFavoritesBox=()=> {
            return (
                <ClickAwayListener
                                                onClickAway={this.handleFavorites}
                                                data-test-id="clickHandleSettingsUOMcat"
                                            >
                                                <Box className={`settingsBox ${classes.settingsBox1}`}>
                                                    <ListItem button data-testid="list-currentSearch" className='font-14 listItem favorites-list' onClick={this.handleUomCategoriesClick}>
                                                        <ListItemText primary="Save Current Search" />
                                                        <img src={gridleftarrow} />
                                                    </ListItem>
                                                    <ListItem button className='font-14 listItem favorites-list' data-testid="list-importRecords">
                                                        <ListItemText primary="Import Records"  onClick={()=>this.renderExport()}/>
                                                    </ListItem>
                                                    {this.state.showSaveCurrentSearch && (
                                                        <ClickAwayListener onClickAway={() => this.setState({ showSaveCurrentSearch: false })} data-test-id="clickHandleSettingsUOMcat2">
                                                            <Box className={`settingsBox ${classes.settingsBox2}`}>
                                                                <ListItem button className='font-14 listItem'>
                                                                    <ListItemText primary={configJSON.uomCategories} />
                                                                </ListItem>
                                                                <List>
                                                                    <ListItem>
                                                                        <Checkbox
                                                                        data-test-id="saveCurrentSearchCheckbox"
                                                                        checked={this.state.useByDefault}
                                                                        onChange={(e) => this.setState({useByDefault:e.target.checked})}
                                                                        />
                                                                        <ListItemText primary="Use by default" />
                                                                    </ListItem>
                                                                    
                                                                    <Button onClick={this.saveCurrentSearch} variant="contained" style={{
                                                                        color: "#ffffff",
                                                                        backgroundColor: "rgba(9, 65, 115, 0.77)", marginRight: "8px", float: "right"
                                                                    }}>Save</Button>
                                                                </List>
                                                            </Box>
                                                        </ClickAwayListener>
                                                    )}
                                                </Box>
                                            </ClickAwayListener>
            )
        }

        // Customizable Area End
        return (
            // Customizable Area Start
            <Box className={classes.uomlistviewMainLayout}>
                <ToastContainer/>
                <Box className={classes.uomlistviewsideBar}>
                    {" "}
                    <Sidebar />
                </Box>
                <Box className={classes.uomlistviewcontent}>
                    {" "}
                    <AppHeader pageTitle="Configuration" />
                    <Box className={classes.mainBox} style={{backgroundColor:"#ffffff"}}>
                        <Box>
                            <Box className={classes.uomlistviewBlock}>
                                <Grid container spacing={0} >
                                    <Grid item sm={6} className='left-center-align'>
                                        <Typography className={classes.uomlistviewHeading}>UoM Categories</Typography>
                                    </Grid>
                                    <Grid item sm={6} className={classes.uomlistviewSearchbox}>
                                        {this.state.showInfoCurrentInSearchBar ? (
                                            <Box className={`${classes.saveCurrentSearchBox}`}>

                                                <Box style={{ position: "relative", marginLeft: "10px" }}>
                                                    <Chip
                                                        style={{ backgroundColor: "#006395", color: "#ffffff", padding: "0 25px" }}
                                                        label={this.chipLabelUOMcat("Save Current Search : UOM Categories")}
                                                    />
                                                    <span
                                                        className={"handleFilterIcon"}
                                                    >
                                                        <img src={whiteStar} className={classes.uomlistviewFilterImg} />
                                                    </span>
                                                    <span
                                                        data-testid={`handleRemoves`}
                                                        className="handleRemoves"
                                                        onClick={() => this.setState({ useByDefault: false}, this.saveCurrentSearch)}
                                                    >
                                                        ×
                                                    </span>
                                                </Box>
                                            </Box>
                                        ) : (
                                        <>
                                        <TextField
                                            className='search-box-width'
                                            id='user-list-search-input'
                                            variant="outlined"
                                            placeholder={`${searchPlaceholder}`}
                                            data-testid="listSearchinput"
                                            value={this.state.searchKeyword}
                                            onChange={(e:any) => this.setState({...this.state,searchKeyword:e.target.value})}
                                            disabled={Boolean(this.state.activeFilterArr.length)}
                                        />
                                        <img src={search} className={`${classes.uomlistviewImg} ${this.searchIconDisplayFnUOMcat()}`} />

                                        {renderSearchChips()}
                                        </>)}
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className={classes.uomlistviewFilterbox} >
                                <Grid container spacing={0} >
                                    <Grid item sm={6} className='left-center-align'>
                                        <Box className={classes.uomlistviewColumnbox}>
                                            <Button className='filters-left' data-testid="list-Filters" onClick={this.handleFilter}
                                            ><img src={filter} className={classes.uomlistviewFilterImg} />{configJSON.Filters}</Button>

                                            {
                                                showFilter &&
                                                <ClickAwayListener
                                                    onClickAway={this.handleFilter}
                                                    data-test-id="clickHandleFilter"
                                                >
                                                    <Box className={`filterBox ${classes.showfilterBox}`}>
                                                        <List>
                                                            <ListItem button className={`font-14 ${this.selectedListItem(0)}`} data-testid="filter-archive"
                                                        onClick={()=>this.handleActiveFilterUOMcat(0)}>
                                                                <ListItemText primary="Archive" />
                                                            </ListItem>

                                                        </List>
                                                    </Box>
                                                </ClickAwayListener>
                                            }

                                        </Box>

                                        <Box className={classes.uomlistviewColumnbox}>
                                            <Button className='filters-left' data-testid="list-Favorites" onClick={this.handleFavorites} ><img src={star} className={classes.uomlistviewFilterImg} />{configJSON.Favorites}</Button>
                                            {showFav && renderFavoritesBox()}

                                        </Box>
                                    </Grid>
                                    <Grid item sm={6} className={classes.uomlistviewCreatebox} >
                                        <Box className={classes.uomlistviewColumnbox}>
                                            <Button className={`filters-left ${classes.settingsBtn} ${this.checkGroupStatus(this.state.checkedArr.length)}`} data-testid="list-settings" onClick={this.handleSettings}><img src={settingsGrey} /></Button>

                                            {
                                                showSettingsUOMcat &&
                                                <ClickAwayListener
                                                    onClickAway={this.handleSettings}
                                                    data-test-id="clickHandleSettingsUOMcat"
                                                >
                                                    <Box className={`settingsBox ${classes.settingsBox}`}>
                                                        <style>
                                                            {`
                                                                .MuiList-padding {
                                                                    padding-top:0 !important;
                                                                    padding-bottom:0 !important;
                                                                }
                                                            `}
                                                        </style>
                                                        <List>
                                                            <ListItem className='listItem font-14' button onClick={this.handleExport}>
                                                                <ListItemText primary="Export" />
                                                            </ListItem>
                                                            <ListItem className='listItem font-14' button onClick={this.handleDelete}>
                                                                <ListItemText primary="Delete" />
                                                            </ListItem>
                                                            {!this.state.activeFilterArr.includes(0) && <ListItem className='listItem font-14' button onClick={this.handleArchive}>
                                                                <ListItemText primary="Archive" />
                                                            </ListItem>}
                                                            {this.state.activeFilterArr.includes(0) && <ListItem className='listItem font-14' button onClick={this.handleUnarchive}>
                                                                <ListItemText primary="Unarchive" />
                                                            </ListItem>}
                                                        </List>
                                                    </Box>
                                                </ClickAwayListener>

                                            }

                                        </Box>

                                        <Button className='filters-right' data-testid="Createcustomer" onClick={this.navigatetoCreateUOMcategories}>Create UoM Categories&nbsp;&nbsp;<img src={add} /></Button>
                                    </Grid>
                                </Grid>
                                <Box className={`${this.loaderBoxFnUOMcat()}`}>
                                    {
                                        this.loaderBoxContentFnUOMcat()
                                    }
                                </Box>
                                <Box className={`${this.listViewFnUOMcat()}`}>
                                    <Box className={classes.uomlistviewColumnbox} >

                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className={classes.uomlistviewCheck}><Checkbox
                                                            checked={this.state.globalCheck}
                                                    onChange={()=>this.handleGlobalCheckUOM()} 
                                                            data-test-id="globalCheckboxUOM"
                                                        /></TableCell>
                                                        <TableCell className={`${classes.uomlistviewTableheading} ${classes.tableheading2}`}>UNIT OF MEASURE CATEGORIES</TableCell>
                                                        <TableCell className={`${classes.uomlistviewTableheading} ${classes.uomTc}`}>UoM</TableCell>

                                                    </TableRow>
                                                </TableHead>
                                            {(!this.state.activeGroup) && this.state.showUOMcatdata?.map((user:any) => {
                                                    return (
                                                        <>
                                                            <TableBody>
                                                                {
                                                        this.expandTableRow(user,classes)
                                                                }
                                                            </TableBody>

                                                        </>
                                                    )
                                                })}


                                            </Table>
                                        </TableContainer>
                                    </Box>
                                    <Box className={`${classes.uomlistviewItems} ${this.checkActiveGroupStatus()}`}>
                                        <Grid container spacing={0} >
                                            <Grid item sm={8} className={classes.uomlistviewItemsgrid}>
                                                <Typography className={classes.uomlistviewItemsText}>{configJSON.itemsPage}</Typography>
                                                <FormControl id="select-form-control">

                                                    <Select
                                                        IconComponent={this.expandMore}
                                                        inputProps={{
                                                            'aria-label': 'custom select',
                                                        }}
                                                        data-testid="select_page"
                                                        id="item-select"
                                                        value={this.state.per_page}
                                                    onChange={(e:any)=>this.handlePaginationUOMcat(e.target.value)}
                                                        label="Select"
                                                        disableUnderline
                                                        className='bold-text'
                                                    >
                                                        <MenuItem className='uom-menu-item' value={9}>09</MenuItem>
                                                        <MenuItem className='uom-menu-item' value={27}>27</MenuItem>
                                                        <MenuItem className='uom-menu-item' value={54}>54</MenuItem>
                                                        <MenuItem className='uom-menu-item' value={99}>99</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item sm={4} className='end-center-align'>
                                                <Typography className={classes.uomlistviewText} >
                                                    <span className='bold-text'>
                                                {(this.state.page - 1)*this.state.per_page+1}
                                                        &nbsp;-&nbsp;
                                                {(this.state.page - 1)*this.state.per_page +
                                                            this.state.showUOMcatdata.length}
                                                    </span>
                                                    <span className={classes.bottompage}>
                                                        &nbsp;of &nbsp;
                                                        {this.state.totalCounts}
                                                    </span>

                                                </Typography>
                                            <Button data-testid="listviewBackwardicon" disabled={this.state.page==1} onClick={()=>this.loadMoreRecordsUOMcat(0,'')} className='tb-footer-btn'><img src={this.state.page==1 ? movebackward : moveBackwardArrowEnable} /></Button>
                                            <Button data-testid="listviewBackArrow" disabled={this.state.page==1} onClick={()=>this.state.page>1 && this.loadMoreRecordsUOMcat(-1,'')} className='tb-footer-btn'><img src={this.state.page==1 ? backarrow : backArrowEnable} /></Button>
                                            <Button data-testid="listviewForwardArrow" disabled={this.isThisLastPageUOMcat()} onClick={()=>this.loadMoreRecordsUOMcat(+1,'')} className='tb-footer-btn'><img src={this.isThisLastPageUOMcat()? forwardArrowDisable:forwardarrow} /></Button>
                                            <Button data-testid="listviewForwardIcon" disabled={this.isThisLastPageUOMcat()} onClick={()=>this.loadMoreRecordsUOMcat(this.state.totalPage-this.state.page,'')} id='next-end'><img src={this.isThisLastPageUOMcat()? moveForwardArrowDisable:moveforward} /></Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>

                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Dialog
                    maxWidth={"sm"}
                    data-test-id="deleteDialog"
                    open={this.state.deleteDialog}
                    onClose={this.handleDialogCancel}
                >
                    <DialogContent>
                        <Typography>
                            Are you sure you want to delete ?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={this.handleDialogCancel}
                            data-test-id="dialogCancelBtn"
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={this.OnDelete}
                            data-test-id="dialogDeleteBtn"
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>

            // Customizable Area End
        )

    }
}
// Customizable Area Start
export const userlistwebStyle: any = (theme: any) => ({
    uomlistviewMainLayout: {
        display: "flex",
    },
    mainBox:{
        border:"1px solid #e3e3e3",
        borderRadius:"5px",
        marginBottom:"10px"
    },
    uomlistviewsideBar: {
        display: "flex",
        minHeight: "100%",
        flexGrow: 1,
        transition: "all 0.3s",
    },
    uomlistviewcontent: {
        flexGrow: 9,
        padding: "0 20px",
    },

    uomlistviewBlock: {
        padding: "15px",
        borderBottom: "1px solid #e3e3e3"
    },
    uomlistviewHeading: {
        color: "#006395",
        fontSize: "30px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    uomlistviewImg: {
        position: "absolute",
        left: "15px",
        top: "19px",
        height: "20px"
    },
    uomlistviewFilterbox: {

    },
    uomlistviewFilterImg: {
        paddingRight: "8px"
    },
    uomlistviewColumnbox: {
        position: "relative"
    },
    uomlistviewColumn: {
        position: "absolute",
        right: "15px",
        top: "28px"
    },
    uomlistviewCheck: {
        width:"92px"
    },
    uomlistviewTableheading: {
        fontSize: "16px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    uomlistviewTabletext: {
        fontSize: "14px",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    uomlistviewItems: {
        padding: "5px 15px"
    },
    uomlistviewCreatebox: {
        display: "flex !important",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingRight: "10px"
    },
    uomlistviewSearchbox: {
        display: "flex !important",
        justifyContent: "flex-end",
        alignItems: "center",
        position: "relative"
    },
    uomlistviewItemsgrid: {
        display: "flex !important",
        justifyContent: "left",
        paddingTop:"8px"
    },
    uomlistviewItemsText: {
        paddingRight: "10px",
        fontSize: "18px",
        marginTop: "15px",
        color:"#47464a"
    },
    uomlistviewText: {
        marginRight: "40px"
    },
    bottompage: {
        color:"#47464a"
    },
    displayNone: {
        display:"none !important"
    },
    showfilterBox:{
        width:"120px"
    },
    uomTc:{marginLeft:"5px"},
    uomChip:{
        marginLeft:"5px",
        backgroundColor: "#fff !important",
        color: "#006395 !important",
        border: "3px solid #c3c3c3 !important"
    },
    uomChip2:{
        marginLeft:"5px",
        backgroundColor:"#f3f3f3 !important",
        color:"black !important"
    },
    tableheading2:{
        maxWidth:"80px"
    },
    settingsBtn:{
        padding: "10px 0!important",
        minWidth: "45px!important"
    },
    settingsBox:{
        top:"55px !important",
        borderRadius: "5px !important",
    },
    settingsBox1:{
        top:"55px !important",
        borderRadius: "5px !important",
        right: '-89px',
    },
    settingsBox2: {
        position: 'absolute',
        top: ' 0px !important',
        left: '100%',  
        marginLeft: '4px', 
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        backgroundColor: 'white',
        paddingBottom: '8px',
        borderRadius: '4px',
        zIndex: 3,
        width: '221px',
    },
    saveCurrentSearchBox: {
        border: "1px solid #c4c4c4",
        borderRadius: "4px",
        padding: "5px 12px",
        width: '570px',
        display: "flex",
        alignItems: "center",
        height: "40px",
    }
})
export const UomCategorieslistviewStyles = withStyles(userlistwebStyle)(UomCategorieslistview)
// Customizable Area End
