import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from 'react';
import { Box, Typography } from '@material-ui/core';
export type token = string | null;
import { getStorageData } from "../../../framework/src/Utilities";
import { validationerror } from "../../settings2/src/assets";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: token;
    responseData: any;
    isEditing: boolean;
    taxes: {
        name: string;
        vendor: boolean;
        customer: boolean;
    }
    saveClick: boolean;
    isLoading: boolean;
    editTextId: string,
    createClick: boolean,
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AddTaxAccountController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    createTaxesAPI: string = "";
    getSingleTaxAPI: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            taxes: {
                name: "",
                vendor: false,
                customer: false,
            },
            saveClick: false,
            isEditing: false,
            isLoading: false,
            editTextId: "",
            createClick: false,
            token: "",
            responseData: [],
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if (apiRequestCallId && responseJson) { this.receiveApi(apiRequestCallId, responseJson); }
            runEngine.debugLog("API Message Recived", message);
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        const token = await getStorageData("TOKEN")
        this.setState({ token: token });
        if (!token) {
            this.props.navigation.navigate("EmailAccountLoginStyles")
        }
        else {
            const id = this.props.navigation.getParam("id")
            if (id) {
                this.fetchSingleShippingMethodData(id);
                this.setState({ isEditing: true, editTextId: id, isLoading: true })
            }
        }
    }

    receiveApi = (
        apiRequestCallId: string,
        responseJson: {
            data: object[];
            meta: { total_pages: number; total_counts: number };
        }
    ) => {
        if (apiRequestCallId === this.createTaxesAPI) {
            this.handleresetShippingMethodResponse(responseJson)

        }
        if (apiRequestCallId === this.getSingleTaxAPI) {
            this.handleGetSingleShippingMethodResponse(
                responseJson.data
            );
        }
    };

    handleresetShippingMethodResponse = (responseJson: any) => {
        if (responseJson.data) {
            this.setState({
                saveClick: false,
            })
            this.props.navigation.goBack();
        } else if (Array.isArray(responseJson.errors)) {
            toast.error(responseJson.errors[0])
        } else if (responseJson.error) {
            toast.error(responseJson.error)
        } else
            return
    };

    handleGetSingleShippingMethodResponse = (
        data: any,
    ) => {
        if (data?.attributes) {
            const taxes = data.attributes;
            this.setState({
                taxes: {
                    name: taxes?.name,
                    vendor: taxes?.for_vendor,
                    customer: taxes?.for_customer,
                },
                isLoading: false,
            })
        }
    };

    navigatedToTaxesAccounting = () => {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), 'TaxesListing');
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    }

    fetchSingleShippingMethodData = async (id: string) => {
        const token = this.state.token;
        this.getSingleTaxAPI = this.apiCallHandle(
            token,
            `/account_block/vendor_taxes/${id}`,
            "GET"
        );
    };

    errorTextClass = (field: any, value: any) => {
        if (this.handleErrorMessage(field) && !value) {
            return 'error-text1';
        } else {
            return 'main-color';
        }
    }

    errorFocusClass = (field: any, value: any) => {
        if (this.handleErrorMessage(field) && !value) {
            return 'error-focus';
        } else {
            return '';
        }
    }

    buttonStyleEdit = () => {
        return (!this.state.isEditing || this.state.isLoading) ? 'display-none' : '';
    };

    buttonStyleEditSave = () => {
        return (this.state.isEditing || this.state.isLoading) ? 'display-none' : '';
    };

    mainBoxStyle = () => {
        return this.state.isLoading ? 'display-none' : '';
    }

    errorAutcompleteFocusClass = (field: any, value: any) => {
        if (this.handleErrorMessage(field) && !value.length) {
            return 'error-focus';
        } else {
            return '';
        }
    }

    handleValueChange = (e: any, name: string) => {
        this.setState({
            ...this.state, taxes:
            {
                ...this.state.taxes,
                [name]: e.target.value
            }
        })
    }

    handleIsVendorCheckbox = () => {
        this.setState({
            ...this.state,
            taxes: {
                ...this.state.taxes,
                vendor: !this.state.taxes.vendor,
            }
        });
    }

    handleIsCustomerCheckbox = () => {
        this.setState({
            ...this.state,
            taxes: {
                ...this.state.taxes,
                customer: !this.state.taxes.customer,
            }
        });
    }

    handleEdit = () => {
        this.setState({ isEditing: false })
    }

    handleErrorLabel = (flabel: any) => {
        return <Box className='flex relative' >
            <img className='error-text-img' src={validationerror} />
            <Typography className='error-text'>{flabel}</Typography>
        </Box>
    }

    navigatedToTaxesAccountingFromEditing = () => {
        this.setState({
            isEditing: false,
            taxes: {
                customer: false,
                name: "",
                vendor: false
            },
            editTextId: ''
        });
    }

    mainErrorBox = () => {
        return <Box className={`flex error-main-box ${!(this.state.saveClick && !this.checkAddCustomerValidation()) && 'display-none'}`}>
            <img src={validationerror} />
            <Typography className='error-main-text'>Some of the fields are incorrect or invalid. Please enter the valid details to continue.</Typography>
        </Box>
    }

    checkAddCustomerValidation = () => {
        if (
            this.isStringNullOrBlank(this.state.taxes.name)
        ) {
            return false;
        }
        return true;
    }

    isStringNullOrBlank(str: string) {
        return str === null || str === '' || /^\s*$/.test(str);
    }

    isArrayEmpty(arr: any[]) {
        if (arr.length === 0) return false;
        return true;
    }

    handleLabel = (flabel: any) => {
        return <>
            {
                flabel ? this.handleErrorLabel(flabel) : flabel
            }
        </>
    }

    handleErrorMessage = (type: string, show: boolean = false) => {
        if (this.state.saveClick || show) {
            let flabel: any = '';
            if (type === "name") {
                flabel = this.showErrorMessage(this.state.taxes.name.trim(), 'Please enter valid Tax name.');
            }
            return this.handleLabel(flabel)
        } else {
            return ''
        }

    }

    showErrorMessage = (state: any, msg: string) => {
        return !state && msg;
    }

    handleDiscard = () => {
        this.props.navigation.goBack();
    }

    handleSave = () => {
        const formdata: any = new FormData();
        formdata.append("vendor_tax[name]", this.state.taxes.name)
        formdata.append("vendor_tax[for_vendor]", this.state.taxes.vendor)
        formdata.append("vendor_tax[for_customer]", this.state.taxes.customer)

        this.setState({
            saveClick: true
        })

        const token = this.state.token;
        if (this.checkAddCustomerValidation()) {

            const id = this.state.editTextId;

            let endPoint = `/account_block/vendor_taxes`
            let method = "POST"
            if (id) {
                endPoint += `/${id}`
                method = "PATCH"
            }
            this.createTaxesAPI = this.apiCallHandle(
                token,
                endPoint,
                method,
                formdata
            );
        }
    };

    apiCallHandle = (token: token, endPoint: string, method: string, body?: object) => {
        const header = { token: token };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage));

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint);

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage), header);

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage), body)

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage), method
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };
    // Customizable Area End
}
