import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StorageProvider from "../../../framework/src/StorageProvider";
import { Avatar, Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import { griddownarrow, gridleftarrow } from "../../settings2/src/assets";
import { invoice, meetings, sales, star } from "../../cfcustomersalesreports3/src/assets";
import { activity } from "../../catalogue/src/assets";
export const configJSON = require("./config");

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  classes: { [key: string]: string };
  history:any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  showFilter: boolean;
  showGroup: boolean;
  showFav: boolean;
  showColumn: boolean
  showCurrentSearch: boolean;
  showAddToDashboard: boolean;
  showSettings: boolean;
  token: string | null;
  showCustomerdata:
  {
    customers: { data: [] };
    customer: { data: [] };
    key: string | undefined;
    checked: boolean | undefined;
    id: number,
    type: string,
    attributes: { first_name: string, job_position: string, company_name: string, city: string, email: string, country: string, full_phone_number: string }
  }[];
  searchValue: string;
  groupValue: string;
  groupByvalue: string;
  totalCount: { [key: string]: number },
  groupBycompany: {
    company_name: string
  }[];
  groupBysalesperson: {
    saler_name: string
  }[];
  groupByactivestatus: {
    active_status: string
  }[];
  groupBycompanyState: boolean;
  groupByState: boolean;
  groupByactivestatusState: boolean;
  filterindiviualState: boolean;
  filtercompanyState: boolean;
  selectedProducts: string[],
  groupbyProducts: string[]
  filterarchivedState: boolean;
  filterinvoiceState: boolean
  listviewState: boolean;
  columns: string[];
  page: number;
  per_page: number;
  groupbyFilterlist: {
    [x: string]: {
      id: string, first_name: string, phone_number: string, email: string, city: string, country: string, company_name: string
    }[]
  } | null,
  row: {
    from: number;
    end: number;
  };
  totalPage: number;
  totalCounts: number;
  dataLength: number;
  selectAll: boolean;
  groupBysalespersonState: boolean,
  groupBySalesState: boolean;
  elements: string[];
  activeIndex: any[];
  searchKeyword: string;
  loading: boolean;
  checkedArr: any[];
  kanbanView: boolean;
  loadMoreRecords: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class CfvendorpurchasesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apigetCustomerlistCallId: string = "";
  apigetSearchlistCallId: string = "";
  apigetGroupbylistCallId: string = "";
  apigetFilterlistCallId: string = "";
  apigetGroupbyfilterlistApiCallId: string = ""

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),

      // Customizable Area Start
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      showFilter: false,
      showColumn: false,
      showGroup: false,
      showFav: false,
      showCurrentSearch: false,
      showAddToDashboard: false,
      showSettings: false,
      showCustomerdata: [],
      totalCount: {},
      searchValue: "",
      groupValue: "",
      groupByvalue: "",
      groupBycompany: [],
      groupBysalesperson: [],
      groupByactivestatus: [],
      token: "",
      columns: ["Customer Name", "Phone", "Email", "Salesperson", "Activities", "City", "State", "Country", "VAT/TAX ID", "Next Partner Review", "Partner Level", "Activation", "Tags", "Current Membership Status"],
      groupBycompanyState: false,
      groupByState: false,
      groupByactivestatusState: false,
      listviewState: true,
      groupBySalesState: false,
      page: 1,
      per_page: 9,
      groupbyFilterlist: null,
      row: {
        from: 1,
        end: 9,
      },
      totalPage: 0,
      totalCounts: 0,
      dataLength: 0,
      selectAll: false,
      filterindiviualState: false,
      filtercompanyState: false,
      filterarchivedState: false,
      filterinvoiceState: false,
      selectedProducts: [],
      groupbyProducts: [],
      groupBysalespersonState: false,
      elements: [],
      activeIndex: [],
      searchKeyword: '',
      loading: false,
      checkedArr: [],
      kanbanView: false,
      loadMoreRecords: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start


    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.setState({ ...this.state, loading: false });
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {

        this.responseHandler(apiRequestCallId, responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getCustomerListToken()
  }
  getCustomerListToken = async () => {
    const getToken = await StorageProvider.get("TOKEN")
    this.setState({
      token: getToken
    }, () => this.getCustomerlistApi())
  }
  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (
      prevState.page !== this.state.page ||
      prevState.per_page !== this.state.per_page) {
      if (this.state.elements.length > 0) {

        let result: string = "";
        this.state.elements.forEach((item, i) => {
          if (i > 0) {
            result = result + "&" + item
          } else {
            result = item
          }
        })
        this.getFilterlistApi(result)
      } else { this.getCustomerlistApi() }

    }
    if (prevState.searchKeyword != this.state.searchKeyword) {
      this.getSearchlistApi()
    }
  }

  loaderBoxCustomer = () => {
    if (this.state.loading || this.state.showCustomerdata.length == 0) {
      return 'loaderBox'
    } else {
      return 'display-none'
    }
  }

  loaderBoxContentCustomer = () => {
    if (this.state.loading) {
      return <CircularProgress />
    } else if (this.state.showCustomerdata.length == 0) {
      return <Typography className='bold-text'>No Records Found!</Typography>
    } else {
      return <></>
    }
  }
  checkGroupFilter = () => {
    return (this.state.selectedProducts.length || this.state.groupbyProducts.length)
  }

  handlePageNumber = (pageNo: number) => {
    this.setState({
      page: pageNo + 1,
      selectAll: false,
      checkedArr: []
    });
    if (this.state.page > pageNo) {
      this.setState((previous) => ({
        row: {
          from: previous.row.from - this.state.per_page,
          end: previous.row.end - this.state.per_page,
        },
      }));
    } else {
      this.setState((previous) => ({
        row: {
          from: previous.row.from + this.state.per_page,
          end: previous.row.end + this.state.per_page,
        },
      }));
    }
  };

  cardName = (name: any) => {
    return `${name?.length > 17 ? '...' : ''}`;
  }

  hideLoadMore = () => {
    if (((this.state.showCustomerdata.length == this.state.totalCount.total_counts) || (this.loaderBoxCustomer() == 'loaderBox'))) {
      return 'display-none';
    } else {
      return '';
    }
  }

  handleLoadMore = () => {
    this.setState(
      (prevState) => ({
        page: prevState.page + 1,
        loadMoreRecords: true
      }),
      () => {
        this.getCustomerlistApi();
      }
    );
  };

  handleChangeper_page = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({
      per_page: parseInt(event.target.value as string),
      page: 1,
      selectAll: false,
      checkedArr: []
    });
  };

  navigatetoListview = () => {
    console.log('list view');
    this.setState({ ...this.state, kanbanView: false, listviewState: true, page: 1, per_page: 9, searchKeyword: '' })
  }

  navigatetoCreateCustomer = () => {
    this.props.history.push("Vendorcreation")
  }

  navigatetoGridview = () => {
    if (this.state.groupByState) {
      this.setState({ ...this.state, groupByState: false, kanbanView: true, page: 1, per_page: 20, showCustomerdata: [], selectedProducts: [], groupbyProducts: [], elements: [] })
      this.getCustomerlistApi()
    } else {
      this.setState({ ...this.state, selectedProducts: [], groupbyProducts: [], elements: [], kanbanView: true, listviewState: false, page: 1, searchKeyword: '', per_page: 9 })
    }
  }

  responseHandler = (apiRequestCallId: string, responseJson: { data: { id: number; type: string; attributes: { first_name: string; job_position: string; company_name: string; city: string; email: string; country: string; full_phone_number: string; }; }[]; meta: {}, errors: string; }) => {
    if (apiRequestCallId === this.apigetCustomerlistCallId) {
      this.apiResponse(responseJson)
    }
    if (apiRequestCallId === this.apigetSearchlistCallId) {
      this.apiResponse(responseJson)
    }
    if (apiRequestCallId === this.apigetFilterlistCallId) {
      this.apiResponse(responseJson)
    }
  }
  apiResponse = (responseJson:
    {
      data: {
        id: number;
        type: string;
        attributes: {
          first_name: string;
          job_position: string;
          company_name: string;
          city: string;
          email: string;
          country: string;
          full_phone_number: string;
        };
      }[],
      meta: {},
      errors: string
    }
  ) => {
    if (responseJson.data) {
      (this.state.loadMoreRecords && this.state.showCustomerdata?.length > 0) ? this.setState({ loadMoreRecords: false, showCustomerdata: [...this.state.showCustomerdata, ...responseJson.data].map(item => ({ ...item, checked: false })) as S['showCustomerdata'] }) :
        this.setState({
          showCustomerdata: responseJson.data.map(item => ({ ...item, checked: false })) as S['showCustomerdata'],
          totalCount: responseJson.meta
        })
    } else if (responseJson.errors) {
      toast.error(configJSON.invalidToken)
    }
  }
  handleFilter = () => {
    this.setState({ showFilter: !this.state.showFilter });
  };
  handleGroup = () => {
    this.setState({
      showGroup: !this.state.showGroup
    });
  };
  handleFavorites = () => {
    this.setState({ showFav: !this.state.showFav });
  };
  handleCurrentSearch = () => {
    this.setState({ showCurrentSearch: !this.state.showCurrentSearch });
  };
  handleAddToDashboard = () => {
    this.setState({ showAddToDashboard: !this.state.showAddToDashboard });
  };
  handleSettings = () => {
    this.setState({ showSettings: !this.state.showSettings });
  };
  handleColumn = () => {
    this.setState({ showColumn: !this.state.showColumn });
  };


  isCurrentPageOne(): boolean {
    return this.state.page === 1;
  }

  isCurrentPageLast(): boolean {
    return this.state.page === this.state.totalCount.total_pages;
  }
  filterLabel = (label: string) => {
    let flabel = '';
    switch (label) {
      case 'individual': flabel = 'Individual'; break;
      case 'companies': flabel = 'Companies'; break;
      case 'archived': flabel = 'Archived'; break;
      case 'saler_name': flabel = 'Sales Person'; break;
      case 'company_name': flabel = 'Company Name'; break;
      case 'activated': flabel = 'Active Status'; break;
      default:
        break;

    }
    return flabel;
  }
  handleProductSelect = (key: string, value: string) => {

    this.setState({
      ...this.state,
      listviewState: this.state.groupbyProducts.length ? false : true,
      kanbanView: false,
      page: 1,
      per_page: 9,
      loading: true,
      groupByState: this.state.groupbyProducts.length ? true : false,
      searchKeyword: '',
      selectAll: false,
      checkedArr: [],
      elements: value == "no" ? this.state.elements.filter((i) => i != key + "=true") : this.state.elements
    })
    const exists = this.state.selectedProducts.find(_ => _.includes(key))
    const existval = exists ? false : true
    this.setState({
      selectedProducts: exists ? this.state.selectedProducts.filter(_ => _ !== key) : [...this.state.selectedProducts, key],
    }, () => {
      value !== "no" ? this.filterStatestatus(key, existval) : this.addElement(key, value)
    });
  };

  dropdownImg = (index: number, classes: any) => {
    return this.state.activeIndex.includes(index) ? <img className={classes.accordionimg} src={griddownarrow} alt="dropdown" /> : <img className={classes.accordionImg} src={gridleftarrow} alt="dropdown" />
  }

  handleProductRemove = (removedOption: string) => {
    const value = "yes"
    this.setState((prevState) => ({
      selectedProducts: prevState.selectedProducts.filter((option) => option !== removedOption),
    }));
    this.addElement(removedOption, value)
  };
  handlegroupByProductSelect = (key: string, value: string) => {
    const elem = this.state.elements.filter((i) => !(i == "saler_name=yes" || i == "company_name=yes" || i == "activated=yes"))
    this.setState({
      page: 1,
      loading: true,
      searchKeyword: '',
      selectAll: false,
      checkedArr: [],
      groupbyProducts: value == "no" ? [] : [key],
      showGroup: false,
      groupByState: value == "no" ? false : true,
      listviewState: value == "no" ? true : false,
      kanbanView: false,
      elements: value == "no" ? this.state.elements.filter((i) => i != key + "=yes") : elem
    }, () => {
      this.addElement(key, value)
    });
  };

  handlegroupbyProductRemove = (removedOption: string) => {
    const value = "yes"
    this.setState((prevState) => ({
      groupbyProducts: prevState.groupbyProducts.filter((option) => option !== removedOption),
    }));
    this.addElement(removedOption, value)
  };

  filterOnClickFunction = (name: string) => {
    if (!this.state.selectedProducts.includes(name)) {
      this.handleProductSelect(name, "yes")
    }
  }

  listItemClass = (name: string, classes: any) => {
    return this.state.selectedProducts.includes(name) ? classes.optionsTextactive : classes.optionsText
  }

  grouplistItemClass = (name: string, classes: any) => {
    return this.state.groupbyProducts.includes(name) ? classes.optionsTextactive : classes.optionsText
  }

  searchInputId = () => {
    return this.checkGroupFilter() ? 'search-input-customer' : 'search-input';
  }

  searchInputPlaceholder = () => {
    return this.checkGroupFilter() ? '' : 'Search here...';
  }

  searchInputReadonly = () => {
    return this.checkGroupFilter() ? true : false
  }

  handletoggle = (id: number) => {
    if (this.state.activeIndex.includes(id)) {
      this.setState({ ...this.state, activeIndex: this.state.activeIndex.filter((i) => i !== id) })
    } else {
      this.setState({ ...this.state, activeIndex: [...this.state.activeIndex, id] })
    }

  };
  groupName = (data: any) => {
    return `${data.key == "true" ? 'Yes' : data.key}`
  }
  toggleGroupSelectAll = () => {
    const newSelectAll = !this.state.selectAll;
    let arr: any[] = [];
    if (!this.state.selectAll) {
      this.state.showCustomerdata.forEach(checkbox =>
        checkbox.customer.data.forEach((value: any) => arr.push(value.id))

      );
    }

    this.setState({ selectAll: newSelectAll, checkedArr: arr });
  };
  toggleSelectAll = () => {
    const newSelectAll = !this.state.selectAll;
    let arr: any[] = [];
    if (!this.state.selectAll) {
      this.state.showCustomerdata.forEach(checkbox =>
        arr.push(checkbox.id)

      );
    }
    this.setState({ selectAll: newSelectAll, checkedArr: arr });
  };
  getGroupByCustomerLength = () => {
    let arr: any[] = [];
    this.state.showCustomerdata.forEach(checkbox =>
      checkbox.customer.data.forEach((value: any) => arr.push(value.id))

    );
    return arr.length
  }
  getCustomerLength = () => {
    let arr: any[] = [];
    this.state.showCustomerdata.forEach(checkbox =>
      arr.push(checkbox.id)

    );
    return arr.length
  }
  handleGroupCheckboxChange = (id: number) => {
    if (this.state.checkedArr.includes(id)) {
      this.setState({ ...this.state, selectAll: false, checkedArr: this.state.checkedArr.filter((i) => i !== id) })
    } else {
      this.setState({ ...this.state, selectAll: (this.getGroupByCustomerLength() == (this.state.checkedArr.length + 1)) ? true : false, checkedArr: [...this.state.checkedArr, id] })
    }

  }
  handleCheckboxChange = (id: number) => {
    if (this.state.checkedArr.includes(id)) {
      this.setState({ ...this.state, selectAll: false, checkedArr: this.state.checkedArr.filter((i) => i !== id) })
    } else {
      this.setState({ ...this.state, selectAll: (this.getCustomerLength() == (this.state.checkedArr.length + 1)) ? true : false, checkedArr: [...this.state.checkedArr, id] })
    }
  }
  filterStatestatus = (key: string, value: any) => {

    switch (key) {
      case "individual":
        this.setState({
          filterindiviualState: value,
          showFilter: false,
        })
        break;
      case "companies":
        this.setState({
          filtercompanyState: value,
          showFilter: false,
        })
        break;
      case "archived":
        this.setState({
          filterarchivedState: value,
          showFilter: false,
        })
        break;

      default:
        this.setState({
          listviewState: true
        })
    }
    this.addElement(key, value)

  }
  addElement = (key: string, value: string) => {
    let elements = value == "no" ? [...this.state.elements] : [...this.state.elements, key + "=" + value]

    this.setState((prevState) => ({
      elements: value == "no" ? [...prevState.elements] : [...prevState.elements, key + "=" + value],
    }));
    let result: string = "";
    elements.forEach((item, i) => {
      if (i > 0) {
        result = result + "&" + item
      } else {
        result = item
      }
    })
    this.getFilterlistApi(result)
  }

  removeElement = (keyToRemove: string) => {
    let elements = [...this.state.elements]
    this.setState((prevState) => ({
      elements: prevState.elements.filter(item => {
        const [existingKey] = item.split('=');
        return existingKey !== keyToRemove;
      }),
    }));

    let result: string = "";
    elements.forEach((item, i) => {
      if (i > 0) {
        result = result + "&" + item
      } else {
        result = item
      }
    })
    this.getFilterlistApi(result)
  }

  getCustomerlistApi = async () => {
    this.setState({
      loading: true,
    })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    this.apigetCustomerlistCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCustomerlistAPiEndPoint + `usertype=vendor&per_page=${this.state.per_page}&page=${this.state.page}&search=${encodeURIComponent(this.state.searchKeyword)}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleSearchApi = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ ...this.state, searchKeyword: e.target.value })
  }
  getSearchlistApi = async () => {
    this.setState({ ...this.state, page: 1, per_page: 9, loading: true, listviewState: true, kanbanView: false })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };

    this.apigetSearchlistCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSearchlistAPiEndPoint + `${this.state.searchKeyword}&usertype=vendor&${"per_page"}=${this.state.per_page}&page=1`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getFilterlistApi = async (key: string) => {
    let groupby = '';
    if (!this.state.groupByState) {
      groupby = `&${"per_page"}=${this.state.per_page}&${"page"}=${this.state.page}`
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token
    };
    this.apigetFilterlistCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFilterlistAPiEndPoint + `usertype=vendor&${key}${groupby}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  kanbanData = (value: any, classes: any) => {
    console.log('kbdata', value)
    return <>
      <Grid item lg={4} className={classes.gridViewBoxstyle1} >
        <Box className={classes.gridviewFilterbox1}  >
          <Grid container spacing={0} className={classes.gridviewbox}  >

            <Grid item lg={4} className={classes.gridviewlistimg1} >
              <Avatar className={`${classes.gridAvatar1} customer-grid-avatar`} src={value.attributes.image} alt="img" variant="square" />
            </Grid>
            <Grid item lg={8} className={classes.gridviewlistimg2}  >
              <img src={star} className={classes.gridListimg1} />
              <Typography className={classes.gridListtext1} >{value.attributes.first_name?.substring(0, 17) + this.cardName(value.attributes.first_name)}</Typography>
              <Typography className={classes.gridListsubtext1} >{value.attributes.company_name}</Typography>
              <Typography className={classes.gridListsubtext2} >{value.attributes.job_position}</Typography>
              <Typography className={classes.gridListinputtext1} >{value.attributes?.city.split('@')[0]},{value.attributes.country}</Typography>
              <Typography className={classes.gridListinputtext1} >{value.attributes.email}</Typography>
            </Grid>
          </Grid>
          <ul className='grid-item-ul customer-list-grid-item-ul'>
            <li>{configJSON.services}</li>
            <li>{configJSON.appliances}</li>
          </ul>
        </Box>
        <Box className={classes.gridviewMainblock1} >
          <Grid container className={classes.gridviewContainer1} >
            <Grid item lg={3}  >
              <Box className={classes.gridViewtext1} >
                <img className={classes.gridViewtext1img} src={sales} /> <Typography className={classes.gridviewSalestext1} >{configJSON.pageFour}</Typography>
              </Box>
            </Grid>
            <Grid item lg={3}  >
              <Box className={classes.gridViewtext1} >
                <img className={classes.gridViewtext1img} src={invoice} /> <Typography className={classes.gridviewSalestext1} >{configJSON.pageFour}</Typography>
              </Box>
            </Grid>
            <Grid item lg={3}  >
              <Box className={classes.gridViewtext1} >
                <img className={classes.gridViewtext1img} src={activity} /> <Typography className={classes.gridviewSalestext1} >{configJSON.pageFour}</Typography>
              </Box>
            </Grid>
            <Grid item lg={3}  >
              <Box className='center-align' >
                <img className={classes.gridViewtext1img} src={meetings} /> <Typography className={classes.gridviewSalestext1} >{configJSON.pageFour}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  }
  // Customizable Area End
}
