// Customizable Area Start
import React from 'react';
import { Box, Button, Grid, Typography, createTheme, withStyles } from '@material-ui/core';
import ExportFavouriteController, { Props } from './ExportFavouriteController'
import CircularProgress from "@material-ui/core/CircularProgress";

export const theme = createTheme({
    palette: {
        primary: {
            main: "#ffcc00",
            contrastText: "#fff",
        },
    },
});
import { ToastContainer } from 'react-toastify'
import SidebarWeb from '../../../components/src/Sidebar.web';
import { AppHeader } from '../../../components/src/AppHeader.web';
import { downloadIcon, importillustration } from '../../settings2/src/assets';
export const configJSON = require("./config");
// Customizable Area End

export default class ExportProductFavouriteView extends ExportFavouriteController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const { classes }: any = this.props;

        // Customizable Area End
        return (
            // Customizable Area Start
            <Box className={classes.exportviewMainLayout}>
                <ToastContainer/>
                <Box className={classes.exportlistviewsideBar}>
                    {" "}
                    <SidebarWeb />
                </Box>
                <Box className={classes.exportlistviewcontent}>
                    {" "}
                    <AppHeader pageTitle="Configuration" />
                    <Box className={classes.mainBox} style={{backgroundColor:"#ffffff"}}>
                          <Box className={classes.exportlistviewBlock}>
                              <Grid container spacing={0} >
                                  <Grid item sm={6} className='left-center-align'>
                                      <Typography className={classes.exportListviewHeading} onClick={this.handleCancel}>{configJSON.exportHeadingProduct}</Typography>
                                      <Typography className={classes.exportListviewHeading2}>{configJSON.exportSubheadingProduct}</Typography>
                                  </Grid>
                              </Grid>
                            </Box>
                          <Box className={classes.exportlistviewUploadBox} >
                            <Grid container spacing={0} >
                            <Box className={classes.uploadSection}>
                                <img
                                  src={importillustration}
                                  alt=""
                                  className={classes.uploadImage}
                                />
                              <Typography className={classes.instructions}>
                               {configJSON.exportProductTitle}
                              </Typography>
                              <Typography className={classes.instructionSubTitle}>
                               {configJSON.exportProductSubTitle}
                              </Typography>
                              <Typography className={classes.instructionHelp}>
                               {configJSON.exportProductHelpTxt}
                              </Typography>
                              <Grid item className={classes.importGrid}>
                              <Button variant="outlined"
                               className={classes.templateButton} 
                               disabled={this.state.loadingExport}
                               startIcon={this.state.loadingExport && <CircularProgress size={20} />} onClick={this.handleDownload}>
                                <img src={downloadIcon} className={classes.uomlistviewFilterImg} />
                              {configJSON.exportProductDownloadBtnTxt}
                              </Button>
                              </Grid>
                              <Button className={classes.discardBtn} variant="outlined" onClick={this.handleCancel}>
                               {configJSON.exportProductCancelBtnTxt}
                                </Button>
                                <Button 
                                  variant="contained" 
                                  color="primary" 
                                  className={classes.uploadButton}
                                  component="label"
                                  disabled={this.state.loadingUpload}
                                  startIcon={this.state.loadingUpload && <CircularProgress size={20} />}
                                >
                                    {configJSON.exportProductUploadBtnTxt}
                                  <input
                                    type="file"
                                    hidden
                                    onChange={this.handleFileUpload}
                                  />
                                </Button>
                              <Typography className={classes.uoMImportFaqButton}>
                              {configJSON.exportProductImportFaqTxt}
                              </Typography>
                            </Box>
                            </Grid>
                          </Box>
                      </Box>
                </Box>               
            </Box>
            // Customizable Area End
        )
    }
}
// Customizable Area Start
export const userlistwebStyle: any = (theme: any) => ({
    exportviewMainLayout: {
        display: "flex",
    },
    mainBox:{
        border:"1px solid #e3e3e3",
        borderRadius:"5px",
        marginBottom:"10px"
    },
    exportlistviewsideBar: {
        display: "flex",
        minHeight: "100%",
        flexGrow: 1,
        transition: "all 0.3s",
    },
    exportlistviewcontent: {
        flexGrow: 9,
        padding: "0 20px",
    },

    exportlistviewBlock: {
        padding: "15px",
        borderBottom: "1px solid #e3e3e3"
    },
    exportListviewHeading: {
        color: "#006395",
        fontSize: "20px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        cursor: "pointer"
    },
    exportListviewHeading2: {
      color: "#47464a",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
  },
    exportlistviewUploadBox: {
      width:"100%",
      padding:"50px 0"
    },
    uploadSection: {
      textAlign: "center",
      width:"100%"
    },
    discardBtn: {
      marginRight: '15px',
      backgroundColor: 'white',
      border: '1px solid #e6e6e6',
    },
    instructions: {
      fontSize: "16px",
      marginBottom: theme.spacing(1),
    },
    instructionSubTitle: {
      fontSize: "14px",
      color: "#47464a",
      marginBottom: theme.spacing(1),
    },
    instructionHelp:{
      fontSize: "12px",
      color: "#47464a",
      marginBottom: theme.spacing(1),
    },
    templateButton: {
      marginRight: theme.spacing(2),
      borderColor: "#006395",
      color: "#006395",
    },
    uploadButton: {
      backgroundColor: '#026494',
      color: '#ffffff',
    },
    uploadImage: {
      width: "150px",
      height:"150px",
      borderRadius: "50%",
      border:"1px solid #e3e3e3",
      marginBottom: theme.spacing(2),
    },
    uomlistviewFilterImg: {
      display:"flex",
      textAllign:"center",
      color: "#006395",
      paddingRight: "8px"
  },
    uoMImportFaqButton:{
      padding:"12px",
      color: "#006395",
      fontSize: "12px",
      marginBottom: theme.spacing(2),
      textDecoration: "underline",
      cursor: "pointer",
    },
    importGrid:{
      marginBottom: theme.spacing(2),
    },
})
export const ExportProductFavouriteViewStyles = withStyles(userlistwebStyle)(ExportProductFavouriteView)
// Customizable Area End
