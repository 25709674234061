// Customizable Area Start
import React from 'react';
import { Box, Button, Card, CardContent, Checkbox, Chip, CircularProgress, ClickAwayListener, Dialog, DialogActions, DialogContent, FormControl, Grid, List, ListItem, ListItemText, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import { ToastContainer } from 'react-toastify'
import TaxesAccountListingController, { Props } from './TaxesAccountListingController.web';
import SidebarWeb from '../../../components/src/Sidebar.web';
import { AppHeader } from '../../../components/src/AppHeader.web';
import { add, backarrow, canban, filter, filtericon, forwardarrow, listView, movebackward, moveforward, search } from './assets';
import { backArrowEnable, forwardArrowDisable, moveBackwardArrowEnable, moveForwardArrowDisable } from '../../settings2/src/assets';
import { settingsGrey } from '../../cfbankcashrecitevoucher4/src/assets';
import { groupWhite } from '../../../components/src/assets';
import { createTheme, styled, withStyles } from '@material-ui/core/styles';

export const configJSON = require("../src/config");
export const theme = createTheme({
    palette: {
        primary: {
            main: "#ffcc00",
            contrastText: "#fff",
        },
    },
});

// Customizable Area End

const ButtonComponent = styled("button")({
    color: "black",
    backgroundColor: "#F3F3F3",
    padding: "8px 15px",
    borderRadius: "4px",
    fontSize: "12px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    border: "none",
    height: "100%",
    marginX: "10px",
});

export default class TaxesAccountListing extends TaxesAccountListingController {
    constructor(props: Props) {
        super(props);
    }

    renderLoadMore = () => {
        return (<>
            {this.state.loading && <Box data-test-id="loadmore">
                <TableRow>
                    <TableCell colSpan={10}>
                        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "20vh", width: "100%" }}>
                            <CircularProgress />
                        </Box>
                    </TableCell>
                </TableRow>
            </Box>}
            <Box data-test-id="loadmore">
                <Button variant="contained" data-test-id="loadMore-btn" onClick={this.handleLoadMore}>
                    Load More Records
                </Button>
            </Box>
        </>
        )
    }

    renderKanbanView = () => {
        return <Box>
            <Grid container spacing={2} style={{ padding: "0 15px 10px 15px" }}>
                {this.state.showdata.map((tablerow: any) => (
                    <Grid onClick={() => { }} item xs={4} key={tablerow.id}>
                        <Card>
                            <CardContent style={{ padding: "10px", minHeight: "80px" }}>
                                <Grid container alignItems="center" justifyContent="space-between">
                                    <Typography variant="body1">{tablerow.attributes.name}</Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                            gridGap: "10px",
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            style={{
                                                backgroundColor: "rgb(0, 99, 148)",
                                                color: "#ffffff",
                                                padding: "5px 10px",
                                                borderRadius: "15px",
                                                fontWeight: 500,
                                                fontSize: "0.875rem",
                                                display: `${this.renderKanbanViewType(tablerow.attributes) === "Customer" ? "" : "none"}`
                                            }}
                                        >
                                            {this.renderKanbanViewType(tablerow.attributes)}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            style={{
                                                backgroundColor: "rgb(0, 99, 148)",
                                                color: "#ffffff",
                                                padding: "5px 10px",
                                                borderRadius: "15px",
                                                fontWeight: 500,
                                                fontSize: "0.875rem",
                                                display: `${this.renderKanbanViewTypeVendor(tablerow.attributes) === "Vendor" ? "" : "none"}`
                                            }}
                                        >
                                            {this.renderKanbanViewTypeVendor(tablerow.attributes)}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: "10px" }}>
                {((this.state.totalCounts > this.state.per_page) && this.state.showdata.length) ? this.renderLoadMore() : null}
            </Box>
        </Box>
    }

    render() {
        // Customizable Area Start
        const { showFilter, showSettings } = this.state;
        const { classes }: any = this.props;

        // Customizable Area End
        return (
            // Customizable Area Start
            <Box className={classes.listviewMainLayout}>
                <ToastContainer />
                <Box className={classes.listviewsideBar}>
                    {" "}
                    <SidebarWeb />
                </Box>
                <Box className={classes.listviewcontent}>
                    {" "}
                    <AppHeader pageTitle="Configuration" />
                    <Box className={classes.mainBox} style={{ backgroundColor: "#ffffff" }}>
                        <Box>
                            <Box className={classes.listviewBlock}>
                                <Grid container spacing={0} >
                                    <Grid item sm={6} className='left-center-align'>
                                        <Typography className={classes.listviewHeading}>Taxes</Typography>
                                    </Grid>
                                    <Grid item sm={6} className={classes.listviewSearchbox}>
                                        <TextField
                                            className='search-box-width'
                                            id='user-list-search-input'
                                            variant="outlined"
                                            placeholder={`${this.searchPlaceholderFn()}`}
                                            data-testid="listSearchinput"
                                            value={this.state.searchKeyword}
                                            onChange={(e: any) => !this.state.searchArray.length && this.setState({ ...this.state, searchKeyword: e.target.value })}
                                        />
                                        <img src={search} className={`${classes.listviewImg} ${this.searchIconDisplayFn()}`} />
                                        <Box className={`${this.checkGroupStatus(this.state.searchArray.length)}`} sx={{ position: "absolute", left: "0px", top: "12px" }}>
                                            <Box sx={{ display: "flex" }}>
                                                {
                                                    this.state.searchArray.map((data: any, indexx: number) => {
                                                        return <Box style={{ position: "relative", marginLeft: "10px" }}>
                                                            <Chip style={{ backgroundColor: "#006395", color: "#ffffff", padding: "0 25px" }} label={this.chipLabel(data.value)} />
                                                            <span className={data.type == 'filter' ? 'handleFilterIcon' : 'handleGroupIcon'} ><img src={data.type == 'filter' ? filtericon : groupWhite} /></span>
                                                            <span data-testid={`handleRemoves${indexx}`} className='handleRemoves' onClick={() => data.type == 'filter' ? this.removeActiveFilter(data.value) : this.handleActiveGroup('')} >×</span>
                                                        </Box>
                                                    })
                                                }
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className={classes.listviewFilterbox} >
                                <Grid container spacing={0} >
                                    <Grid item sm={6} className='left-center-align'>
                                        <Box className={classes.listviewColumnbox1}>
                                            <Button className='filters-left' data-testid="list-Filters" onClick={this.handleFilter}
                                            ><img src={filter} className={classes.listviewFilterImg} />{configJSON.Filters}</Button>
                                            {
                                                showFilter &&
                                                <ClickAwayListener
                                                    onClickAway={this.handleFilter}
                                                >
                                                    <Box className={`filterBox ${classes.showfilterBox}`}>
                                                        <List>
                                                            <ListItem button className={`font-14 ${this.selectedListItem(0)}`} data-testid="filter-archive"
                                                                onClick={() => this.handleActiveFilter(0)}>
                                                                <ListItemText primary="Archive" />
                                                            </ListItem>

                                                        </List>
                                                    </Box>
                                                </ClickAwayListener>
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item sm={6} className={classes.tableOption} >
                                        <Button className={`filters-left ${classes.settingsBtn} ${this.checkGroupStatus(this.state.checkedArr.length)}`} data-testid="list-settings" onClick={this.handleSettings}><img src={settingsGrey} /></Button>
                                        {
                                            showSettings &&
                                            <ClickAwayListener
                                                onClickAway={this.handleSettings}
                                                data-test-id="clickHandleSettingsUOMcat"
                                            >
                                                <Box className={`settingsBox ${classes.settingsBox}`}>
                                                    <List>
                                                        <ListItem className='listItem font-14' button onClick={this.handleExport}>
                                                            <ListItemText primary="Export" />
                                                        </ListItem>
                                                        <ListItem className='listItem font-14' button onClick={this.handleDelete}>
                                                            <ListItemText primary="Delete" />
                                                        </ListItem>
                                                        {!this.checkValue() && <ListItem className='listItem font-14' button onClick={this.handleArchive}>
                                                            <ListItemText primary="Archive" />
                                                        </ListItem>}
                                                        {this.checkValue() && <ListItem className='listItem font-14' button onClick={this.handleUnarchive}>
                                                            <ListItemText primary="Unarchive" />
                                                        </ListItem>}
                                                    </List>
                                                </Box>
                                            </ClickAwayListener>
                                        }
                                        <ButtonComponent
                                            data-test-id="listView"
                                            color="primary"
                                            className={
                                                this.state.isViewTypeChanged ? classes.active : {}
                                            }
                                            onClick={() => {
                                                this.setState({ isViewTypeChanged: true, per_page: 9 });
                                            }}
                                        >
                                            <img
                                                src={listView}
                                                alt="listview"
                                                style={
                                                    this.state.isViewTypeChanged
                                                        ? {}
                                                        : { filter: "invert(1)" }
                                                }
                                            />
                                        </ButtonComponent>
                                        <ButtonComponent
                                            data-test-id="canbanView"
                                            className={
                                                this.state.isViewTypeChanged ? {} : classes.active
                                            }
                                            onClick={() => {
                                                this.setState({ isViewTypeChanged: false, per_page: 18, loading: true, checkedArr: [], });
                                            }}
                                        >
                                            <img
                                                src={canban}
                                                alt="canbanview"
                                                style={
                                                    this.state.isViewTypeChanged
                                                        ? {}
                                                        : { filter: "invert(1)" }
                                                }
                                            />
                                        </ButtonComponent>
                                        <Button className='filters-right' data-testid="Createcustomer" onClick={this.navigatetoTaxes}>Create Taxes&nbsp;&nbsp;<img src={add} /></Button>
                                    </Grid>
                                </Grid>
                                <Box className={`${this.loaderBoxFn()}`}>
                                    {
                                        this.loaderBoxContentFn()
                                    }
                                </Box>
                                <Box className={`${this.kanbanStyle()}`}>
                                    {this.renderKanbanView()}
                                </Box>
                                <Box className={`${this.listViewFn()}`}>
                                    <Box className={classes.listviewColumnbox} >
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className={classes.listviewCheck}><Checkbox
                                                            checked={this.state.globalCheck}
                                                            onChange={() => this.handleGlobalCheckUOM()}
                                                            data-test-id="globalCheckboxUOM"
                                                        /></TableCell>
                                                        <TableCell className={`${classes.listviewTableheading} ${classes.tableheading2}`}>TAX NAME</TableCell>
                                                        <TableCell className={`${classes.listviewTableheading} ${classes.tableheading2}`}>CUSTOMER</TableCell>
                                                        <TableCell className={`${classes.listviewTableheading} ${classes.tableheading2}`}>VENDOR</TableCell>
                                                        <TableCell className={`${classes.listviewTableheading} ${classes.uomTc}`}>ACTION</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {(!this.state.activeGroup) && this.state.showdata?.map((user: any) => {
                                                    return (
                                                        <>
                                                            <TableBody>
                                                                {
                                                                    this.expandTableRow(user, classes)
                                                                }
                                                            </TableBody>
                                                        </>
                                                    )
                                                })}
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                    <Box className={`${classes.listviewItems} ${this.checkActiveGroupStatus()}`}>
                                        <Grid container spacing={0} >
                                            <Grid item sm={8} className={classes.listviewItemsgrid}>
                                                <Typography className={classes.listviewItemsText}>{configJSON.itemsPage}</Typography>
                                                <FormControl id="select-form-control">
                                                    <Select
                                                        IconComponent={this.expandMore}
                                                        inputProps={{
                                                            'aria-label': 'custom select',
                                                        }}
                                                        data-testid="select_page"
                                                        id="item-select"
                                                        value={this.state.per_page}
                                                        onChange={(e: any) => this.handlePagination(e.target.value)}
                                                        label="Select"
                                                        disableUnderline
                                                        className='bold-text'
                                                    >
                                                        <MenuItem className='menu-item' value={9}>09</MenuItem>
                                                        <MenuItem className='menu-item' value={27}>27</MenuItem>
                                                        <MenuItem className='menu-item' value={54}>54</MenuItem>
                                                        <MenuItem className='menu-item' value={99}>99</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item sm={4} className='end-center-align'>
                                                <Typography className={classes.listviewText} >
                                                    <span className='bold-text'>
                                                        {(this.state.page - 1) * this.state.per_page + 1}
                                                        &nbsp;-&nbsp;
                                                        {(this.state.page - 1) * this.state.per_page +
                                                            this.state.showdata.length}
                                                    </span>
                                                    <span className={classes.bottompage}>
                                                        &nbsp;of &nbsp;
                                                        {this.state.totalCounts}
                                                    </span>
                                                </Typography>
                                                <Button data-testid="listviewBackwardicon" disabled={this.state.page == 1} onClick={() => this.loadMoreRecords(0, '')} className='tb-footer-btn'><img src={this.state.page == 1 ? movebackward : moveBackwardArrowEnable} /></Button>
                                                <Button data-testid="listviewBackArrow" disabled={this.state.page == 1} onClick={() => this.state.page > 1 && this.loadMoreRecords(-1, '')} className='tb-footer-btn'><img src={this.state.page == 1 ? backarrow : backArrowEnable} /></Button>
                                                <Button data-testid="listviewForwardArrow" disabled={this.isThisLastPage()} onClick={() => this.loadMoreRecords(+1, '')} className='tb-footer-btn'><img src={this.isThisLastPage() ? forwardArrowDisable : forwardarrow} /></Button>
                                                <Button data-testid="listviewForwardIcon" disabled={this.isThisLastPage()} onClick={() => this.loadMoreRecords(this.state.totalPage - this.state.page, '')} id='next-end'><img src={this.isThisLastPage() ? moveForwardArrowDisable : moveforward} /></Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>

                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Dialog
                    maxWidth={"sm"}
                    data-test-id="deleteDialog"
                    open={this.state.deleteDialog}
                    onClose={this.handleDialogCancel}
                >
                    <DialogContent>
                        <Typography>
                            Are you sure you want to delete ?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={this.handleDialogCancel}
                            data-test-id="dialogCancelBtn"
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={this.OnDelete}
                            data-test-id="dialogDeleteBtn"
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    maxWidth={"sm"}
                    data-test-id="deleteDialog1"
                    open={this.state.deleteDialogTable}
                    onClose={this.handleDialogCancelTable}
                >
                    <DialogContent>
                        <Typography>
                            Are you sure you want to delete ?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={this.handleDialogCancelTable}
                            data-test-id="dialogCancelBtn"
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={this.OnDeleteTableData}
                            data-test-id="dialogDeleteBtn"
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
            // Customizable Area End
        )
    }
}
// Customizable Area Start
export const userlistwebStyle: any = (theme: any) => ({
    listviewMainLayout: {
        display: "flex",
    },
    mainBox: {
        border: "1px solid #e3e3e3",
        borderRadius: "5px",
        marginBottom: "10px"
    },
    listviewsideBar: {
        display: "flex",
        minHeight: "100%",
        flexGrow: 1,
        transition: "all 0.3s",
    },
    listviewcontent: {
        flexGrow: 9,
        padding: "0 20px",
    },

    listviewBlock: {
        padding: "15px",
        borderBottom: "1px solid #e3e3e3"
    },
    listviewHeading: {
        color: "#006395",
        fontSize: "30px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    listviewImg: {
        position: "absolute",
        left: "15px",
        top: "19px",
        height: "20px"
    },
    listviewFilterbox: {

    },
    listviewFilterImg: {
        paddingRight: "8px"
    },
    listviewColumnbox: {
        position: "relative"
    },
    listviewColumnbox1: {
        position: "relative"
    },
    listviewColumn: {
        position: "absolute",
        right: "15px",
        top: "28px"
    },
    listviewCheck: {
        width: "92px"
    },
    listviewTableheading: {
        fontSize: "16px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    listviewTabletext: {
        fontSize: "14px",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    listviewItems: {
        padding: "5px 15px"
    },
    listviewCreatebox: {
    },
    listviewSearchbox: {
        display: "flex !important",
        justifyContent: "flex-end",
        alignItems: "center",
        position: "relative"
    },
    listviewItemsgrid: {
        display: "flex !important",
        justifyContent: "left",
        paddingTop: "8px"
    },
    listviewItemsText: {
        paddingRight: "10px",
        fontSize: "18px",
        marginTop: "15px",
        color: "#47464a"
    },
    listviewText: {
        marginRight: "40px"
    },
    bottompage: {
        color: "#47464a"
    },
    displayNone: {
        display: "none !important"
    },
    showfilterBox: {
        width: "120px"
    },
    uomTc: { marginLeft: "5px" },
    uomChip: {
        marginLeft: "5px",
        backgroundColor: "#fff !important",
        color: "#006395 !important",
        border: "3px solid #c3c3c3 !important"
    },
    uomChip2: {
        marginLeft: "5px",
        backgroundColor: "#f3f3f3 !important",
        color: "black !important"
    },
    tableheading2: {
        maxWidth: "80px"
    },
    settingsBtn: {
        padding: "10px 0!important",
        minWidth: "45px!important"
    },
    active: {
        backgroundColor: "#006395",
        color: "#fff",
    },
    tableOption: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        margin: "5px 0",
        height: "100%",
        justifyContent: "end",
    },
    settingsBox: {
        position: "absolute",
        right: "300px",
        zIndex: 10,
        borderRadius: "5px !important",
        backgroundColor: "#fff",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        "& .MuiList-padding": {
            paddingBottom: "0 !important",
            paddingTop: "0 !important",
        },
        minWidth: "100px",
        top: "247px !important"
    },
    EmptyData: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh',
    },
})
export const TaxesAccountListingStyles = withStyles(userlistwebStyle)(TaxesAccountListing)
// Customizable Area End
