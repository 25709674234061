Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.searchBtnTxt = "Search";
exports.approveBtnTxt = "Approve";
exports.rejectBtnTxt = "Reject";
exports.waiting = "waiting";
exports.approved = "approved";
exports.rejected = "rejected";
exports.deleteApiUrl = "/bx_block_productquickview/purchases/";
exports.deleteapiTableUrl = "bx_block_productquickview/products/delete_product_records";
exports.unarchiveApiUrl = "/bx_block_productquickview/products/unarchive_product_records";
exports.archiveApiUrl = "/bx_block_productquickview/products/archive_product_records";
exports.fetchCategory = "/bx_block_productquickview/general_infos/retrieve_categories_by_parent?parent_category=";
exports.salesApiUrl = "bx_block_salesreporting/sales";
exports.purchasesApiUrl = "bx_block_productquickview/purchases";
exports.saveApiUrl = "bx_block_productquickview/products/";
exports.singleProductApiUrl = "bx_block_productquickview/products/";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "itemavailability";
exports.labelBodyText = "itemavailability Body";
exports.userDataEndPoint = `/account_block/accounts`;
exports.btnExampleTitle = "CLICK ME";
exports.currency = "OMR";
exports.getunitofMeasureDropdown = "/bx_block_productquickview/uoms?per_page=9&page=1"
exports.exportsCsvUrl = "";
exports.downloadExportProductUrl = ''
exports.importProductUrl = ''
exports.exportHeadingProduct = 'List of Product/'
exports.exportSubheadingProduct = 'Import a file'
exports.exportProductTitle = 'Upload a CSV file to import'
exports.exportProductSubTitle = 'CSV file are recommended as formatting is automatic'
exports.exportProductHelpTxt = 'Need Help?'
exports.exportProductDownloadBtnTxt = 'Import Template for Products'
exports.exportProductUploadBtnTxt = 'Upload file'
exports.exportProductCancelBtnTxt = 'Cancel'
exports.uomCategories = "List of Product"
// Customizable Area End