import React from 'react';
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ExpandMore } from '@material-ui/icons';
import StorageProvider from "../../../framework/src/StorageProvider";
import { Box, ClickAwayListener, List, ListItem, ListItemText, MenuItem, Select, Typography } from '@material-ui/core';
import { validationerror } from '../../cfcustomersalesreports3/src/assets';
import { City, Country, State } from 'country-state-city';
export const configJSON1 = require("./config.js")
export const configJSON = require("../../settings2/src/config.js");


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export interface Props {
    navigation: any;
    id: string;
    
    
    // Customizable Area Start
    classes?: any;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    bank_name:string;
    address:string;
    address2:string;
    identifier_code:string;
    abm_code:string;
    cityName:string;
    countryName:string;
    stateName:string;
    zip:string;
    vat:string;
    phone_number:string;
    email_address:string;
    token: string | null;
    saveClick:boolean;
    showSettings:boolean;
    bankId: string|null;
    apiMethod: string;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export interface Option {
    value: string;
    label: string;
    
}

export default class AddBankController extends BlockComponent<
    Props,
    S,
    SS
> {
    addBankApiCallId: any = "";
    apigetBankCallId!: string;
    stateObj = {
                bank_name:'',
                address:'',
                address2:'',
                identifier_code:'',
                abm_code:'',
                cityName:'',
                countryName:'',
                stateName:'',
                zip:'',
                vat:'',
                phone_number:'',
                mobile:'',
                email_address:'',
                token: '',
                saveClick:false,
                showSettings:false,
                bankId: null,
                apiMethod: "POST"
              };
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
          ];
        // Customizable Area Start  
        this.state = this.stateObj;
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        
        
        // Customizable Area End
    }

    async componentDidMount() {
      const bankId = window.location.href.split('AddBank/')[1]
      
      const getToken =  await StorageProvider.get("TOKEN")
       this.setState({
         token: getToken,
         bankId,
         apiMethod:bankId ? 'PUT' : 'POST'
       })
       
    }

    async componentDidUpdate(prevProps: {}, prevState: S) {
    
      if ((prevState.bankId !== this.state.bankId) && this.state.bankId) {
        await this.getBankApi()
      }
      
      
    }

    async receive(from: string, msgs: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === msgs.id) {
          const apiRequestCallId = msgs.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
    
          let responseJson = msgs.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          let errorReponse = msgs.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          runEngine.debugLog("API Message Recived", msgs);
          
          if (responseJson) {
            if (responseJson.errors) {
                this.handleBankErrorResponse(responseJson.errors);
                
            }
            
            if ((apiRequestCallId === this.addBankApiCallId) && responseJson.data?.id) {
              this.addBankResponse()
            }

            if (apiRequestCallId === this.apigetBankCallId) {
              this.getBankApiResponse(responseJson)
            }
          } 
          
        }
        // Customizable Area End
      }

    addBankResponse = () => {
        if(this.state.bankId){
          this.showAlert(
            configJSON.success,
            configJSON1.bank_updated_msg
          );
        }else{
          this.showAlert(
            configJSON.success,
            configJSON1.bank_created_msg
          );
          this.setState(this.stateObj);
        }
        this.props.navigation.navigate("BanksListView")
      
    }

    getBankApi = async () => {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token":this.state.token
      };
  
      this.apigetBankCallId = requestMessage.messageId;
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON1.bankApiUrl}/${this.state.bankId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getBankApiResponse = (responseJson:any) => {
      if(responseJson.data && responseJson.data.attributes){
        const bankDetail:any = responseJson.data.attributes;
        this.setState({
          bank_name:bankDetail.bank_name,
          address:bankDetail.address.split('@')[0],
          address2:bankDetail.address.split('@')[1],
          identifier_code:bankDetail.bank_identifier_code,
          abm_code:bankDetail.abm_code,
          cityName:bankDetail.city,
          countryName:bankDetail.country.split('@')[1],
          stateName:bankDetail.state.split('@')[1],
          zip:bankDetail.postal_code,
          vat:bankDetail.vat,
          phone_number:bankDetail.phone_number,
          email_address:bankDetail.email_address,
        })
      }
    }

    handleBankErrorResponse = (error:any) => {
        let msg = configJSON.something_went_wrong;
        if(error[0]){
            msg = error[0];
        }
    }

    handleSettings = () => {
    this.setState({ ...this.state, showSettings: !this.state.showSettings });
    };

    showSettingsUI = (classes:any) => {
        return <ClickAwayListener
                    onClickAway={this.handleSettings}
                 >
                <Box className={`settingsBox ${classes.showSettingsUI}` } >
                    <List>
                        <ListItem button className='listItem font-14'>
                            <ListItemText primary="Archive" />
                        </ListItem>
                        <ListItem button className='font-14'>
                            <ListItemText primary="Duplicate" />
                        </ListItem>
                    </List>
                </Box>
                </ClickAwayListener>
    }

    isStringNullOrBlank(str: string) {
    return str === null || str?.length === 0;
    }

    discardBankData = () => {
      this.setState(this.stateObj);
      this.props.navigation.navigate("BanksListView")
    }
    
    expandMore = () => {
      return <ExpandMore/>;
    }

    errorText = (field:any,value:any) => {
    if((this.handleErrorMessage1(field) || this.handleErrorMessage2(field)) && (!value || value.trim()==='')) {
        return 'error-text1';
    }else{
        return 'main-color';
    }
    }

    errorFocus = (field:any,value:any) => {
      if ((this.handleErrorMessage1(field) || this.handleErrorMessage2(field)) && (!value || value.trim() === '')) {
        return 'error-focus';
    }else{
        return '';
    }
    }

    mainBankErrorBox = (classes:any) => {
      return <Box className={`flex ${classes.errorMainbox} ${!(this.state.saveClick && (!this.checkBankValidation())) && 'display-none'}`}>
            <img src={validationerror}/>
                <Typography className='error-main-text'>Some of the fields are incorrect or invalid. Please enter the valid details to continue.</Typography>
            </Box>
    }
    

    addBankData = () => {
      if (this.state.phone_number.length >= 10
        &&  this.state.phone_number.length <= 15) {
        const countries = Country.getAllCountries();
        const stat = State.getStatesOfCountry(this.state.countryName)
        this.setState({...this.state,saveClick:false})
        let formdata:any = new FormData();
        formdata.append("data[bank_name]",this.state.bank_name);
        formdata.append("data[bank_identifier_code]",this.state.identifier_code);
        formdata.append("data[abm_code]",this.state.abm_code);
        formdata.append("data[phone_number]",this.state.phone_number);
        formdata.append("data[email_address]",this.state.email_address);
        formdata.append("data[vat]",this.state.vat);
        formdata.append("data[address]",this.state.address+'@'+this.state.address2);
        formdata.append("data[country]",countries.filter((c:any)=>c.isoCode==this.state.countryName)[0]?.name+'@'+this.state.countryName);
        formdata.append("data[state]",stat.filter((c:any)=>c.isoCode==this.state.stateName)[0]?.name+'@'+this.state.stateName);
        formdata.append("data[city]",this.state.cityName);
        formdata.append("data[postal_code]",this.state.zip);
        
        const header = {
        token: this.state.token,
        };
        const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addBankApiCallId = requestMessage.messageId;

        requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        this.state.bankId ? configJSON1.addBankApiUrl+'/'+this.state.bankId : configJSON1.addBankApiUrl
        );
        requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
        );
        requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
        );
        requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        this.state.apiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      }
    }

    handleLabel = (flabel:any) => {
        return <>
        {
          flabel ? this.handleError(flabel) : flabel
        }
        </>
      }

    handleError = (flabel:any) => {
    return <Box className='flex relative' >
                <img className='error-text-img' src={validationerror} />
            <Typography className='error-text'>{flabel}</Typography>
            </Box>
    }

    checkDefaultColor = (value:string) => {
      if(value){
        return ''
      }else{
        return 'select-default-color'
      }
    }

    handleErrorMessage1 = (type:string,show:boolean=false) => {
        if(this.state.saveClick || show){
          let flabel:any = '';
          switch(type){
            
            case 'address': flabel = !this.state.address.trim() && 'Please enter valid address.'; break;
            case 'address2' :  flabel = !this.state.address2 && 'Please enter valid address.'; break;
            case 'countryName' :  flabel = !this.state.countryName && 'Please select valid Country.'; break;
            case 'stateName' :  flabel = !this.state.stateName.trim() && 'Please select valid State.'; break;
            case 'cityName' :  flabel = !this.state.cityName.trim() && 'Please select valid City.'; break;
            case 'zip': flabel = /^\s*$/.test(this.state.zip.trim()) && 'Please enter valid postal code.'; break;
            case 'vat': flabel = /^\s*$/.test(this.state.vat) && 'Please enter valid VAT.'; break;
            default :  
            break;
            
          }
          return this.handleLabel(flabel)
        }else{
          return ''
        }
        
      }
      handleErrorMessage2 = (type: string, show: boolean = false) => {
        if (this.state.saveClick || show) {
          let flabel: any = '';
          switch (type) {
            case 'bank_name':
              flabel = /^\s*$/.test(this.state.bank_name) && (this.state.bank_name.trim() === '' || this.state.abm_code.trim().length === 0) && 'Please enter valid bank name.';
              break;
            case 'identifier_code':
              flabel = /^\s*$/.test(this.state.identifier_code) && (this.state.identifier_code.trim() === '' || this.state.identifier_code.trim().length === 0) && 'Please select valid bank identifier code.';
              break;
            case 'abm_code':
              flabel = /^\s*$/.test(this.state.abm_code) && 'Please select valid abm code.';
              break;
            case 'email_address':
              flabel = /^\s*$/.test(this.state.email_address) && 'Please enter valid email address.';
              break;
            case 'phone_number':
              flabel = /^\d+$/.test(this.state.phone_number) && 'Please enter valid phone number.';
              break;
            case 'email1':
              flabel = configJSON.errorEmailNotValid;
              break;
            case '1':
              flabel = configJSON1.errorMobileLengthNotValidMin;
              break;
            case '2':
              flabel = configJSON1.errorMobileLengthNotValidMax;
              break;
            default:
              break;
          }
          return this.handleLabel(flabel);
        } else {
          return '';
        }
      }
      

    isValidEmail = (Email: string) => {
        let reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (Email && reg.test(Email) === false && Email.trim()) {
          return false;
        } else {
          return true;
        }
    };

    checkMobileLength = (mob: any) => {
        if (mob && mob.length<10) {
          return "min";
        }
        else if (mob && mob.length>15)
          return "max"; 
      }
      

    checkBankOtherFields = () => {
        if(!this.isValidEmail(this.state.email_address)){
          return false;
        }
        return true;
      
    }

    handleCountry = (event:any) => {
        const countryName = event.target?.value as string;
        this.setState({
          ...this.state,
          countryName,
          stateName: '',
          cityName: '',
        });
      };

      handleRegion = (event:any) => {
        const stateName = event.target?.value as string;
        this.setState({
          ...this.state,
          stateName,
          cityName: '',
        });
      };

      handleCity = (event:any) => {
        const cityName = event.target?.value as string;
        this.setState({
          ...this.state,
          cityName,
        });
        
      };

      SelectCityUI = (stateName:any,cityName:any,countryName:any) => {
        return <>
              <Select
                    IconComponent={this.expandMore}
                    data-test-id="cityNameInput"
                    inputProps={{
                        'aria-label': 'custom select',
                    }}
                    MenuProps={{
                        PaperProps: {
                            style: {
                            marginTop:52,
                            },
                        },
                    }}
                    value={cityName}
                    displayEmpty
                    onChange={this.handleCity}
                    label="Select"
                    disableUnderline
                    className={`width-100 ${this.checkDefaultColor(this.state.cityName)} ${this.errorFocus('cityName',this.state.cityName)}`}
                    
                >
                    <MenuItem disabled value="" style={{ display: 'none', color: "grey" }}>Select City</MenuItem>
                    {(stateName ? City.getCitiesOfState(countryName,stateName) : [])?.length>0 && (stateName ? City.getCitiesOfState(countryName,stateName) : []).map((city:any,index:number) => (
                        <MenuItem className='select-menu-item' key={index} value={city.name+'@'+city.latitude+'@'+city.longitude}>
                        {city.name}
                        </MenuItem>
                    ))}
                    
                    
                </Select>
              </>
      }

      SelectStateUI = (stateName:any,regions:any) => {
        return <>
                <Select
                    IconComponent={this.expandMore}
                    data-test-id="stateNameInput"
                    inputProps={{
                        'aria-label': 'custom select',
                    }}
                    MenuProps={{
                        PaperProps: {
                            style: {
                            marginTop:52,
                            },
                        },
                    }}
                    value={stateName}
                    displayEmpty
                    onChange={this.handleRegion}
                    label="Select"
                    disableUnderline
                    className={`width-100 ${this.checkDefaultColor(this.state.stateName)} ${this.errorFocus('stateName',this.state.stateName)}`}
                    
                >
                    <MenuItem disabled value="" style={{ display: 'none', color: "grey" }}>Select State</MenuItem>
                    {regions?.length>0 && regions.map((region:any) => (
                    <MenuItem  key={region.isoCode} value={region.isoCode}>
                    {region.name}
                    </MenuItem>
                    ))}
                    
                    
                </Select>
            
              </>
    }

    checkFields = () => {
      return (this.isStringNullOrBlank(this.state.bank_name) ||
      this.isStringNullOrBlank(this.state.identifier_code) ||
      this.isStringNullOrBlank(this.state.abm_code) ||
      this.isStringNullOrBlank(this.state.email_address) ||
      this.isStringNullOrBlank(this.state.phone_number) ||
      this.isStringNullOrBlank(this.state.address) ||
      this.isStringNullOrBlank(this.state.countryName) ||
      this.isStringNullOrBlank(this.state.stateName) ||
      this.isStringNullOrBlank(this.state.cityName) ||
      this.isStringNullOrBlank(this.state.zip) ||
      this.isStringNullOrBlank(this.state.vat))
    }

    checkBankValidation = () => {
        if (this.checkFields()) {
                return false;
            }
            else if(!this.checkBankOtherFields()){
                return false;
            }
            return true;
    }

    addBank = () => {
        this.setState({...this.state,saveClick:true})
        if (!this.checkBankValidation()) {
          
          return false;
        }else {
        this.addBankData();
        }
    }

    

    

}