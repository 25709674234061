import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { ChangeEvent } from 'react';
import { imgPasswordInVisible, imgPasswordVisible, noProductImg } from "./assets";
import {
  filterTableData,
  getTableKey,
  getGroupbyTableKey,
} from "../../../components/src/Utils/utils";
import { TableData } from "../../../components/src/Utils/types";
export type token = string | null;
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import * as Yup from "yup";
import { FormikProps } from "formik";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FILTER_FIELDS } from "../../../components/src/Utils/constants";

interface File {
  name: string;
  type: string;
}

type FormDataType = {
  canbesold: boolean;
  canbepurchased: boolean;
  product_type: string;
  invoice_policy: string;
  unit_of_measure: string;
  purchased_uom: string;
  product_code: string;
  sales_price: number;
  customer_tax: {
    id: number;
    title: string;
    value: string;
  }[];
  product_category: string;
  internal_notes: string;
  cost: number;
  optional_products: {
    id: number;
    title: string;
    value: string;
  }[];
  accessory_products: {
    id: number;
    title: string;
    value: string;
  }[];
  alternative_products: {
    id: number;
    title: string;
    value: string;
  }[];
  available_in_POS: boolean;
  to_weigh_with_scale: boolean;
  sales_description: string;
  account_id: number;
  extra_product_media: File[];
  responsible: string;
  barcode: string;
  weight: string;
  trackingValue: string;
  volume: string;
  manuf_lead_time: string;
  customer_lead_time: string;
  hs_code: string;
  description_for_receipts: string;
  description_for_delivery_orders: string;
  description_for_internal_transfers: string;
  control_policy: string;
  description_for_purchase: string;
  vendor_tax: {
    id: number;
    title: string;
    value: string;
  }[];
  purchase_description: string;
}

export const tableHeading = [
  "Name",
  "Responsible",
  "Product Code",
  "Sales Price",
  "Cost",
  "Category",
  "Type",
  "QTY on Hand",
  "Forecasted QTY",
  "UMO",
];

export const tableBodyContent = {
  name: "LED TV",
  responsible: "Mark Demo",
  productCode: "456875DFGT",
  salesPrice: "50.00 OMR",
  cost: "30.00 OMR",
  category: "All/Delivery",
  type: "Storable Product",
  qTYOnHand: "16.00",
  forecastedQTY: "132.00",
  UMO: "Units",
};

type tableBodyContentType = {
  id: string;
  name: string;
  productImage: string;
  purchaseDescription: string;
  responsible: string;
  productCode: string;
  salesPrice: string;
  cost: string;
  category: string;
  type: string;
  qTYOnHand: number;
  forecastedQTY: number;
  UMO: string;
  checked: boolean;
  delivery: number;
};

type groupbyBodyContentType = {
  key: string;
  total_sales_price: number;
  data: tableBodyContentType[];
};

export const tableBody = Array(18).fill(tableBodyContent);

interface UomData {
  id: string;
  type: string;
  attributes: {
    category: string | null;
    unspsc_category: string;
    archive: boolean;
    unit_of_measures: {
      id: number;
      ratio: number;
      active: boolean | null;
      rounding_precision: number;
      uom: string;
      uom_type: string;
      uom_category_id: number;
      archive: boolean | null;
    }[];
  };
}

interface UserDataType {
  id: string;
  type: string;
  attributes: {
    first_name: string;
    email: string;
    related_partner: string;
    access_right: {
      id: number;
      allowed_companies: string[];
      user_types: string;
    };
    account_id: number;
    accounting: string[];
    allowed_companies: string[];
    approval: string[];
    contacts: string[];
    created_at: string;
    default_company: string;
    human_resources: string[];
    inventory: string[];
    point_of_sale: string[];
    purchase: string[];
    sales: string[];
    settings: string[];
    sign: string[];
    tag: string[];
    updated_at: string;
    user_types: string;
    preference: {
      id: number;
      language: string;
      timezone: string;
      notification: string;
    };
    latest_authentication: string | null;
    login_type: string | null;
  };
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: Record<string, any>;
  formikProps?: FormikProps<any>;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isViewTypeChanged: boolean;
  anchorEl: HTMLElement | null;
  anchorElement: HTMLElement | null;
  token: token;
  filteredData: TableData[];
  selectedFilter: string;
  tableData: TableData[];
  responseData: tableBodyContentType[] | groupbyBodyContentType[];
  isChecked: boolean;
  isAllChecked: boolean;
  currentPage: number;
  rowsPerPage: number;
  isGeneraltabEditing: boolean;
  isSalesEditing: boolean;
  isPurchaseEditing: boolean;
  isInventoryEditing: boolean;
  isAccountingEditing: boolean
  productId: string;
  row: {
    from: number;
    end: number;
  };
  totalPage: number;
  totalCounts: number;
  dataLength: number;
  query: string;
  anchorElFilter: HTMLElement | null;
  anchorElGroupBy: HTMLElement | null;

  params: { [name: string]: boolean | string };

  selectedItems: {
    [name: string]: boolean;
  };

  isGroupby: boolean;
  isLoading: boolean;
  isLoadMore: boolean;
  showFav: boolean;
  showSaveCurrentSearch: boolean;
  useByDefault: boolean;
  showInfoCurrentInSearchBar: boolean;
  queryItems: { id: string, tag: string, key: string, type: string }[];
  completeProductData: tableBodyContentType[] | groupbyBodyContentType[];
  initialLoadComplete: boolean;
  remainingItems: number;
  visibleFields: string[];

  userListData: UserDataType[] | Array<Object>;
  uomCategoriesData: Array<Object>;
  productCategoriesData: Array<Object>;
  salesAttributesData: Array<Object>;
  selectedTab: number;
  loading: boolean;
  product_name_error: string;
  product_name_touched: boolean;
  product_name: string;
  canbesold: boolean;
  canbepurchased: boolean;
  isProductNameNotValid: boolean;
  productImage: any;
  imageUploadValid: boolean;
  productImageError: boolean;
  generalinfo: {
    parent_category: any;
    product_type: string;
    invoice_policy: string;
    unit_of_measure: string;
    purchased_uom: string;
    product_code: string;
    sales_price?: number;
    customer_tax: string | string[];
    product_category: string;
    internal_notes: string;
    cost?: number;
  },
  sales: {
    id: number,
    optional_products: string[],
    accessory_products: string[],
    alternative_products: string[],
    available_in_POS: boolean,
    to_weigh_with_scale: boolean,
    sales_description: string,
    account_id: number,
    extra_product_media: File[],
  },

  filteredSelectedData: [],
  groupbySelectedData: [],
  inventory: {
    tracking: boolean;
    delivery_orders_description: string;
    internal_transfer_description: string;
    receipts_description: string;
    responsible: string,
    barcode: number | string,
    weight: string,
    trackingValue: string,
    volume: string,
    manuf_lead_time: string,
    customer_lead_time: string,
    hs_code: string | number,
    description_for_receipts: string,
    description_for_delivery_orders: string,
    description_for_internal_transfers: string,
  },
  accountingAttributesData: any,
  accounting: {
    income_account: string,
    expense_account: string,
    price_difference_account: string,
    commodity_code: string,
    country_of_origin: string,
    automatic_email_at_invoice: string,
    product_id: number,
    accounting_account_id: number
  }
  purchaseAttributesData: Array<Object>,
  vendorsListTableData: Array<{
    vendor_id: number,
    vendor_name: string,
    subcontracted: string,
    product_variant: string,
    currency: string,
    qty: string,
    uom: string,
    price: string,
    delivery_time_lead: string,
  }>,
  vendorNameList: { vendor_name: string }[],
  deleteDialog: boolean,
  deleteRowId: number,
  deleteVendorId: string,
  purchase: {
    type: string,
    vendor_tax: string | string[],
    purchase_description: string,
    product_id: number,
    account_id: number,
    control_policy: string,
    description_for_purchase: string,
  }
  vendorTaxOption: any;
  optionalProductOption: any;
  accessoryProductOption: any;
  alernativeProductOption: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}


export const filterOptions = [
  { name: "Active Status", keys: "active" },
  { name: "Archived", keys: "archive" },
  { name: "Available in POS", keys: "available_in_POS" },
  { name: "Can be Sold", keys: "can_be_sold" },
  { name: "Can be purchased", keys: "can_be_purchased" },
];

export const groupByOptions = [
  { name: "Product Category", keys: "product_category" },
  { name: "Product Type", keys: "product_type" },
  { name: "Product category Type", keys: "pos_product_type" },
  { name: "Active Status", keys: "group_by_active" },
];

export const SettingOptions = [
  { name: "Export" },
  { name: "Archive" },
  { name: "Unarchive" },
  { name: "Delete" },
];

export default class ItemavailabilityController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserApi: string = "";
  getItemsAvailabilityAPI: string = "";
  getItemsAvailabilityDataAPI: string = "";
  productGeneralInfoAPICallId: string = "";
  getUomCategoriesAPI: string = "";
  getProductCategoriesAPI: string = "";
  getSalesAttributesAPI: string = "";
  getAccountingAttributesData: string = "";
  getPurchaseAttributesAPI: string = "";
  deletePurchaseAPI: string = "";
  getVendorsListAPI: string = "";
  getSingleProductAPI: string = "";
  getExportAPICallId: string = "";
  getArchiveAPICallId: string = "";
  getUnarchiveAPICallId: string = "";
  getDeletAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isViewTypeChanged: true,
      showFav: false,
      showSaveCurrentSearch: false,
      useByDefault: false,
      showInfoCurrentInSearchBar: false,
      tableData: tableBody,
      anchorEl: null,
      anchorElement: null,
      token: "",
      filteredData: tableBody,
      selectedFilter: "",
      responseData: [],
      isChecked: false,
      isAllChecked: false,
      isGeneraltabEditing: false,
      isSalesEditing: false,
      isPurchaseEditing: false,
      isInventoryEditing: false,
      isAccountingEditing: false,
      productId: '',
      currentPage: 1,
      rowsPerPage: 9,
      row: {
        from: 1,
        end: 9,
      },
      totalPage: 0,
      totalCounts: 0,
      dataLength: 0,
      query: "",
      anchorElFilter: null,
      anchorElGroupBy: null,
      selectedItems: {},

      visibleFields: FILTER_FIELDS.map((field) => field.name),
      params: {},
      isGroupby: false,
      isLoading: false,
      isLoadMore: false,
      queryItems: [],
      completeProductData: [],
      initialLoadComplete: false,
      remainingItems: 0,
      userListData: [],
      loading: true,
      uomCategoriesData: [],
      productCategoriesData: [],
      salesAttributesData: [],
      selectedTab: 0,
      product_name_error: "",
      product_name_touched: false,
      product_name: "",
      canbesold: false,
      canbepurchased: false,
      isProductNameNotValid: false,
      productImage: null,
      productImageError: false,
      imageUploadValid: false,
      generalinfo: {
        product_type: "",
        invoice_policy: "",
        unit_of_measure: "",
        purchased_uom: "",
        product_code: "",
        sales_price: undefined,
        customer_tax: [],
        product_category: "",
        parent_category: "",
        internal_notes: "",
        cost: undefined,
      },
      sales: {
        id: 0,
        optional_products: [],
        accessory_products: [],
        alternative_products: [],
        available_in_POS: false,
        to_weigh_with_scale: false,
        sales_description: "",
        account_id: 317,
        extra_product_media: [],
      },
      filteredSelectedData: [],
      groupbySelectedData: [],
      inventory: {
        tracking: false,
        delivery_orders_description: '',
        internal_transfer_description: '',
        receipts_description: '',
        responsible: "",
        barcode: "",
        weight: "",
        trackingValue: "By Unique Serial Number",
        volume: "",
        manuf_lead_time: "",
        customer_lead_time: "",
        hs_code: "",
        description_for_receipts: "",
        description_for_delivery_orders: "",
        description_for_internal_transfers: "",
      },
      accountingAttributesData: {},
      accounting: {
        income_account: "",
        expense_account: "",
        price_difference_account: "",
        commodity_code: "",
        country_of_origin: "",
        automatic_email_at_invoice: "",
        product_id: 220,
        accounting_account_id: 1
      },
      purchaseAttributesData: [],
      vendorsListTableData: [{
        vendor_id: 0,
        vendor_name: "",
        subcontracted: "---",
        product_variant: "Height 700 cm",
        currency: "OMR",
        qty: "01.00",
        uom: "Units",
        price: "500.00 OMR",
        delivery_time_lead: "1",
      }],
      vendorNameList: [
        { vendor_name: "Vendor 1" },
        { vendor_name: "Vendor 2" },
        { vendor_name: "Vendor 3" },
      ],
      deleteDialog: false,
      deleteRowId: 0,
      deleteVendorId: "",
      purchase: {
        type: "",
        vendor_tax: "",
        purchase_description: "",
        product_id: 0,
        account_id: 0,
        control_policy: "On received quantities",
        description_for_purchase: "",
      },
      vendorTaxOption: [],
      optionalProductOption: [],
      accessoryProductOption: [],
      alernativeProductOption: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.receiveApi(apiRequestCallId, responseJson);
        if (apiRequestCallId === this.getSingleProductAPI && responseJson.data) {
          const currentProductData = responseJson.data.data.attributes
          this.fetchProductCategories(currentProductData.general_info.parent_category)
          this.setState({
            product_name: currentProductData.product_name,
            canbesold: currentProductData.can_be_sold,
            canbepurchased: currentProductData.can_be_purchased,
            generalinfo: currentProductData.general_info,
            sales: currentProductData.sale,
            inventory: currentProductData.inventory,
            accounting: currentProductData.accounting,
            purchase: currentProductData.purchases,
            productImage: currentProductData.product_image ? currentProductData.product_image.url : noProductImg,

          }, () => { this.setState({ loading: false }) })
        }
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({
        enableField: !this.state.enableField,
      });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({
      enableField: !this.state.enableField,
    });
  };

  // Customizable Area Start

  productImageRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
  extraMediaRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();


  async componentDidMount() {
    const token = await getStorageData("TOKEN")
    this.setState({ token: token });
    const savedDefaultView = JSON.parse(await getStorageData('productDefaultView'));
    if (!token) {
      this.props.navigation.navigate("EmailAccountLoginStyles")
    }
    else {
      this.fetchDataLength();
      this.fetchFilterGroupData();
      this.fetchUomCategories();
      this.fetchSalesAttributes();
      this.fetchPurchaseAttributes();
      this.fetchVendorsList();
      this.getUserList();
    }
    const savedState = await getStorageData("editingState")
    if (savedState) {
      const editingState = JSON.parse(savedState);

      this.getSingleProductData(editingState.productId);
      this.setState(editingState);
    }
    if (savedDefaultView) {
      this.setState({
        query: savedDefaultView.query,
        isGroupby: savedDefaultView.isGroupby,
        queryItems: savedDefaultView.queryItems,
        useByDefault: savedDefaultView.useByDefault,
        showInfoCurrentInSearchBar: savedDefaultView.showInfoCurrentInSearchBar
      });
    }
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    const token = this.state.token;
    if (prevState.query !== this.state.query ||
      (prevState.currentPage !== this.state.currentPage ||
        prevState.rowsPerPage !== this.state.rowsPerPage)
    ) {
      let searchStringItem = ""
      if (this.state.query) {
        searchStringItem = `&search_text=${this.state.query}`
      }
      const { currentPage, rowsPerPage } = this.state;
      const params = `page=${currentPage}&per_page=${rowsPerPage}${searchStringItem}`
      this.getItemsAvailabilityAPI = this.apiCall(
        token,
        `bx_block_productquickview/products?${params}`,
        `${configJSON.validationApiMethodType}`
      );
    }
    else if (
      prevState.currentPage !== this.state.currentPage ||
      prevState.rowsPerPage !== this.state.rowsPerPage ||
      prevState.queryItems.length !== this.state.queryItems.length) {
      this.fetchFilterGroupData()
    }
   else if (prevState.queryItems !== this.state.queryItems) {
      this.fetchFilterGroupData()
    }

  }

  getUserList = async () => {
    const token = await getStorageData("TOKEN");
    this.setState({ token: token });
    this.getUserApi = this.apiCall(
      token,
      configJSON.userDataEndPoint,
      configJSON.validationApiMethodType
    );
  }

  fetchFilterGroupData = async () => {
    this.setState({
      isLoading: true,
    })
    const token = this.state.token;
    let params = {} as any
    this.state.queryItems.forEach((item: any) => {
      if (item.hasOwnProperty("key")) {
        params[item.key] = true
      }
    })

    const strng = Object.keys(params)
      .map(function (keys) {
        return keys + "=" + params[keys];
      })
      .join("&");
    const { currentPage, rowsPerPage } = this.state;


    this.getItemsAvailabilityAPI = this.apiCall(
      token,
      this.state.isGroupby ? `bx_block_productquickview/products?${strng}` : `bx_block_productquickview/products?page=${currentPage}&per_page=${rowsPerPage}&${strng}`,
      `${configJSON.validationApiMethodType}`
    );
  };

  fetchDataLength = async () => {
    const token = await getStorageData("TOKEN");
    this.setState({ token: token });
    this.setState({
      isLoading: true,
    })
    this.getItemsAvailabilityDataAPI = this.apiCall(
      token,
      `bx_block_productquickview/products/product_list_dropdown`,
      `${configJSON.validationApiMethodType}`
    );
  };

  fetchUomCategories = async () => {
    const token = await getStorageData("TOKEN");
    this.setState({ token: token });
    this.getUomCategoriesAPI = this.apiCall(
      token,
      `${configJSON.getunitofMeasureDropdown}`,
      `${configJSON.validationApiMethodType}`
    );
  };

  fetchProductCategories = async (category: string) => {
    const token = await getStorageData("TOKEN");
    this.setState({ token: token });
    this.getProductCategoriesAPI = this.apiCall(
      token,
      `${configJSON.fetchCategory}${category}`,
      `${configJSON.validationApiMethodType}`
    );
  };

  fetchSalesAttributes = async () => {
    const token = await getStorageData("TOKEN");
    this.setState({ token: token });
    this.getSalesAttributesAPI = this.apiCall(
      token,
      `${configJSON.salesApiUrl}`,
      `${configJSON.validationApiMethodType}`
    );
  };

  fetchPurchaseAttributes = async () => {
    const token = await getStorageData("TOKEN");
    this.setState({ token: token });
    this.getPurchaseAttributesAPI = this.apiCall(
      token,
      `${configJSON.purchasesApiUrl}`,
      `${configJSON.validationApiMethodType}`
    );
  };

  fetchVendorsList = async () => {
    const token = await getStorageData("TOKEN");
    this.setState({ token: token });
    this.getVendorsListAPI = this.apiCall(
      token,
      `/account_block/accounts/get_customers_list?usertype=vendor`,
      `${configJSON.validationApiMethodType}`
    );
  };

  handleArchive = (selectedIds: number[]) => {
    const token = this.state.token;
    const method = "PATCH";
    const contentType = configJSON.validationApiContentType;
    const body = {
      "product_record_ids": `[${selectedIds.join(',')}]`
    }
    this.getArchiveAPICallId = this.apiCall(
      token,
      `${configJSON.archiveApiUrl}`,
      method,
      JSON.stringify(body), contentType
    );
  }

  handleUnarchive = (selectedIds: number[]) => {
    const token = this.state.token;
    const method = "PATCH";
    const contentType = configJSON.validationApiContentType;
    const body = {
      "product_record_ids": `[${selectedIds.join(',')}]`
    }
    this.getUnarchiveAPICallId = this.apiCall(
      token,
      `${configJSON.unarchiveApiUrl}`,
      method,
      JSON.stringify(body), contentType
    );
  }

  OnDelete = () => {
    const selectedIds = Object.keys(this.state.selectedItems).map(Number);
    const token = this.state.token;
    const method = "DELETE";
    const contentType = configJSON.validationApiContentType;
    const body = {
      "product_record_ids": `[${selectedIds.join(',')}]`
    }
    this.getDeletAPICallId = this.apiCall(
      token,
      `${configJSON.deleteapiTableUrl}`,
      method,
      JSON.stringify(body), contentType
    );
  }

  handleDelete = () => {
    this.setState({
      deleteDialog: true,
      anchorEl: null
    })
  };

  handleExport = (selectedIds: number[]) => {
    const token = this.state.token;
    const method = "PATCH";
    const contentType = configJSON.validationApiContentType;
    const body = {
      "product_record_ids": `[${selectedIds.join(',')}]`
    }
    this.getExportAPICallId = this.apiCall(
      token,
      `${configJSON.exportsCsvUrl}`,
      method,
      JSON.stringify(body), contentType
    );
  }

  handleSettingAction = (value: string, selectedKeys: {
    [name: string]: boolean;
  }) => {
    let selectedIds = Object.keys(selectedKeys).map(Number);
    if (value === "Archive") {
      this.handleArchive(selectedIds)
    }
    if (value === "Unarchive") {
      this.handleUnarchive(selectedIds)
    }
    if (value === "Delete") {
      this.handleDelete()
    }
    if (value === "Export") {
      this.handleExport(selectedIds)
    }
  }

  receiveApi = (
    apiRequestCallId: string,
    responseJson: {
      data: Array<Object>;
      meta: { total_pages: number; total_counts: number };
      message: string;
    }
  ) => {
    if (apiRequestCallId === this.getItemsAvailabilityAPI) {
      this.handleGetItemResponse(
        responseJson.data,
        responseJson.meta.total_pages,
        responseJson.meta.total_counts
      );
    }
    if (apiRequestCallId === this.productGeneralInfoAPICallId) {
      if (responseJson.message) {
        toast.success(responseJson.message)
        this.props.navigation.navigate("Itemavailability");
      }
    }
    if (apiRequestCallId === this.getItemsAvailabilityDataAPI) {
      this.handleGetDataLegthResponse(responseJson.data);
    }
    if (apiRequestCallId === this.getUserApi) {
      this.handleUserListResponse(responseJson.data);
    }
    if (apiRequestCallId === this.getUomCategoriesAPI) {
      this.handleGetUomCategoriesResponse(responseJson.data);
    }
    if (apiRequestCallId === this.getProductCategoriesAPI) {
      this.handleGetProductCategoryResponse(responseJson);
    }
    if (apiRequestCallId === this.getSalesAttributesAPI) {
      this.handleSalesAttributesResponse(responseJson.data);
    }
    if (apiRequestCallId === this.getPurchaseAttributesAPI) {
      this.handlePurchaseAttributesResponse(responseJson.data);
    }
    if (apiRequestCallId === this.getArchiveAPICallId) {
      this.handleArchiveSettingResponse(responseJson);
    }
    if (apiRequestCallId === this.getUnarchiveAPICallId) {
      this.handleUnarchiveSettingResponse(responseJson);
    }
    if (apiRequestCallId === this.getDeletAPICallId) {
      this.handleDeleteSettingResponse(responseJson);
    }
    if (apiRequestCallId === this.getExportAPICallId) {
      this.handleExportResponse(responseJson)
    }
  };

  extractUserName(data: UserDataType[]): { id: string; title: string; value: string }[] {
    const optionsList: {
      id: string;
      title: string;
      value: string
    }[] = [];
    if (Array.isArray(data)) {
      for (const item of data) {
        if (item.attributes.first_name !== " ") {
          optionsList.push({
            id: item.id.toString(),
            title: item.attributes.first_name,
            value: item.attributes.first_name,
          });
        }
      }
    }

    return optionsList;
  }

  extractUomValues(data: any, p0: string, p1: string): { id: string; title: string; value: string }[] {
    let optionsList: {
      id: string;
      title: string;
      value: string
    }[] = [];
    optionsList = data.map((val: any) => {
      return {
        id: val.id,
        title: val.attributes.uom,
        value: val.attributes.uom
      }
    })
    return optionsList;
  }

  extractProductCategoryValues(data: any[]): { id: string; title: string; value: string; }[] {
    const categories: { id: string; title: string; value: string }[] = [];
    if (Array.isArray(data)) {
      for (const item of data) {
        const category = {
          product_id: item.attributes.product_id.toString(),
          product_category: item.attributes.product_category,
        };
        if (category.product_category) {
          categories.push({
            id: category.product_id,
            title: category.product_category,
            value: category.product_category,
          });
        }
      }
    }

    return categories;
  }

  extractOptionalProducts() {
    const optionalProductsArray: { id: string; title: string; value: string }[] = [];
    const data = this.state.completeProductData
    if (Array.isArray(data)) {
      data.forEach((item: any) => {
        const attributes = item.attributes;
        if (attributes.product_name && attributes.product_name.length > 0) {
          optionalProductsArray.push({
            id: attributes.product_id,
            title: attributes.product_name,
            value: attributes.product_name,
          });
        }
      });
    }
    this.setState({
      optionalProductOption: optionalProductsArray
    })
  }

  extractAccessoryProducts() {
    const accessoryProductsArray: { id: string; title: string; value: string }[] = [];
    const data = this.state.salesAttributesData
    if (Array.isArray(data)) {
      data.forEach((item: any) => {
        const attributes = item.attributes;
        if (attributes.accessory_products && attributes.accessory_products.length > 0) {
          accessoryProductsArray.push({
            id: attributes.product_id,
            title: attributes.accessory_products[0],
            value: attributes.accessory_products[0],
          });
        }
      });
    }
    this.setState({
      accessoryProductOption: accessoryProductsArray
    })
  }

  extractAlternativeProducts() {
    const alternativeProductsArray: { id: string; title: string; value: string }[] = [];
    const data = this.state.salesAttributesData
    if (Array.isArray(data)) {
      data.forEach((item: any) => {
        const attributes = item.attributes;
        if (attributes.alternative_products && attributes.alternative_products.length > 0) {
          alternativeProductsArray.push({
            id: attributes.product_id,
            title: attributes.alternative_products[0],
            value: attributes.alternative_products[0],
          });
        }
      });
    }
    this.setState({
      alernativeProductOption: alternativeProductsArray
    })
  }



  toggleMenu = (anchorEl: HTMLElement | null) => {
    this.setState({ anchorEl });
  };

  toggleColumn = (anchorElement: HTMLElement | null) => {
    this.setState({ anchorElement });
  };

  handleFilterChange = (fieldName: string) => {
    const { visibleFields } = this.state;
    const updatedFields = visibleFields.includes(fieldName)
      ? visibleFields.filter((field: string) => field !== fieldName)
      : [...visibleFields, fieldName];

    this.setState({
      visibleFields: updatedFields,
    });
  };


  handleGetItemResponse = (
    data: object[],
    totalPage: number,
    totalCounts: number
  ) => {
    const tableInfo = this.state.isGroupby
      ? getGroupbyTableKey(data)
      : getTableKey(data);
    this.setState({ responseData: tableInfo, totalPage, totalCounts });

    this.setState({
      isLoading: false,
      isLoadMore: false,
    })
  };

  handleGetItemSearchResponse = (
    data: object[],
    totalPage: number,
    totalCounts: number
  ) => {
    const tableInfo = getTableKey(data);
    this.setState({ responseData: tableInfo, totalPage, totalCounts });

    this.setState({
      isLoading: false,
      isLoadMore: false
    })
  };

  handleGetDataLegthResponse = (data: any) => {
    const tableInfoLength = data?.length;
    this.setState({
      dataLength: tableInfoLength,
      completeProductData: data,
    }, () => this.extractOptionalProducts());
  };

  handleUserListResponse = (data: UserDataType[] | Array<Object>) => {
    this.setState({
      userListData: data
    });
  };

  handleGetUomCategoriesResponse = (data: any) => {
    this.setState({
      uomCategoriesData: data
    });
  };

  handleGetProductCategoryResponse = (data: any) => {
    let option = data.categories?.map((val: string, idx: number) => {
      return {
        id: idx,
        title: val,
        value: val
      }
    })
    this.setState({
      productCategoriesData: option
    });
  };

  handleSalesAttributesResponse = (data: any) => {
    this.setState({
      salesAttributesData: data
    });
    this.extractOptionalProducts();
    this.extractAccessoryProducts();
    this.extractAlternativeProducts();
  };

  handlePurchaseAttributesResponse = (data: any) => {
    this.setState({
      purchaseAttributesData: data
    });
    this.extractVendorTax();
  }

  handleArchiveSettingResponse = (data: any) => {
    this.setState({
      selectedItems: {}, isGroupby: false, isAllChecked: false, anchorEl: null, currentPage: 1
    });
    this.fetchFilterGroupData()
    toast.success(data.message);
  }

  handleUnarchiveSettingResponse = (data: any) => {
    this.fetchFilterGroupData()
    this.setState({
      selectedItems: {}, isGroupby: false, isAllChecked: false, anchorEl: null, currentPage: 1
    });
    toast.success(data.message);
  }

  handleDeleteSettingResponse = (data: any) => {
    this.fetchFilterGroupData()
    this.setState({
      selectedItems: {}, isGroupby: false, isAllChecked: false, anchorEl: null, currentPage: 1, deleteDialog: false
    });
    toast.success(data.message);
  }

  handleExportResponse = (responseJson: any) => {
    if (responseJson.download_link) {
      const anchor = document.createElement('a');
      anchor.href = responseJson.download_link;
      anchor.download = '';
      document.body.appendChild(anchor);
      anchor.click();
      this.setState({
        selectedItems: {}, isGroupby: false, isAllChecked: false, anchorEl: null, currentPage: 1
      });
      document.body.removeChild(anchor);
    } else if (responseJson.error) {
      toast.error(responseJson.error[0] || 'An unexpected error occurred');
    } else {
      return
    }
  }

  extractVendorTax() {
    const vendorTaxArray: {
      id: string;
      title: string,
      value: string
    }[] = [];
    const data = this.state.purchaseAttributesData;

    if (Array.isArray(data)) {
      data.forEach((item: any) => {

        const attributes = item.attributes;
        const pid = item.id;
        if (attributes.vendor_tax && attributes.vendor_tax.length > 0) {
          vendorTaxArray.push({
            id: pid,
            title: attributes.vendor_tax,
            value: attributes.vendor_tax,
          });
        }
      });
    }

    this.setState({
      vendorTaxOption: vendorTaxArray
    })
  }

  handleFavorites = () => {
    this.setState({ ...this.state, showFav: !this.state.showFav, showSaveCurrentSearch: false });
  };

  handleCategoriesClick = (event: any) => {
    event.stopPropagation();
    this.setState({ ...this.state, showSaveCurrentSearch: !this.state.showSaveCurrentSearch });
  };

  renderExport = () => {
    this.props.navigation.navigate("ExportProductFavouriteView")
  }

  saveCurrentSearch = async () => {
    const { query, isGroupby, queryItems, useByDefault, showInfoCurrentInSearchBar } = this.state;
    const currentView = {
      query,
      isGroupby,
      queryItems,
      useByDefault,
      showInfoCurrentInSearchBar: !showInfoCurrentInSearchBar
    };

    if (useByDefault) {
      await setStorageData('productDefaultView', JSON.stringify(currentView));
      this.setState({ showInfoCurrentInSearchBar: true })
    } else {
      await removeStorageData('productDefaultView');
      this.fetchFilterGroupData()
      this.setState({ queryItems: [], query: "", showInfoCurrentInSearchBar: false })
    }

    this.setState({ showFav: false, showSaveCurrentSearch: false });
  };

  handleLoadMore = () => {
    const { totalCounts, rowsPerPage, remainingItems, initialLoadComplete } = this.state;
    const leftOver = totalCounts - rowsPerPage;
    const newRowsPerPage = rowsPerPage + 18;
    this.setState({ remainingItems: leftOver, isLoadMore: true, rowsPerPage: newRowsPerPage })

    if (!initialLoadComplete || (leftOver > 0 && remainingItems > 0)) {
      this.setState({
        remainingItems: leftOver,
        isLoadMore: true,
        initialLoadComplete: true,
        rowsPerPage: newRowsPerPage,
      });
    }
  }


  handleCheckBoxSelectGroupby = (
    item: tableBodyContentType,
    index: number,
    updatedSelectedItem: {
      [x: string]: boolean
    },
    checkBoxData: groupbyBodyContentType[]
  ) => {
    if (item.checked) {
      updatedSelectedItem[item.id] = true;
    } else if (updatedSelectedItem.hasOwnProperty(item.id)) {
      delete updatedSelectedItem[item.id];
    }

    checkBoxData.forEach((element: groupbyBodyContentType) => {
      element.data[index] = item;
    })

    return checkBoxData.every((element: groupbyBodyContentType) => {
      return element.data.every((item: tableBodyContentType) => item.checked) === true
    })
  }

  handleCheckBoxSelectFilter = (item: tableBodyContentType, index: number, updatedSelectedItem: { [x: string]: boolean }, checkBoxData: any) => {
    if (item.checked) {
      updatedSelectedItem[item.id] = true;
    } else if (updatedSelectedItem.hasOwnProperty(item.id)) {
      delete updatedSelectedItem[item.id];
    }

    checkBoxData[index] = item;
    return checkBoxData.every((item: tableBodyContentType) => item.checked);
  }


  handleCheckBoxSelect = (item: tableBodyContentType, index: number) => {
    const checkBoxData = [...this.state.responseData] as any
    const updatedSelectedItem = { ...this.state.selectedItems };

    item.checked = !item.checked;

    let isAllChecked = false
    if (this.state.isGroupby) {
      isAllChecked = this.handleCheckBoxSelectGroupby(item, index, updatedSelectedItem, checkBoxData);
    } else {
      isAllChecked = this.handleCheckBoxSelectFilter(item, index, updatedSelectedItem, checkBoxData);
    }

    this.setState({
      responseData: checkBoxData,
      selectedItems: updatedSelectedItem,
      isAllChecked: isAllChecked,
    });
    this.setState({
      isLoading: false,
      isLoadMore: false,
    })
  };

  handleAllCheckFilter = (updatedSelectedItem: { [name: string]: boolean }) => {
    const filterData = this.state.responseData as tableBodyContentType[]
    const checkBoxData = filterData.map((item: tableBodyContentType) => {

      item.checked = !this.state.isAllChecked;

      if (item.checked) {
        updatedSelectedItem[item.id] = item.checked;
      } else {
        if (updatedSelectedItem.hasOwnProperty(item.id)) {
          delete updatedSelectedItem[item.id];
        }
      }

      return item;
    });
    this.setState((prev) => {
      return {
        responseData: checkBoxData,
        isAllChecked: !prev.isAllChecked,
        selectedItems: updatedSelectedItem,
      };
    });
    this.setState({
      isLoading: false,
      isLoadMore: false,
    })
  }

  handleAllCheckGroupby = (updatedSelectedItem: { [name: string]: boolean }) => {
    const groupbyData = this.state.responseData as groupbyBodyContentType[]
    const checkBoxData = groupbyData.map((element: groupbyBodyContentType) => {
      let updatedElement = element
      updatedElement.data = element.data.map((item: tableBodyContentType) => {
        item.checked = !this.state.isAllChecked;
        if (item.checked) {
          updatedSelectedItem[item.id] = item.checked;
        } else {
          if (updatedSelectedItem.hasOwnProperty(item.id)) {
            delete updatedSelectedItem[item.id];
          }
        }
        return item;
      })
      return updatedElement
    }
    );
    this.setState((prev) => {
      return {
        responseData: checkBoxData,
        isAllChecked: !prev.isAllChecked,
        selectedItems: updatedSelectedItem,
      };
    });
    this.setState({
      isLoading: false,
      isLoadMore: false
    })
  }

  handleAllCheck = () => {
    let updatedSelectedItem = Object.assign({}, this.state.selectedItems);
    if (!this.state.isGroupby) {
      this.handleAllCheckFilter(updatedSelectedItem)
    } else {
      this.handleAllCheckGroupby(updatedSelectedItem)
    }
  };

  handleChangePage = (pageNo: number) => {
    this.setState({
      currentPage: pageNo + 1,
      isLoading: true,
    });
    if (this.state.currentPage > pageNo) {
      this.setState((prev) => ({
        row: {
          from: prev.row.from - this.state.rowsPerPage,
          end: prev.row.end - this.state.rowsPerPage,
        },
      }));
    } else {
      this.setState((prev) => ({
        row: {
          from: prev.row.from + this.state.rowsPerPage,
          end: prev.row.end + this.state.rowsPerPage,
        },
      }));
    }
    this.setState({
      isAllChecked: false,
    }, () => this.fetchFilterGroupData())
  };

  handleChangeRowsPerPage = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ currentPage: 1, rowsPerPage: parseInt(event.target.value as string), });
  };
  handleClickFilter = (event: any) => {
    this.setState({
      anchorElFilter: this.state.anchorElFilter ? null : event.currentTarget,
      query: "",
    });
  };

  handleClickGroupBy = (event: any) => {
    this.setState({
      anchorElGroupBy: this.state.anchorElGroupBy ? null : event.currentTarget,
    });
  };

  handleClickSetting = (event: any) => {
    this.setState({
      anchorEl: this.state.anchorEl ? null : event.currentTarget,
    });
  };

  handleGroupBySelect = (item: { name: string; keys: string }) => {
    if (!this.state.isViewTypeChanged) {
      this.setState({
        isViewTypeChanged: true,
      })
    }
    this.setState((prevState) => {
      const updatedQueryItems = [...prevState.queryItems];

      const existingIndex = updatedQueryItems.findIndex(
        (queryItem) => queryItem.type === "groupby"
      );

      if (existingIndex !== -1) {
        updatedQueryItems[existingIndex] = {
          id: item.keys,
          tag: item.name,
          key: item.keys,
          type: "groupby",
        };
      } else {
        updatedQueryItems.push({
          id: item.keys,
          tag: item.name,
          key: item.keys,
          type: "groupby",
        });
      }

      return { queryItems: updatedQueryItems };
    });
    this.setState({
      isGroupby: true,
      anchorElGroupBy: null,
      isAllChecked: false,
      anchorEl: null
    })
  };

  handleRemoveTag = (item: { id: string; tag: string; key: string; type: string; }) => {
    const updatedQueryItems = this.state.queryItems.filter((queryItem) => queryItem.key !== item.key);
    this.setState({ queryItems: updatedQueryItems, query: "" });
    this.state.queryItems.forEach((item) => {
      if (item.type !== "groupby") {
        this.setState({ isGroupby: false });
      }
    })
    if (!updatedQueryItems.length) {
      this.setState({ isGroupby: false });
    }
    if (this.state.rowsPerPage == 18)
      this.setState({
        rowsPerPage: 9
      })
    this.setState({
      currentPage: 1,
      selectedItems: {}, isGroupby: false, isAllChecked: false, anchorEl: null
    })
  }


  onChangeSearchField = (event: any) => {
    const { value } = event.target;
    this.setState({
      query: value,
      currentPage: 1,
    });
    return {};
  }

  handleAddRow = () => {
    const newRow = {
      vendor_id: 0,
      vendor_name: "",
      subcontracted: "---",
      product_variant: "Height 700 cm",
      currency: "OMR",
      qty: "01.00",
      uom: "Units",
      price: "500.00 OMR",
      delivery_time_lead: "1",
    };

    this.setState({
      vendorsListTableData: this.state.vendorsListTableData.concat(newRow),
    })
  };

  handleDeleteRow = (rowIndex: number, vendorId: string) => {
    this.setState({
      deleteDialog: true,
      deleteRowId: rowIndex,
      deleteVendorId: vendorId
    })
  };

  handleNavigateToEdit = (path: string) => {
    this.props.navigation.navigate(path)
  }

  handleNavigateToUom = () => {
    this.props.navigation.navigate("AddUnitOfMeasure")
  }

  handleNavigateProductCategory = () => {
    this.props.navigation.navigate("AddPosProductCategory")
  }

  handleDialogCancel = () => {
    this.setState({
      deleteDialog: false,
    })
  }

  onDeletePurchase = () => {
    const { deleteRowId, deleteVendorId, token } = this.state;
    if (deleteVendorId == "") {
      this.setState((prevState) => ({
        vendorsListTableData: prevState.vendorsListTableData.filter((arrData, i) => i !== deleteRowId),
      }));
    }
    else {
      this.deletePurchaseAPI = this.apiCall(
        token,
        `${configJSON.deleteApiUrl}${deleteVendorId}`,
        "DELETE",
      );
    }
    this.setState({
      deleteDialog: false,
    })
  }

  handleFilterSelect = (item: { name: string; keys: string }) => {
    const existingItemIndex = this.state.queryItems.findIndex(
      (queryItem) => queryItem.key === item.keys
    );
    if (existingItemIndex !== -1) {
      this.setState({ anchorElFilter: null });
    } else {
      this.setState({
        queryItems: [
          ...this.state.queryItems,
          { id: item.keys, tag: item.name, key: item.keys, type: "filter" },
        ],
        anchorElFilter: null,
        currentPage: 1,
        selectedItems: {}, isGroupby: false, isAllChecked: false, anchorEl: null
      });
    }
  };

  handleClickAwayFilter = () => {
    this.setState({ anchorElFilter: null });
  };

  handleClickAwayGroup = () => {
    this.setState({ anchorElGroupBy: null });
  };

  handleClickAwaySettings = () => {
    this.setState({ anchorEl: null });
  };

  validateInput = (input: string = this.state.product_name) => {
    if (!input?.length) {
      this.setState({ isProductNameNotValid: true, product_name_error: 'Product Name is required' });
      return 'Product Name is required'
    }
    else if (input.length < 3) {
      this.setState({ isProductNameNotValid: true, product_name_error: 'Product Name must be at least 3 characters' });
      return 'Product Name must be at least 3 characters';
    }
    else if (input.length > 64) {
      this.setState({ isProductNameNotValid: true, product_name_error: 'Product Name must not exceed 64 characters' });
      return 'Product Name must not exceed 64 characters';
    }
    this.setState({ isProductNameNotValid: false });
    return '';
  };


  handleProductNameChange = (event: any) => {
    const value = event.target.value;
    this.setState({
      product_name: value,
    })
    const validationError = this.validateInput(value);
    this.setState({
      product_name_error: validationError,
    })
  };

  handleProductNameBlur = () => {
    this.setState({
      product_name_touched: true,
    });

    const validationError = this.validateInput(this.state.product_name);
    this.setState({
      product_name_error: validationError,
    });
  };


  handleDiscardImageAndName = () => {
    this.setState({
      productImage: null,
      product_name: ""
    })
    this.props.navigation.navigate("Itemavailability");
  }

  handleproductImageError = () => {
    this.state.productImage === null ?
      this.setState({
        productImageError: true
      })
      :
      this.setState({
        productImageError: false
      })
  }

  transformOptionalProducts(customerTax: string | string[]): Array<{ id?: string; title: string; value: string }> {
    if (Array.isArray(customerTax) && customerTax.length > 0 && typeof customerTax[0] === "string") {
      customerTax = JSON.parse(customerTax[0]);
    }

    if (Array.isArray(customerTax)) {
      return customerTax.map((tax) => ({
        id: undefined,
        title: tax,
        value: tax,
      }));
    }

    return [];
  }

  getInitialValues = () => {
    let vendorTax: any = [];
    if (this.state.purchase.vendor_tax) {
      const vendorTaxArray = Array.isArray(this.state.purchase.vendor_tax)
        ? this.state.purchase.vendor_tax
        : [this.state.purchase.vendor_tax];

      vendorTax = this.transformOptionalProducts(vendorTaxArray);
    }
    return {
      canbesold: this.state.canbesold,
      canbepurchased: this.state.canbepurchased,
      product_type: this.state.generalinfo.product_type,
      invoice_policy: this.state.generalinfo.invoice_policy,
      unit_of_measure: this.state.generalinfo.unit_of_measure,
      purchased_uom: this.state.generalinfo.purchased_uom,
      product_code: this.state.generalinfo.product_code,
      sales_price: this.state.generalinfo.sales_price,
      customer_tax: this.state.generalinfo.customer_tax ? this.transformOptionalProducts(this.state.generalinfo.customer_tax) : [],
      product_category: this.state.generalinfo.product_category,
      parent_category: this.state.generalinfo.parent_category,
      internal_notes: this.state.generalinfo.internal_notes,
      cost: this.state.generalinfo.cost,

      optional_products: this.state.sales.optional_products ? this.transformOptionalProducts(this.state.sales.optional_products) : [],
      accessory_products: this.state.sales.accessory_products ? this.transformOptionalProducts(this.state.sales.accessory_products) : [],
      alternative_products: this.transformOptionalProducts(this.state.sales.alternative_products),
      available_in_POS: this.state.sales.available_in_POS,
      to_weigh_with_scale: this.state.sales.to_weigh_with_scale,
      sales_description: this.state.sales.sales_description,
      account_id: this.state.sales.id,
      extra_product_media: this.state.sales.extra_product_media,

      responsible: this.state.inventory.responsible,
      barcode: this.state.inventory.barcode,
      weight: this.state.inventory.weight,
      trackingValue: this.state.inventory.tracking || "By Unique Serial Number",
      volume: this.state.inventory.volume,
      manuf_lead_time: this.state.inventory.manuf_lead_time,
      customer_lead_time: this.state.inventory.customer_lead_time,
      hs_code: this.state.inventory.hs_code,
      description_for_receipts: this.state.inventory.receipts_description,
      description_for_delivery_orders: this.state.inventory.delivery_orders_description,
      description_for_internal_transfers: this.state.inventory.internal_transfer_description,

      income_account: this.state.accounting.income_account,
      expense_account: this.state.accounting.expense_account,
      price_difference_account: this.state.accounting.price_difference_account,
      commodity_code: this.state.accounting.commodity_code,
      country_of_origin: this.state.accounting.country_of_origin,
      automatic_email_at_invoice: this.state.accounting.automatic_email_at_invoice,
      product_id: this.state.accounting.product_id,
      accounting_account_id: this.state.accounting.accounting_account_id,

      vendor_tax: vendorTax,
      control_policy: this.state.purchase.control_policy,
      description_for_purchase: this.state.purchase.purchase_description,
      purchase_description: this.state.purchase.purchase_description
    };

  }

  validationSchema = Yup.object().shape({
    product_type: Yup.string().nullable().required("Product Type is required"),
    invoice_policy: Yup.string().nullable().required("Invoice Policy is required"),
    unit_of_measure: Yup.string().required("Unit of Measure is required"),
    purchased_uom: Yup.string().required("Purchased Uom is required"),
    product_code: Yup.string().min(3, "Product Code must be min 3 and max 46 characters").max(46, "Product Code must be min 3 and max 46 characters").required("Product Code is required"),
    sales_price: Yup.number().typeError("Sales Price must be a valid number").min(1, "Sales Price must be greater than 0").required("Sales Price is required"),
    customer_tax: Yup.array().required("Customer Tax is required"),
    product_category: Yup.string().required("Product Category is required"),
    parent_category: Yup.string().required("Parent Category is required"),
    internal_notes: Yup.string(),
    cost: Yup.number().typeError("Cost must be a valid number").min(1, "Cost must be greater than 0").required("Cost is required"),

    optional_products: Yup.array(),
    accessory_products: Yup.array(),
    alternative_products: Yup.array(),
    available_in_POS: Yup.boolean().required("Available in POS is required"),
    to_weigh_with_scale: Yup.boolean().required("To weigh with scale is required"),
    sales_description: Yup.string(),
    extra_product_media: Yup.array(),

    responsible: Yup.string().required("Responsible is required"),
    barcode: Yup.string(),
    weight: Yup.string().matches(/^\d+$/, "Weight must contain only numbers").max(46, "Weight must not exceed 46 characters").required("Weight is required"),
    trackingValue: Yup.string().required("Tracking is required"),
    volume: Yup.string().matches(/^\d+$/, "Volume must contain only numbers").max(46, "Volume must not exceed 46 characters").required("Volume is required"),
    manuf_lead_time: Yup.string().matches(/^\d+$/, "Manuf. Lead Time must contain only numbers").max(46, "Manuf. Lead Time must not exceed max 46 characters").required("Manuf. Lead Time is required"),
    customer_lead_time: Yup.string().matches(/^\d+$/, "Customer Lead Time must contain only numbers").max(46, "Customer Lead Time must not exceed 46 characters").required("Customer Lead Time is required"),
    hs_code: Yup.string().matches(/^[a-zA-Z0-9]*$/, "HS Code must be alphanumeric").min(3, "HS Code must be min 3 and max 46 characters").max(46, "HS Code must be min 3 and max 46 characters").required("HS Code is required"),
    description_for_receipts: Yup.string(),
    description_for_delivery_orders: Yup.string(),
    description_for_internal_transfers: Yup.string(),

    income_account: Yup.string().required("Income Account is required"),
    expense_account: Yup.string().required("Expense Account is required"),
    price_difference_account: Yup.string().required("Price Difference Account is required"),
    commodity_code: Yup.string().matches(/^[a-zA-Z0-9]*$/, "Commodity Code must be alphanumeric").required("Commodity Code is required").min(3, "Commodity Code must be min 3 and max 64 characters").max(64, "Commodity Code must be min 3 and max 64 characters"),
    country_of_origin: Yup.string().required("Country of Origin is required"),
    automatic_email_at_invoice: Yup.string().required("Automatic Email At Invoice is required"),
    control_policy: Yup.string().required("Control Policy is required")
  });

  handleTabChange = (value: number) => {
    this.setState({
      selectedTab: value,
    });
  };

  productImageChange = () => {
    this.productImageRef.current?.click()
  }

  productChangeField = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files && event.target.files[0];
    if (file) {
      if (!file.type.startsWith("image/")) {
        toast.error("Please select a valid image file");
        return;
      }
      this.setState({ ...this.state, productImage: file });
    }
  }

  extraMediaChange = () => {
    this.extraMediaRef.current?.click();
  }

  handleDeleteImage = (index: number) => {
    this.setState((prevState) => {
      const updatedExtraMedia = [...prevState.sales.extra_product_media];
      updatedExtraMedia.splice(index, 1);

      return {
        sales: {
          ...prevState.sales,
          extra_product_media: updatedExtraMedia
        }
      };
    });
  }

  handleAutoCompleteParser = (values: { id: number, title: string, value: string }[]) => {
    const valueArray: string[] = []
    if (values) {
      values.forEach((item) => {
        valueArray.push(item.title)
      })
      return valueArray;
    }
  }

  async fetchImageFromURL(imageUrl: string): Promise<File> {
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    const file = new File([blob], imageUrl.split('/').pop() || 'image', {
      type: blob.type,
      lastModified: Date.now(),
    });

    return file;
  }

  handleSave = async (values: any) => {
    if (values.invoice_policy && Number(values.cost) > 0 && Number(values.sales_price) > 0 && values.product_type && values.unit_of_measure && values.purchased_uom && values.product_code && values.product_category && values.customer_tax && Array.isArray(values.customer_tax) && values.customer_tax.length > 0 && values.control_policy) {
      const customerTaxTemp = this.handleAutoCompleteParser(values?.customer_tax)
      const optionalProductsTemp = this.handleAutoCompleteParser(values?.optional_products)
      const accessoryProductsTemp = this.handleAutoCompleteParser(values?.accessory_products)
      const alternativeProductsTemp = this.handleAutoCompleteParser(values?.alternative_products)
      const vendorTaxTemp = this.handleAutoCompleteParser(values?.vendor_tax)
      const fileList = values?.extra_product_media;

      const token = this.state.token;
      const formdata = new FormData();
      let productImage = null;
      const User_id = await getStorageData("User_id");
      if (typeof this.state.productImage === "string") {
        productImage = await this.fetchImageFromURL(this.state.productImage);
      } else {
        productImage = this.state.productImage;
      }
      formdata.append("data[product_name]", this.state.product_name);
      formdata.append("data[can_be_sold]", values?.canbesold);
      formdata.append("data[can_be_purchased]", values?.canbepurchased);
      (productImage) && formdata.append("data[product_image]", productImage);
      formdata.append("data[general_info_attributes][product_type]", values?.product_type);
      formdata.append("data[general_info_attributes][invoice_policy]", values?.invoice_policy);
      formdata.append("data[general_info_attributes][unit_of_measure]", values?.unit_of_measure);
      formdata.append("data[general_info_attributes][purchased_uom]", values?.purchased_uom);
      formdata.append("data[general_info_attributes][product_code]", values?.product_code);
      formdata.append("data[general_info_attributes][sales_price]", values?.sales_price);
      formdata.append("data[general_info_attributes][customer_tax][]", JSON.stringify(customerTaxTemp));
      formdata.append("data[general_info_attributes][cost]", values?.cost);
      formdata.append("data[general_info_attributes][product_category]", values?.product_category);
      formdata.append("data[general_info_attributes][parent_category]", values?.parent_category);
      formdata.append("data[general_info_attributes][internal_notes]", values?.internal_notes);
      formdata.append("data[sale_attributes][optional_products][]", JSON.stringify(optionalProductsTemp));
      formdata.append("data[sale_attributes][accessory_products][]", JSON.stringify(accessoryProductsTemp));
      formdata.append("data[sale_attributes][alternative_products][]", JSON.stringify(alternativeProductsTemp));
      formdata.append("data[sale_attributes][available_in_POS]", values?.available_in_POS);
      formdata.append("data[sale_attributes][to_weigh_with_scale]", values?.to_weigh_with_scale);
      formdata.append("data[sale_attributes][sales_description]", values?.sales_description);
      if (this.state.productId)
        formdata.append("data[sale_attributes][account_id]", User_id);
      else
        formdata.append("data[sale_attributes][account_id]", '1');
      if (fileList && fileList.length > 0) {
        for (const file of fileList) {
          formdata.append(`data[sale_attributes][extra_product_media][]`, file);
        }
      }
      formdata.append("data[inventory_attributes][responsible]", values?.responsible);
      formdata.append("data[inventory_attributes][weight]", values?.weight);
      formdata.append("data[inventory_attributes][volume]", values?.volume);
      formdata.append("data[inventory_attributes][manuf_lead_time]", values?.manuf_lead_time);
      formdata.append("data[inventory_attributes][customer_lead_time]", values?.customer_lead_time);
      formdata.append("data[inventory_attributes][hs_code]", values?.hs_code);
      formdata.append("data[inventory_attributes][tracking]", values?.trackingValue);
      formdata.append("data[inventory_attributes][receipts_description]", values?.description_for_receipts);
      formdata.append("data[inventory_attributes][delivery_orders_description]", values?.description_for_delivery_orders);
      formdata.append("data[inventory_attributes][internal_transfer_description]", values?.description_for_internal_transfers);
      formdata.append("data[inventory_attributes][barcode]", values?.barcode);
      formdata.append("data[purchase_attributes][vendor_tax]", JSON.stringify(vendorTaxTemp));
      formdata.append("data[purchase_attributes][control_policy]", values?.control_policy);
      formdata.append("data[purchase_attributes][purchase_description]", values?.purchase_description);
      formdata.append("data[accounting_attributes][income_account]", values?.income_account);
      formdata.append("data[accounting_attributes][expense_account]", values?.expense_account);
      formdata.append("data[accounting_attributes][price_difference_account]", values?.price_difference_account);
      formdata.append("data[accounting_attributes][commodity_code]", values?.commodity_code);
      formdata.append("data[accounting_attributes][country_of_origin]", values?.country_of_origin);
      formdata.append("data[accounting_attributes][automatic_email_at_invoice]", values?.automatic_email_at_invoice);
      if (this.state.productId) {
        formdata.append("data[product_category_attributes][account_id]", User_id);
        formdata.append("data[accounting_attributes][account_id]", User_id);
        formdata.append("data[inventory_attributes][account_id]", User_id);
        formdata.append("data[purchase_attributes][account_id]", User_id);
      }
      else {
        formdata.append("data[product_category_attributes][account_id]", "1");
      }
      const endpoint = this.state.productId ? this.state.productId : ""
      const method = this.state.productId ? "PATCH" : "POST"
      this.productGeneralInfoAPICallId = this.apiCall(
        token,
        `${configJSON.saveApiUrl}` + endpoint,
        method,
        formdata,
      );
    }
  };


  apiCall = (token: token, endPoint: string, method: string, body?: object | string, contentType?: string
  ) => {
    const header = {
      ...contentType ? { "Content-Type": contentType } : {},
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  navigatedToProduct = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'Itemavailability');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleVendorsListResponse = (e: React.ChangeEvent<HTMLSelectElement>, rowIndex: number) => {

    const selectedVendor = e.target.value

    const updatedState = this.state.vendorsListTableData.map((item, index) => {
      if (index === rowIndex) {
        item.vendor_name = selectedVendor
      }
      return item
    })
    this.setState({
      vendorsListTableData: updatedState
    })
  }

  navigateToCreation = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ProductCreation");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  navigateToCreateNewProduct = () => {
    this.setState({
      loading: true
    }, () => this.setState({
      product_name: "",
      canbesold: false,
      canbepurchased: false,
      productImage: null,
      generalinfo: {
        product_type: "",
        invoice_policy: "",
        unit_of_measure: "",
        purchased_uom: "",
        product_code: "",
        sales_price: 0,
        customer_tax: [],
        product_category: "",
        parent_category: "",
        internal_notes: "",
        cost: 0,
      },
      sales: {
        id: 0,
        optional_products: [],
        accessory_products: [],
        alternative_products: [],
        available_in_POS: false,
        to_weigh_with_scale: false,
        sales_description: "",
        account_id: 317,
        extra_product_media: [],
      },
      purchase: {
        type: "",
        vendor_tax: [],
        purchase_description: "",
        product_id: 0,
        account_id: 0,
        control_policy: "On received quantities",
        description_for_purchase: "",
      },
      inventory: {
        tracking: false,
        delivery_orders_description: '',
        internal_transfer_description: '',
        receipts_description: '',
        responsible: "",
        barcode: "",
        weight: "",
        trackingValue: "By Unique Serial Number",
        volume: "",
        manuf_lead_time: "",
        customer_lead_time: "",
        hs_code: "",
        description_for_receipts: "",
        description_for_delivery_orders: "",
        description_for_internal_transfers: "",
      },
      accounting: {
        income_account: "",
        expense_account: "",
        price_difference_account: "",
        commodity_code: "",
        country_of_origin: "",
        automatic_email_at_invoice: "",
        product_id: 220,
        accounting_account_id: 1
      },

      isGeneraltabEditing: true,
      isSalesEditing: true,
      isPurchaseEditing: true,
      isInventoryEditing: true,
      isAccountingEditing: true,
      loading: false
    }))
  }

  navigateToProductCreationFromEdit = async () => {
    const editingState = {
      isGeneraltabEditing: true,
      isSalesEditing: true,
      isPurchaseEditing: true,
      isAccountingEditing: true,
      isInventoryEditing: true,
      loading: false
    };
    this.setState({ selectedTab: 0 })
    await setStorageData("editingState", JSON.stringify(editingState));
    this.navigateToCreateNewProduct();
  }

  setEditingTrue = async () => {
    const editingState = {
      isGeneraltabEditing: true,
      isSalesEditing: true,
      isPurchaseEditing: true,
      isAccountingEditing: true,
      isInventoryEditing: true,
      loading: false
    };
    await setStorageData("editingState", JSON.stringify(editingState));

    this.navigateToCreation();
  };

  setEditingFalse = async (productId: string) => {
    this.getSingleProductData(productId)
    const editingState = {
      isGeneraltabEditing: false,
      isSalesEditing: false,
      isPurchaseEditing: false,
      isAccountingEditing: false,
      isInventoryEditing: false,
      productId: productId,
    };
    await setStorageData("editingState", JSON.stringify(editingState));
    this.navigateToCreation();
  };
  setAllFormOpen = () => {
    this.setState({
      isGeneraltabEditing: true,
      isSalesEditing: true,
      isPurchaseEditing: true,
      isInventoryEditing: true,
      isAccountingEditing: true,
    })
  }
  getSingleProductData = async (id: string) => {
    const token = await getStorageData("TOKEN");
    const endpoint = `${configJSON.singleProductApiUrl}` + id
    this.getSingleProductAPI = this.apiCall(
      token,
      endpoint,
      `${configJSON.validationApiMethodType}`
    );
  }

  // Customizable Area End
}