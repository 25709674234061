import React from "react";

import {
    Box,
    Button,
    Typography,
    InputAdornment,
    // Customizable Area Start
    Grid,
    TextField,
    Checkbox,
    Divider,
    CircularProgress,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";


const theme = createTheme({
    palette: {
        primary: {
            main: "#e3e3e3",
            contrastText: "#000",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import AddTaxAccountController, {
    Props
} from "./AddTaxAccountController"
import { AppHeader } from "../../../components/src/AppHeader.web";
import SidebarWeb from "../../../components/src/Sidebar.web";
import { ToastContainer } from "react-toastify";
export const configJSON = require("../src/config");

export default class AddTaxAccount extends AddTaxAccountController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    renderProductTitle = () => {
        return (
            !this.state.isEditing ? (<><Typography className="customer bold-text" style={webStyle.cursor} data-testId="productLink" onClick={this.navigatedToTaxesAccounting}>
                {configJSON.taxes}  /&nbsp; {" "}
            </Typography><Typography >
                    New
                </Typography></>) : (<Typography className="customer bold-text" >
                    {configJSON.taxes}
                </Typography>)
        )
    }

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Box style={webStyle.gridMainLayout}>
                    <ToastContainer />
                    <Box style={webStyle.gridsideBar}>
                        <SidebarWeb />
                    </Box>
                    <Box className="gridcontent" style={webStyle.gridcontent}>
                        <AppHeader pageTitle={configJSON.configuration} />
                        <Box className="parent-div" >
                            <style>
                                {`
                                .MuiInput-underline:after {
                                    border-bottom: 0px !important;
                                },
                                .MuiSelect-selectMenu{
                                    border: 1px solid #e3e3e3;
                                }
                                .MuiInputBase-root{
                                    border-radius: 5px;
                                }
                       
                                .MuiInputAdornment-positionEnd{
                                    padding : 10px;
                                }
                                .MuiFormControl-fullWidth{
                                    width: 90%;
                                }
                                .MuiChip-root{
                                    background-color:#e3e3e3 !important;
                                    color:#000 !important;
                                }
                                .MuiChip-deleteIcon{
                                    color:#000 !important;
                                }
                                .MuiIconButton-root:hover{
                                    background-color: transparent !important;
                                }
                                #tags-outlined-popup {
                                    padding:0 !important;
                                }
                                li[role="option"] {
                                    border-bottom: 1px solid #E3E3E3;
                                }
                                ul[role="listbox"] {
                                    padding-top:0 !important;
                                    padding-bottom:0 !important;
                                }
                                .MuiSelect-icon{
                                    color:black !important;
                                }
                                .MuiFormControl-root{
                                    margin-top: 10px !important;
                                    margin-bottom: 10px !important;
                                }
                                .MuiAutocomplete-inputRoot{
                                    margin-top: 0px !important;
                                }
                                .form-marginonrate .MuiInputBase-root {
                                    border: 1px solid #e3e3e3;
                                    border-radius: 5px;
                                }

                                .form-marginonrate .MuiInputBase-input {
                                    border: 0px !important;
                                }

                                .form-methodName .MuiInputBase-root {
                                    border: 1px solid #e3e3e3;
                                    border-radius: 5px;
                                }

                                .form-methodName .MuiInputBase-input {
                                    border: 0px !important;
                                }

                                .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
                                    padding:4px;
                                }
                                
                            `}
                            </style>
                            <Grid container spacing={0} style={{ display: "flex", flexDirection: "column", marginTop: "25px" }}>
                                <Grid item sm={3} className='left-center-align'>
                                    {this.renderProductTitle()}
                                </Grid>
                                <Divider style={webStyle.divider} />
                                <Grid item sm={12} className='relative' >
                                    {
                                        this.mainErrorBox()
                                    }
                                </Grid>
                                {this.state.isLoading && (
                                    <Box className="loaderBox">
                                        <CircularProgress />
                                    </Box>
                                )}
                                <Box className={`form-div ${this.mainBoxStyle()}`}>
                                    <Grid container spacing={4}>
                                        <Grid item sm={12} md={12} lg={12} style={webStyle.mainBox}>

                                            <Grid lg={6} style={{ display: "flex" }} className={this.buttonStyleEdit()}>
                                                <Typography className={`${this.errorTextClass('name', this.state.taxes.name)}`} style={{ whiteSpace: "nowrap", alignSelf: "center", marginRight: "25px", fontWeight: "bold" }}>Tax Name : </Typography>
                                                <Typography className={`${this.errorTextClass('name', this.state.taxes.name)}`} >{this.state.taxes.name}</Typography>
                                            </Grid>

                                            <Grid lg={6} className={this.buttonStyleEditSave()}>
                                                <Typography className={`${this.errorTextClass('name', this.state.taxes.name)}`} style={{ whiteSpace: "nowrap", alignSelf: "center", marginRight: "25px", }}>Tax Name<span className='required'>*</span></Typography>
                                                <Box sx={{ width: "100%" }}>
                                                    <TextField
                                                        data-test-id="name"
                                                        name="name"
                                                        disabled={this.state.isEditing}
                                                        placeholder="Enter Tax Name"
                                                        fullWidth
                                                        className={`form-name ${this.errorFocusClass('name', this.state.taxes.name)}`}
                                                        value={this.state.taxes.name}
                                                        onChange={(e: any) => this.handleValueChange(e, "name")}
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end"><Typography style={{ color: "black" }}>EN</Typography></InputAdornment>,
                                                        }}
                                                        style={{ width: "100%" }}
                                                    />
                                                    {
                                                        this.handleErrorMessage('name')
                                                    }
                                                </Box>
                                            </Grid>
                                            <Grid lg={6} style={webStyle.mainBox}>
                                                <Box display="flex" flexDirection="column" >
                                                    <Box display="flex" flexDirection="row" alignItems="center">
                                                        <Checkbox
                                                            disableRipple
                                                            disableTouchRipple
                                                            disableFocusRipple
                                                            disabled={this.state.isEditing}
                                                            data-test-id="customer"
                                                            name="customer"
                                                            checked={this.state.taxes.customer}
                                                            onChange={this.handleIsCustomerCheckbox}
                                                        />
                                                        <Typography>Customer</Typography>
                                                    </Box>
                                                    {
                                                        this.handleErrorMessage('customer')
                                                    }
                                                </Box>
                                                <Box display="flex" flexDirection="column" >
                                                    <Box display="flex" flexDirection="row" alignItems="center">
                                                        <Checkbox
                                                            disableRipple
                                                            disableTouchRipple
                                                            disableFocusRipple
                                                            disabled={this.state.isEditing}
                                                            data-test-id="vendor"
                                                            name="vendor"
                                                            checked={this.state.taxes.vendor}
                                                            onChange={this.handleIsVendorCheckbox}
                                                        />
                                                        <Typography>Vendor</Typography>
                                                    </Box>
                                                    {
                                                        this.handleErrorMessage('vendor')
                                                    }
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Box>
                        <Box sx={webStyle.actionBtnStyle} className={this.buttonStyleEdit()}>
                            <Button variant="contained" style={webStyle.discardBtn} data-test-id="createBtn" onClick={this.navigatedToTaxesAccountingFromEditing} className={this.buttonStyleEdit()}>Create</Button>
                            <Button
                                type="submit"
                                data-test-id="saveBtn"
                                className={this.buttonStyleEdit()}
                                style={webStyle.saveBtn}
                                onClick={this.handleEdit}
                            >
                                Edit
                            </Button>
                        </Box>
                        <Box sx={webStyle.actionBtnStyle} className={this.buttonStyleEditSave()}>
                            <Button variant="contained" style={webStyle.discardBtn} data-test-id="discardBtn" onClick={this.handleDiscard}>Discard</Button>
                            <Button
                                type="submit"
                                data-test-id="saveBtn"
                                style={webStyle.saveBtn}
                                onClick={this.handleSave}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        background: "#fff",
    },
    inputStyle: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
        width: "100%",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    buttonStyle: {
        width: "100%",
        height: "45px",
        marginTop: "40px",
        border: "none",
        backgroundColor: "rgb(98, 0, 238)",
    },
    gridsideBar: {
        display: "flex",
        minHheight: "100%",
        transition: "all 0.3s",
    },
    gridMainLayout: {
        display: "flex",
    },
    gridcontent: {
        flexGrow: 9,
        padding: "0 20px",
    },
    actionBtnStyle: {
        display: "flex",
        gap: 8,
        float: "right",
        marginBlock: "40px",
    },
    saveBtn: {
        backgroundColor: "rgba(9, 65, 115, 0.77)",
        color: "#ffffff"
    },
    discardBtn: {
        backgroundColor: "white"
    },
    divider: {
        marginTop: "20px",
        marginBottom: "20px",
        backgroundColor: "#E3E3E3"
    },
    mainBox: {
        display: "flex",
        alignItems: "center",
        gap: "20px"

    },
    cursor: {
        cursor: "pointer"
    }
};
// Customizable Area End
