Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.Filters = "Filters";
exports.configuration="Configuration";
exports.taxes="Taxes";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfaccountingsetting5";
exports.labelBodyText = "cfaccountingsetting5 Body";
exports.deleteUrl='account_block/vendor_taxes/delete_vendor_tax'
exports.unArchiveUrl='account_block/vendor_taxes/unarchive_vendor_tax'
exports.archiveUrl='account_block/vendor_taxes/archive_vendor_tax'
exports.listingUrl='/account_block/vendor_taxes'
exports.singleDeleteUrl='/account_block/vendor_taxes/'
exports.btnExampleTitle = "CLICK ME";
exports.exportUrl="account_block/vendor_taxes/export_vendor_tax"
// Customizable Area End