export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const swap = require("../assets/swap.svg");
export const downloadIcon = require("../assets/downloadIcon.svg");

export const sales = require("../../cfcustomersalesreports3/assets/sales.svg");
export const meetings = require("../../cfcustomersalesreports3/assets/calendar.svg");
export const invoice = require("../../cfcustomersalesreports3/assets/invoice.svg");
export const activity = require("../../cfcustomersalesreports3/assets/activity.svg");
export const star = require("../../cfcustomersalesreports3/assets/star.svg");
export const dropdown = require("../../cfcustomersalesreports3/assets/dropdown.svg");
export const downarrow = require("../../cfcustomersalesreports3/assets/down-arrow.svg");
export const settings = require("../../settings2/assets/settings.svg");
export const menu = require("../../settings2/assets/Menu.svg");
export const listView = require("../../settings2/assets/listview.svg");
export const canban = require("../../settings2/assets/dashboard.svg");
export const forwardarrow = require("../../settings2/assets/forwardarrow.svg");
export const moveforward = require("../../settings2/assets/move-forward.svg");
export const movebackward = require("../../settings2/assets/move-backward.svg");
export const backarrow = require("../../settings2/assets/back-arrow.svg");
export const paginationarrow = require("../../settings2/assets/pagination-arrow.svg");
export const griddownarrow = require("../../settings2/assets/grid-down-arrow.svg");
export const gridleftarrow = require("../../settings2/assets/grid-left-arrow.svg");
export const envelope = require("../../settings2/assets/envelope.svg");
export const greenDot = require("../../settings2/assets/green-dot.svg");
export const kanbanview = require("../../settings2/assets/kanban-view.svg");
export const listview = require("../../settings2/assets/list-view.svg");
export const filtericon = require("../../settings2/assets/filtericon.svg");
export const radioSelected = require("../../settings2/assets/radio-selected.svg");
export const radioNonSelected = require("../../settings2/assets/radio-non-selected.svg");
export const add = require("../../cfcustomersalesreports3/assets/add.svg");
export const groupby = require("../../cfcustomersalesreports3/assets/groupby.svg");
export const dashboard = require("../../cfcustomersalesreports3/assets/dashboard.svg");
export const filter = require("../../cfcustomersalesreports3/assets/filter.svg");
export const list = require("../../cfcustomersalesreports3/assets/list.svg");
export const search = require("../../cfcustomersalesreports3/assets/search.svg");
export const more = require("../../cfcustomersalesreports3/assets/more.svg");
export const purchase = require("../../cfcustomersalesreports3/assets/purchase.svg");
export const vendorbills = require("../../cfcustomersalesreports3/assets/vendorbills.svg");
export const upload = require("../../cfcustomersalesreports3/assets/upload.png");
export const addrow = require("../../cfcustomersalesreports3/assets/add-row.svg");
export const delrow = require("../../cfcustomersalesreports3/assets/del-row.svg");
export const external = require("../../cfcustomersalesreports3/assets/external-link.svg");
export const close = require("../../cfcustomersalesreports3/assets/close.svg");
export const userimg = require("../../settings2/assets/userimg.png");
export const rightArrow = require("../../cfcustomersalesreports3/assets/right-arrow.svg");
